import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';

import { Amplify } from 'aws-amplify';
import { generateClient } from 'aws-amplify/api';
import config from './amplifyconfiguration.json';
Amplify.configure(config);

export const client = generateClient();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
