import { useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Input,
  LoadingOverlay,
  Space,
  Stack,
  Title,
  UnstyledButton,
  createStyles,
  rem,
} from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { IconArrowLeft } from '@tabler/icons-react';
import { Layout } from 'src/components';
import { client } from 'src';
import { createDepartmentModel } from 'src/graphql/mutations';

const DepartmentAdd = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();

  const [deptInfo, setDeptInfo] = useState({
    hospital: '',
    dept: '',
  });

  const [isLoading, setIsLoading] = useState(false);

  const onAddDept = async () => {
    try {
      const { hospital, dept } = deptInfo;

      if (hospital.length == 0 || dept.length == 0) {
        alert('필수 항목이 입력되지 않았습니다. 입력해 주세요.');
        return;
      }

      setIsLoading(true);

      const result = await client.graphql({
        query: createDepartmentModel,
        variables: {
          input: {
            hospital: hospital,
            dept: dept,
          },
        },
      });

      if (result.errors && result.errors.length > 0) {
        alert('데이터 추가를 실패했습니다. 다시 시도해 주세요.');
        return;
      }

      setTimeout(() => {
        setIsLoading(false);
        navigate('/admin/department');
      }, 1800);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Layout>
      <LoadingOverlay style={{ position: 'fixed' }} visible={isLoading} />
      <Space w={1} h={1} mb={rem(50)} />
      <Flex direction={'column'} justify={'center'} align={'center'} h={'100%'}>
        <Box className={classes.form}>
          <Flex justify={'space-between'}>
            <UnstyledButton onClick={() => navigate('/admin/department')}>
              <IconArrowLeft />
            </UnstyledButton>
            <Title order={4}>
              <span>부서 추가</span>
            </Title>
            <Button onClick={onAddDept}>추가</Button>
          </Flex>
          <Stack>
            <Box>
              <Input.Wrapper label="병원" withAsterisk>
                <Input
                  onChange={(event) =>
                    setDeptInfo((prev) => ({
                      ...prev,
                      hospital: event.target.value,
                    }))
                  }
                />
              </Input.Wrapper>
            </Box>
            <Box>
              <Input.Wrapper label="소속" withAsterisk>
                <Input
                  onChange={(event) =>
                    setDeptInfo((prev) => ({
                      ...prev,
                      dept: event.target.value,
                    }))
                  }
                />
              </Input.Wrapper>
            </Box>
          </Stack>
        </Box>
      </Flex>
    </Layout>
  );
};

const useStyles = createStyles(() => ({
  form: {
    width: '80%',
    maxWidth: rem(500),
  },
}));

export default DepartmentAdd;
