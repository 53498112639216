import { TableRowParams } from '../types/TableRowParams';
import { Checkbox } from '@mantine/core';
import { Fragment, useMemo } from 'react';

const TableRow = ({
  tableRows,
  data,
  selection,
  setSelection,
  toggleRow,
  dynamicKey,
  checkbox,
  disabled,
  currentId,
}: TableRowParams) => {
  const rows = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.map((row: any) => (
      <Fragment key={row[dynamicKey]}>
        <tr
          style={{
            backgroundColor:
              currentId === row.id ? 'rgba(28,107,164,0.2' : 'transparent',
          }}
          key={row[dynamicKey]}
        >
          {checkbox && (
            <td
              style={{
                paddingBlock: '0 !important',
                verticalAlign: 'middle',
              }}
            >
              <Checkbox
                disabled={disabled}
                checked={selection && selection.includes(row[dynamicKey])}
                onChange={() => toggleRow(row[dynamicKey], setSelection)}
              />
            </td>
          )}
          {tableRows && tableRows(row, dynamicKey)}
        </tr>
      </Fragment>
    ));
  }, [
    data,
    dynamicKey,
    currentId,
    checkbox,
    selection,
    setSelection,
    toggleRow,
    tableRows,
    disabled,
  ]);

  return rows;
};

export default TableRow;
