import {
  Box,
  Button,
  Flex,
  Input,
  LoadingOverlay,
  Space,
  Stack,
  Title,
  UnstyledButton,
  createStyles,
  rem,
} from '@mantine/core';
import { useNavigate, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { IconArrowLeft } from '@tabler/icons-react';
import { Layout } from 'src/components';
import { client } from 'src';
import {
  getMedicationModel,
  searchMedicationModels,
} from 'src/graphql/queries';
import { updateMedicationModel } from 'src/graphql/mutations';
import {
  SearchableMedicationModelSortableFields,
  SearchableSortDirection,
} from 'src/API';

const MedicationEdit = () => {
  const { classes } = useStyles();

  const { id } = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [medi, setMedi] = useState({
    rank: 0,
    name_kor: '',
    name_eng: '',
  });

  const [lastNumber, setLastNumber] = useState(0);

  useEffect(() => {
    onGetLastNumber();
    onGetMedi();
  }, []);

  const onGetLastNumber = async () => {
    const result = await client.graphql({
      query: searchMedicationModels,
      variables: {
        sort: [
          {
            field: SearchableMedicationModelSortableFields.rank,
            direction: SearchableSortDirection.asc,
          },
        ],
      },
    });
    setLastNumber((result.data.searchMedicationModels as any).total);
  };

  const onGetMedi = async () => {
    try {
      const findItem = await client.graphql({
        query: getMedicationModel,
        variables: {
          id: String(id),
        },
      });

      const item = findItem.data.getMedicationModel;

      if ((findItem.errors && findItem.errors.length > 0) || !item) {
        alert('데이터 삭제를 실패했습니다. 다시 시도해 주세요.');
        return;
      }

      setMedi({
        rank: item.rank,
        name_kor: item.name_kor,
        name_eng: item.name_eng,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const onEditMedi = async () => {
    const { rank, name_kor, name_eng } = medi;

    if (name_kor.length == 0 || name_eng.length == 0) {
      alert('필수 항목이 입력되지 않았습니다. 입력해 주세요.');
      return;
    }

    try {
      setIsLoading(true);
      const result = await client.graphql({
        query: updateMedicationModel,
        variables: {
          input: {
            id: String(id),
            rank: rank === 0 ? lastNumber + 1 : rank,
            name_kor: name_kor,
            name_eng: name_eng,
          },
        },
      });

      if (result.errors && result.errors.length > 0) {
        alert('데이터 수정를 실패했습니다. 다시 시도해 주세요.');
        return;
      }

      setTimeout(() => {
        setIsLoading(false);
        navigate('/admin/medication');
      }, 1800);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Layout>
      <LoadingOverlay style={{ position: 'fixed' }} visible={isLoading} />
      <Space w={1} h={1} mb={rem(50)} />
      <Flex direction={'column'} justify={'center'} align={'center'} h={'100%'}>
        <Box className={classes.buttonList}>
          <Flex justify={'space-between'}>
            <UnstyledButton onClick={() => navigate('/admin/medication')}>
              <IconArrowLeft />
            </UnstyledButton>
            <Title order={4}>
              <span>약품 정보 수정</span>
            </Title>
            <Button onClick={onEditMedi}>수정</Button>
          </Flex>
          <Stack>
            <Box>
              <Input.Wrapper label="순위" withAsterisk>
                <Input
                  value={medi.rank}
                  onChange={(event) =>
                    setMedi({ ...medi, rank: +event.target.value })
                  }
                />
              </Input.Wrapper>
            </Box>
            <Box>
              <Input.Wrapper label="이름" withAsterisk>
                <Input
                  value={medi.name_kor}
                  onChange={(event) =>
                    setMedi({ ...medi, name_kor: event.currentTarget?.value })
                  }
                />
              </Input.Wrapper>
            </Box>
            <Box>
              <Input.Wrapper label="영문 이름" withAsterisk>
                <Input
                  value={medi.name_eng}
                  onChange={(event) =>
                    setMedi({ ...medi, name_eng: event.currentTarget?.value })
                  }
                />
              </Input.Wrapper>
            </Box>
          </Stack>
        </Box>
      </Flex>
    </Layout>
  );
};

const useStyles = createStyles(() => ({
  buttonList: { width: '80%', maxWidth: rem(500) },
}));

export default MedicationEdit;
