import {
  Box,
  Button,
  Center,
  Checkbox,
  Flex,
  Input,
  LoadingOverlay,
  Modal,
  NumberInput,
  Pagination,
  Select,
  Space,
  Stack,
  Table,
  Title,
  Tooltip,
  UnstyledButton,
  createStyles,
  rem,
} from '@mantine/core';
import { useEffect, useState, Fragment, useCallback, memo } from 'react';
import { TableHeaderType } from 'src/types/TableHeaderParams';
import { randomId, useDisclosure } from '@mantine/hooks';
import { Toggle, onCheckUser } from 'src/utils';
import {
  IconEdit,
  IconSquareRoundedPlus,
  IconTrash,
} from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import DeleteModal from '../../components/DeleteModal';
import {
  QuizModel,
  SearchableSortDirection,
  SearchableVideoModelSortableFields,
  VideoModel,
} from 'src/API';
import { client } from 'src';
import {
  getVideoModel,
  listPartnerModels,
  listQuizModels,
  searchVideoModels,
} from 'src/graphql/queries';
import {
  createQuizModel,
  deleteQuizModel,
  deleteVideoModel,
  updateQuizModel,
} from 'src/graphql/mutations';
import {
  onCreateQuizModel,
  onDeleteQuizModel,
  onDeleteVideoModel,
  onUpdateQuizModel,
} from 'src/graphql/subscriptions';
import {
  getVideoId,
  retrievePageNumber,
  savePageNumber,
} from 'src/utils/localStoragePage';
import { LIMIT_PAGE } from 'src/constants';
import { Layout } from 'src/components';

const videoHeaderData: TableHeaderType[] = [
  { key: randomId(), label: '제목' },
  { key: randomId(), label: '작성자' },
  { key: randomId(), label: '태그' },
  { key: randomId(), label: '링크' },
  { key: randomId(), label: '파트너' },
  { key: randomId(), label: '퀴즈 개수' },
  { key: randomId(), label: '퀴즈 추가' },
  { key: randomId(), label: '수정' },
];

const quizHeaderData: TableHeaderType[] = [
  { key: randomId(), label: '문제 유형' },
  { key: randomId(), label: '순서' },
  { key: randomId(), label: '질문' },
  { key: randomId(), label: '정답' },
  { key: randomId(), label: '선택지' },
  { key: randomId(), label: '출처' },
  { key: randomId(), label: '출처 URL' },
  { key: randomId(), label: '수정' },
  { key: randomId(), label: '삭제' },
];

const initialQuiz = {
  question: '',
  type: 0,
  examples: '',
  answer: '',
  source_name: '',
  source_url: '',
  seq: 0,
  videomodelID: '',
};

const Video = () => {
  const { classes } = useStyles();

  const navigate = useNavigate();
  const { toggleAll, toggleRow } = Toggle();

  const [videoDeleteOpened, videoDeleteHandler] = useDisclosure(false);
  const [quizDeleteOpened, quizDeleteHandler] = useDisclosure(false);
  const [quizOpened, quizHandler] = useDisclosure(false);
  const [editQuizOpened, editQuizHandler] = useDisclosure(false);

  const [pageVideo, setPageVideo] = useState<VideoModel[]>([]);
  const [currentVideoId, setCurrentVideoId] = useState<string | null>(
    getVideoId()
  );
  const [currentQuizList, setCurrentQuizList] = useState<QuizModel[]>([]);
  const [refresh, setRefresh] = useState(false);

  const [quizInfo, setQuizInfo] = useState(initialQuiz);
  const [editQuizInfo, setEditQuizInfo] = useState(initialQuiz);
  const [quizId, setQuizId] = useState('');

  const [selection, setSelection] = useState<string[]>([]);

  const [search, setSearch] = useState('');
  const [isSearch, setIsSearch] = useState(false);

  const [partnerList, setPartnerList] = useState<
    { label: string; value: string }[]
  >([]);

  const [isLoading, setIsLoading] = useState(false);
  const [activePage, setActivePage] = useState(retrievePageNumber());
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    const userCheck = onCheckUser();
    if (!userCheck) {
      navigate('/login');
    }
    setActivePage(retrievePageNumber());
    const fetchData = async () => {
      setIsLoading(true);
      await onFindPartnerName();
      if (currentVideoId) {
        await onVideoClick(currentVideoId);
      }
      await onGetVideo();
      setIsLoading(false);
    };
    fetchData();

    const videoDeleteSub = client
      .graphql({ query: onDeleteVideoModel })
      .subscribe({
        next: ({ data }: any) => {
          console.log(data);
        },
        error: (error: any) => console.warn(error),
      });

    const quizDeleteSub = client
      .graphql({ query: onDeleteQuizModel })
      .subscribe({
        next: ({ data }: any) => {
          console.log(data);
          setRefresh((prev) => !prev);
        },
        error: (error: any) => console.warn(error),
      });
    const quizCreateSub = client
      .graphql({ query: onCreateQuizModel })
      .subscribe({
        next: ({ data }: any) => {
          console.log(data);
          setRefresh((prev) => !prev);
        },
        error: (error: any) => console.warn(error),
      });
    const quizUpdateSub = client
      .graphql({ query: onUpdateQuizModel })
      .subscribe({
        next: ({ data }: any) => {
          console.log(data);
          setRefresh((prev) => !prev);
        },
        error: (error: any) => console.warn(error),
      });

    return () => {
      videoDeleteSub.unsubscribe();
      quizDeleteSub.unsubscribe();
      quizCreateSub.unsubscribe();
      quizUpdateSub.unsubscribe();
    };
  }, [refresh]);

  const onSearch = useCallback(async () => {
    if (search.length === 0) {
      setIsSearch(false);
    } else {
      setIsSearch(true);
    }

    setIsLoading(true);
    try {
      const result = await client.graphql({
        query: searchVideoModels,
        variables: {
          limit: LIMIT_PAGE,
          from: LIMIT_PAGE * (activePage - 1),
          filter: {
            or: [
              { author: { wildcard: `${search}*` } },
              { tag: { wildcard: `*${search}*` } },
              { title: { wildcard: `*${search}*` } },
              { retitle: { wildcard: `*${search}*` } },
            ],
          },
          sort: [
            {
              field: SearchableVideoModelSortableFields.createdAt,
              direction: SearchableSortDirection.desc,
            },
          ],
        },
      });

      setTotalCount((result.data.searchVideoModels as any).total);

      if (result.errors && result.errors.length > 0) {
        alert('데이터 검색을 실패했습니다. 다시 시도해 주세요.');
        return;
      }

      const { items } = result.data.searchVideoModels;
      if (items.length === 0) {
        alert('데이터가 없습니다.');
        return;
      }

      setPageVideo(items);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, [activePage, search, setPageVideo, setIsSearch, setIsLoading]);

  useEffect(() => {
    if (isSearch) {
      onSearch();
    } else {
      onGetVideo();
    }
  }, [activePage]);

  useEffect(() => {
    if (currentVideoId) {
      localStorage.setItem('currVideoId', currentVideoId);
    }
  }, [currentVideoId]);

  const onGetVideo = async () => {
    await client
      .graphql({
        query: searchVideoModels,
        variables: {
          limit: LIMIT_PAGE,
          from: LIMIT_PAGE * (activePage - 1),
          sort: [
            {
              field: SearchableVideoModelSortableFields.createdAt,
              direction: SearchableSortDirection.desc,
            },
          ],
        },
      })
      .then(async (result) => {
        setTotalCount((result.data.searchVideoModels as any).total);

        if (result.errors && result.errors.length > 0) {
          alert('데이터 불러오는데 실패했습니다. 다시 시도해 주세요.');
          return;
        }

        const { items } = result.data.searchVideoModels;

        setPageVideo(items);
      });
  };

  const onVideoClick = async (videoId: string) => {
    setIsLoading(true);
    setCurrentVideoId(videoId || getVideoId());
    try {
      const quizResult = await client.graphql({
        query: listQuizModels,
        variables: {
          filter: {
            videomodelID: {
              eq: videoId,
            },
          },
        },
      });

      const quizItems = quizResult.data.listQuizModels.items || [];
      setCurrentQuizList(quizItems);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching quiz models:', error);
    }
  };

  const setActivePageAndSave = (page: number) => {
    setActivePage(page);
    savePageNumber(page);
  };

  const onFindPartnerName = async () => {
    try {
      const result = await client.graphql({ query: listPartnerModels });
      const list = result.data.listPartnerModels.items;

      const selectList = list.map((data) => {
        const { id, name } = data;
        return {
          label: name,
          value: id,
        };
      });

      setPartnerList(selectList);
    } catch (error) {
      console.error('Error fetching partner models:', error);
    }
  };

  const truncate = (str: string, n: number) => {
    return str?.length > n ? str.substring(0, n - 1) + '...' : str;
  };

  const rows = pageVideo.map((video) => (
    <Fragment key={randomId()}>
      <tr>
        <td>
          <Checkbox
            checked={selection && selection.includes(video.id)}
            onChange={() => toggleRow(video.id, setSelection)}
          />
        </td>
        <td>
          <Tooltip
            label={`[ 수정 제목 ] ${video.retitle}, [ 원제목 ] ${video.title}`}
          >
            <Flex direction={'column'}>
              <span>
                [ 수정 제목 ] {truncate(video.retitle || video.title, 20)}
              </span>
              <span>[ 원제목 ] {truncate(video.title, 20)}</span>
            </Flex>
          </Tooltip>
        </td>
        <td>
          <Tooltip label={video.author}>
            <span>{truncate(video.author, 10)}</span>
          </Tooltip>
        </td>
        <td>
          <Tooltip label={video.tag}>
            <span>{truncate(video.tag || '', 20) || '-'}</span>
          </Tooltip>
        </td>
        <td>
          <Tooltip label={video.url}>
            <UnstyledButton onClick={() => window.open(video.url, '_blank')}>
              {truncate(video.url, 20)}
            </UnstyledButton>
          </Tooltip>
        </td>
        <td>
          <Tooltip
            label={
              partnerList.filter(
                (item) => item.value === video.partnermodelID
              )[0]?.label
            }
          >
            <span>
              {truncate(
                partnerList.filter(
                  (item) => item.value === video.partnermodelID
                )[0]?.label,
                7
              )}
            </span>
          </Tooltip>
        </td>
        <td>
          <span>{video.QuizModels?.items.length || 0}개</span>
          <UnstyledButton
            onClick={() => {
              onVideoClick(video.id);
            }}
            style={{ fontSize: rem(14), marginLeft: rem(5) }}
          >
            보기
          </UnstyledButton>
        </td>
        <td>
          <UnstyledButton
            onClick={() => {
              setQuizInfo({ ...quizInfo, videomodelID: video.id });
              quizHandler.open();
            }}
            variant="default"
          >
            <IconSquareRoundedPlus color="gray" />
          </UnstyledButton>
        </td>
        <td>
          <UnstyledButton
            onClick={() => navigate(`/admin/video/edit/${video.id}`)}
            variant="default"
          >
            <IconEdit color="gray" />
          </UnstyledButton>
        </td>
      </tr>
      {currentVideoId && currentVideoId === video.id && (
        <tr>
          <td colSpan={9}>
            <Table
              key={randomId()}
              className={classes.table}
              withBorder
              withColumnBorders
            >
              {currentQuizList.length > 0 && (
                <thead>
                  <tr className={classes.tr}>
                    {quizHeaderData.map((data) => (
                      <th key={data.key}>{data.label}</th>
                    ))}
                  </tr>
                </thead>
              )}
              <tbody>
                {currentQuizList.map((quiz) => (
                  <tr key={randomId()}>
                    <td>
                      {JSON.parse(quiz.answer)?.type === 1
                        ? '주관식 문제'
                        : JSON.parse(quiz.answer)?.type === 2
                        ? 'OX 퀴즈형'
                        : JSON.parse(quiz.answer)?.type === 3
                        ? '다지선다형'
                        : 'Undefined'}
                    </td>
                    <td>{quiz.seq}</td>
                    <td>
                      <Tooltip label={quiz.question}>
                        <span>{truncate(quiz.question, 15)}</span>
                      </Tooltip>
                    </td>
                    <td>
                      <Tooltip label={JSON.parse(quiz.answer)?.answer}>
                        <Flex direction={'column'}>
                          <span>
                            {truncate(JSON.parse(quiz.answer)?.answer, 8)}
                          </span>
                        </Flex>
                      </Tooltip>
                    </td>
                    <td>
                      <Tooltip label={JSON.parse(quiz.answer).examples}>
                        <Flex direction={'column'}>
                          {JSON.parse(quiz.answer)?.examples
                            ? Array.isArray(JSON.parse(quiz.answer).examples) &&
                              JSON.parse(quiz.answer).examples.map(
                                (exam: any, index: number) => (
                                  <Box key={index}>
                                    <span>{index + 1}. </span>
                                    <span>{exam}</span>
                                  </Box>
                                )
                              )
                            : '-'}
                        </Flex>
                      </Tooltip>
                    </td>
                    <td>
                      <Tooltip label={quiz.source_name}>
                        <span>
                          {truncate(quiz?.source_name || '', 10) || '-'}
                        </span>
                      </Tooltip>
                    </td>
                    <td>
                      <Tooltip label={quiz.source_url}>
                        <UnstyledButton
                          onClick={() =>
                            window.open(quiz.source_url || '', '_blank')
                          }
                        >
                          {truncate(quiz?.source_url || '', 20) || '-'}
                        </UnstyledButton>
                      </Tooltip>
                    </td>
                    <td>
                      <UnstyledButton
                        onClick={() => {
                          setEditQuizInfo({
                            question: quiz.question,
                            type: JSON.parse(quiz.answer).type,
                            examples: JSON.parse(quiz.answer).examples
                              ? JSON.parse(quiz.answer).examples.join(';')
                              : '',
                            answer: JSON.parse(quiz.answer).answer,
                            source_name: quiz?.source_name || '',
                            source_url: quiz?.source_url || '',
                            seq: quiz.seq,
                            videomodelID: quiz.videomodelID,
                          });
                          setQuizId(quiz.id);
                          editQuizHandler.open();
                        }}
                        variant="default"
                      >
                        <IconEdit color="gray" />
                      </UnstyledButton>
                    </td>
                    <td>
                      <UnstyledButton
                        onClick={() => {
                          setQuizId(quiz.id);
                          quizDeleteHandler.open();
                        }}
                        variant="default"
                      >
                        <IconTrash color="gray" />
                      </UnstyledButton>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </td>
        </tr>
      )}
    </Fragment>
  ));

  const onDeleteVideo = async () => {
    try {
      if (selection.length === 0) {
        alert('선택된 비디오가 없습니다.');
        return;
      }

      setIsLoading(true);
      await Promise.all(
        selection.map(async (video) => {
          await client
            .graphql({
              query: getVideoModel,
              variables: {
                id: video,
              },
            })
            .then(async (findVideoItem) => {
              const videoItem = findVideoItem.data.getVideoModel;

              if (videoItem) {
                const findQuizItems = await client.graphql({
                  query: listQuizModels,
                  variables: {
                    filter: {
                      videomodelID: {
                        eq: videoItem.id,
                      },
                    },
                  },
                });

                const videoResult = await client.graphql({
                  query: deleteVideoModel,
                  variables: {
                    input: {
                      id: videoItem.id,
                    },
                  },
                });

                if (videoResult.errors && videoResult.errors.length > 0) {
                  alert('데이터 삭제를 실패했습니다. 다시 시도해 주세요.');
                  return;
                }

                const findQuizItem = findQuizItems.data.listQuizModels.items;

                if (findQuizItem.length !== 0) {
                  findQuizItem.map(async (quiz) => {
                    const quizResult = await client.graphql({
                      query: deleteQuizModel,
                      variables: {
                        input: {
                          id: quiz.id,
                        },
                      },
                    });

                    if (quizResult.errors && quizResult.errors.length > 0) {
                      alert('데이터 삭제를 실패했습니다. 다시 시도해 주세요.');
                      return;
                    }
                  });
                }
              }
            });
        })
      );

      videoDeleteHandler.close();

      setTimeout(() => {
        setIsLoading(false);
        window.location.reload();
      }, 1800);
    } catch (error) {
      console.error(error);
    }
  };

  const onAddQuiz = async () => {
    const {
      question,
      type,
      examples,
      answer,
      source_name,
      source_url,
      videomodelID,
      seq,
    } = quizInfo;
    if (
      question.length === 0 ||
      type === 0 ||
      (type === 3 && examples.length === 0) ||
      answer.length === 0 ||
      seq == 0
    ) {
      alert('필수 항목이 입력되지 않았습니다. 입력해 주세요.');
      return;
    }
    try {
      const result = await client.graphql({
        query: createQuizModel,
        variables: {
          input: {
            question,
            answer:
              type === 3
                ? JSON.stringify({
                    answer: Number(answer),
                    examples: examples.split(';'),
                    type,
                  })
                : JSON.stringify({
                    answer,
                    type,
                  }),
            source_name,
            source_url: source_url || null,
            seq,
            videomodelID,
          },
        },
      });

      if (result.errors && result.errors.length > 0) {
        alert('데이터 추가를 실패했습니다. 다시 시도해 주세요.');
        return;
      }

      quizHandler.close();
      setQuizInfo(initialQuiz);

      setRefresh((prev) => !prev);
    } catch (error) {
      console.error(error);
    }
  };

  const onEditQuiz = async (id: string) => {
    const { question, type, examples, answer, source_name, source_url, seq } =
      editQuizInfo;
    if (
      question.length === 0 ||
      type === 0 ||
      (type === 3 && examples.length === 0) ||
      answer.length === 0 ||
      seq == 0
    ) {
      alert('필수 항목이 입력되지 않았습니다. 입력해 주세요.');
      return;
    }
    try {
      const result = await client.graphql({
        query: updateQuizModel,
        variables: {
          input: {
            id: String(id),
            question,
            answer:
              type === 3
                ? JSON.stringify({
                    answer: Number(answer),
                    examples: examples.split(';'),
                    type,
                  })
                : JSON.stringify({
                    answer,
                    type,
                  }),
            source_name,
            source_url: source_url || null,
            seq,
          },
        },
      });

      if (result.errors && result.errors.length > 0) {
        alert('데이터 수정을 실패했습니다. 다시 시도해 주세요.');
        return;
      }

      editQuizHandler.close();
      setRefresh((prev) => !prev);
    } catch (error) {
      console.error(error);
    }
  };

  const onDeleteQuiz = async (id: string) => {
    try {
      const result = await client.graphql({
        query: deleteQuizModel,
        variables: {
          input: {
            id: String(id),
          },
        },
      });

      if (result.errors && result.errors.length > 0) {
        alert('데이터 삭제를 실패했습니다. 다시 시도해 주세요.');
        return;
      }

      quizDeleteHandler.close();

      setRefresh((prev) => !prev);
    } catch (error) {
      console.error(error);
    }
  };

  const MemoizedDataTable = memo(Table);
  const MemoizedPagination = memo(Pagination);
  const MemoizedDeleteModal = memo(DeleteModal);

  return (
    <Layout>
      <LoadingOverlay style={{ position: 'fixed' }} visible={isLoading} />
      <Box>
        <Title order={4}>
          <span>비디오 목록</span>
        </Title>
        <Flex align={'center'} justify={'space-between'}>
          <Flex gap={rem(10)}>
            <Input
              value={search}
              onChange={(event) => setSearch(event.target.value)}
              rightSection={
                <UnstyledButton
                  onClick={() => {
                    setSearch('');
                  }}
                >
                  x
                </UnstyledButton>
              }
            />
            <Button
              onClick={() => {
                setActivePage(1);
                onSearch();
              }}
            >
              검색
            </Button>
          </Flex>
          <Flex gap={rem(10)} className={classes.buttonList}>
            <Button
              variant="default"
              onClick={() => navigate('/admin/video/add')}
            >
              추가
            </Button>
            <Button
              variant="default"
              onClick={() => {
                if (selection.length === 0) {
                  alert('선택된 비디오가 없습니다.');
                  return;
                }
                videoDeleteHandler.open();
              }}
            >
              삭제
            </Button>
          </Flex>
        </Flex>
        <Box>
          <MemoizedDataTable withBorder withColumnBorders>
            <thead>
              <tr>
                <th>
                  <Checkbox
                    onChange={() => toggleAll(pageVideo, setSelection, 'id')}
                    checked={
                      selection
                        ? selection.length === (pageVideo?.length || 0) &&
                          (pageVideo?.length || 0) > 0
                        : false
                    }
                  />
                </th>
                {videoHeaderData.map((header) => (
                  <th key={header.key}>{header.label}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {rows.length > 0 ? (
                rows
              ) : (
                <tr>
                  <td
                    style={{
                      height: rem(200),
                      textAlign: 'center',
                      padding: `${rem(50)} 0`,
                    }}
                    colSpan={videoHeaderData.length + 1}
                  >
                    <span>{'데이터가 존재하지 않습니다.'}</span>
                  </td>
                </tr>
              )}
            </tbody>
          </MemoizedDataTable>
          <Space w={1} h={1} mb={rem(25)} />
          <Center>
            <MemoizedPagination
              total={Math.ceil(totalCount / LIMIT_PAGE)}
              value={activePage}
              onChange={setActivePageAndSave}
            />
          </Center>
        </Box>
        <Space w={1} h={1} mb={rem(200)} />
      </Box>
      <Modal title="퀴즈 추가" opened={quizOpened} onClose={quizHandler.close}>
        <Stack>
          <Box>
            <Input.Wrapper label="질문" withAsterisk>
              <Input
                value={quizInfo.question}
                onChange={(event) =>
                  setQuizInfo({ ...quizInfo, question: event.target.value })
                }
              />
            </Input.Wrapper>
          </Box>
          <Box>
            <Select
              label="문제 유형"
              withAsterisk
              value={String(quizInfo.type)}
              data={[
                { label: '주관식 문제', value: '1' },
                { label: 'OX 퀴즈형', value: '2' },
                { label: '다지선다형', value: '3' },
              ]}
              onChange={(value) =>
                setQuizInfo({ ...quizInfo, type: (value && +value) || 0 })
              }
            />
          </Box>
          <Box>
            <Input.Wrapper label="정답" withAsterisk>
              <Input
                value={quizInfo.answer}
                onChange={(event) =>
                  setQuizInfo({ ...quizInfo, answer: event.target.value })
                }
              />
            </Input.Wrapper>
          </Box>
          {quizInfo.type === 3 && (
            <Box>
              <Input.Wrapper label="선택지" withAsterisk>
                <Input
                  value={quizInfo.examples}
                  placeholder="( ; )로 구분하여 작성해주세요"
                  onChange={(event) =>
                    setQuizInfo((prev) => ({
                      ...prev,
                      examples: event.target.value,
                    }))
                  }
                />
              </Input.Wrapper>
            </Box>
          )}
          <Box>
            <Input.Wrapper label="출처">
              <Input
                value={quizInfo.source_name}
                onChange={(event) =>
                  setQuizInfo({ ...quizInfo, source_name: event.target.value })
                }
              />
            </Input.Wrapper>
          </Box>
          <Box>
            <Input.Wrapper label="출처 URL">
              <Input
                value={quizInfo.source_url}
                onChange={(event) =>
                  setQuizInfo({ ...quizInfo, source_url: event.target.value })
                }
              />
            </Input.Wrapper>
          </Box>
          <Box>
            <NumberInput
              label="순서"
              withAsterisk
              value={quizInfo.seq}
              onChange={(value) => setQuizInfo({ ...quizInfo, seq: +value })}
            />
          </Box>
          <Center>
            <Button
              onClick={() => {
                onAddQuiz();
              }}
            >
              완료
            </Button>
          </Center>
        </Stack>
      </Modal>
      <Modal
        title="퀴즈 수정"
        opened={editQuizOpened}
        onClose={editQuizHandler.close}
      >
        <Stack>
          <Box>
            <Input.Wrapper label="질문" withAsterisk>
              <Input
                value={editQuizInfo.question}
                onChange={(event) =>
                  setEditQuizInfo({
                    ...editQuizInfo,
                    question: event.target.value,
                  })
                }
              />
            </Input.Wrapper>
          </Box>
          <Box>
            <Select
              label="문제 유형"
              withAsterisk
              value={String(editQuizInfo.type)}
              data={[
                { label: '주관식 문제', value: '1' },
                { label: 'OX 퀴즈형', value: '2' },
                { label: '다지선다형', value: '3' },
              ]}
              onChange={(value) =>
                setEditQuizInfo({
                  ...editQuizInfo,
                  type: (value && +value) || 0,
                })
              }
            />
          </Box>
          <Box>
            <Input.Wrapper label="정답" withAsterisk>
              <Input
                value={editQuizInfo.answer}
                onChange={(event) =>
                  setEditQuizInfo({
                    ...editQuizInfo,
                    answer: event.target.value,
                  })
                }
              />
            </Input.Wrapper>
          </Box>
          {editQuizInfo.type === 3 && (
            <Box>
              <Input.Wrapper label="선택지" withAsterisk>
                <Input
                  value={editQuizInfo.examples}
                  placeholder="( ; )로 구분하여 작성해주세요"
                  onChange={(event) =>
                    setEditQuizInfo((prev) => ({
                      ...prev,
                      examples: event.target.value,
                    }))
                  }
                />
              </Input.Wrapper>
            </Box>
          )}

          <Box>
            <Input.Wrapper label="출처">
              <Input
                value={editQuizInfo.source_name}
                onChange={(event) =>
                  setEditQuizInfo({
                    ...editQuizInfo,
                    source_name: event.target.value,
                  })
                }
              />
            </Input.Wrapper>
          </Box>
          <Box>
            <Input.Wrapper label="출처 URL">
              <Input
                value={editQuizInfo.source_url}
                onChange={(event) =>
                  setEditQuizInfo({
                    ...editQuizInfo,
                    source_url: event.target.value,
                  })
                }
              />
            </Input.Wrapper>
          </Box>
          <Box>
            <NumberInput
              label="순서"
              withAsterisk
              value={editQuizInfo.seq}
              onChange={(value) =>
                setEditQuizInfo({ ...editQuizInfo, seq: +value })
              }
            />
          </Box>
          <Center>
            <Button onClick={() => onEditQuiz(quizId)}>완료</Button>
          </Center>
        </Stack>
      </Modal>
      <MemoizedDeleteModal
        opened={videoDeleteOpened}
        onClose={videoDeleteHandler.close}
        onDelete={onDeleteVideo}
      />
      <MemoizedDeleteModal
        opened={quizDeleteOpened}
        onClose={quizDeleteHandler.close}
        onDelete={() => onDeleteQuiz(quizId)}
      />
    </Layout>
  );
};

const useStyles = createStyles(() => ({
  buttonList: {
    paddingBlock: rem(10),
  },
  table: {
    border: `1px solid grey`,
  },
  tr: {
    backgroundColor: 'rgba( 28,107,164, 0.2)',
  },
}));

export default Video;
