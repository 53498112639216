import {
  BackgroundImage,
  Center,
  Flex,
  Image,
  Space,
  UnstyledButton,
  createStyles,
  rem,
} from '@mantine/core';
import { BrowserView, MobileView } from 'react-device-detect';
import { Layout } from 'src/components';
import AppBanner from '../assets/application-app.jpg';
import AppUI from '../assets/app-ui.jpg';
import RealAppUI from '../assets/real-app-scene.jpg';
import Google from '../assets/google-play.png';
import Apple from '../assets/app-store.png';
import GoogleButton from '../assets/google-play-button.png';
import AppleButton from '../assets/app-store-button.png';
import classnames from 'classnames';
import Male from '../assets/male.png';
import Female from '../assets/female.png';
import Logo01 from '../assets/web_logo.png';
import Logo2 from '../assets/web_logo02.png';
import Phone from '../assets/phone.png';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { downloadData } from 'aws-amplify/storage';

const AppIntroduction = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();

  const [urls, setUrls] = useState<{ basic: string; partner: string }>({
    basic: '',
    partner: '',
  });

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  useEffect(() => {
    const onFetch = async () => {
      const basic = (await onGetUrls('basic_guide.pdf')) ?? '';
      const partner = (await onGetUrls('partner_guide.pdf')) ?? '';
      setUrls((prev) => ({ ...prev, basic, partner }));
    };
    onFetch();
  }, []);

  const onGetUrls = async (key: string) => {
    try {
      const result = await downloadData({
        key,
      }).result;

      const file = new File([await result.body.blob()], result.key, {
        type: result.contentType,
      });

      return URL.createObjectURL(file) ?? null;
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Layout tabs={false}>
      <BrowserView>
        {/* 메인배너 */}
        <BackgroundImage
          src={AppBanner}
          mah={rem(400)}
          miw={rem(390)}
          mih={rem(220)}
          style={{
            zIndex: -2,
            position: 'relative',
          }}
        >
          <Flex
            direction={'column'}
            gap={rem(15)}
            justify={'center'}
            align={'center'}
            className={classes.mainContents01}
          >
            <span className={classes.title}>SERVICE</span>
            <span className={classes.pageDescription}>닥터벤</span>
          </Flex>
        </BackgroundImage>
        <Space mb={rem(100)} />
        <Flex
          direction={'column'}
          align={'center'}
          className={classes.introduceBox}
          gap={rem(30)}
        >
          {/* 닥터벤 앱 다운로드 */}
          <Flex direction={'column'} w={'100%'} gap={rem(30)}>
            <span
              className={classnames(classes.colorEmphasis, classes.emphasis)}
            >
              닥터벤 앱 다운로드
            </span>
            <BackgroundImage
              src={AppUI}
              h={rem(356)}
              miw={rem(330)}
              mih={rem(200)}
              style={{
                position: 'relative',
                backgroundColor: 'orange',
              }}
              className={classes.sectionBackground}
            >
              <Flex
                justify={'flex-end'}
                h={'inherit'}
                align={'center'}
                pr={rem(153)}
              >
                <Image
                  src={Phone}
                  className={classes.phone}
                  width={rem(200)}
                  height={rem(400)}
                />
                <Flex gap={rem(100)}>
                  <Link
                    to={
                      'https://play.google.com/store/apps/details?id=com.Bench.Drben&pcampaignid=web_share&pli=1'
                    }
                  >
                    <Image src={Google} width={rem(193)} height={rem(253)} />
                  </Link>
                  <Link
                    to={
                      'https://apps.apple.com/kr/app/%EB%8B%A5%ED%84%B0%EB%B2%A4/id6471632219'
                    }
                  >
                    <Image src={Apple} width={rem(193)} height={rem(242)} />
                  </Link>
                </Flex>
              </Flex>
            </BackgroundImage>
            {/* 닥터벤 기능 둘러보기 */}
            <span
              className={classnames(classes.colorEmphasis, classes.emphasis)}
            >
              닥터벤 기능 둘러보기
            </span>
            <Image
              src={RealAppUI}
              height={rem(620)}
              styles={{
                image: {
                  maxWidth: rem(1280),
                  minWidth: rem(390),
                  borderRadius: rem(16),
                },
              }}
            />
          </Flex>
          <Flex direction={'column'} w={'100%'} gap={rem(30)}>
            {/* 설치 및 사용 가이드 */}
            <span
              className={classnames(classes.colorEmphasis, classes.emphasis)}
            >
              설치 및 사용 가이드
            </span>
            <Flex
              gap={rem(30)}
              justify={'space-evenly'}
              mt={rem(30)}
              className={classes.guideContainer}
            >
              <a
                href={urls.basic}
                download={'닥터벤(Dr.BEN) 사용가이드_일반.pdf'}
              >
                <Flex
                  className={classes.guideBox}
                  align={'center'}
                  gap={rem(18)}
                >
                  <Image src={Male} width={rem(224)} height={rem(224)} />
                  <Flex direction={'column'} align={'center'} gap={rem(7)}>
                    <Image src={Logo01} width={rem(125)} height={rem(82)} />
                    <Flex direction={'column'} align={'center'}>
                      <span className={classes.guideText}>사용가이드</span>
                      <span className={classes.guideType}>(일반 이용자)</span>
                    </Flex>
                  </Flex>
                </Flex>
              </a>
              <a
                href={urls.partner}
                download={'닥터벤(Dr.BEN) 사용가이드_파트너.pdf'}
              >
                <Flex
                  className={classnames(
                    classes.guideBox,
                    classes.colorGuideBox
                  )}
                  align={'center'}
                  gap={rem(18)}
                >
                  <Image src={Female} width={rem(224)} height={rem(224)} />
                  <Flex direction={'column'} align={'center'} gap={rem(7)}>
                    <Image src={Logo2} width={rem(125)} height={rem(82)} />
                    <Flex direction={'column'} align={'center'}>
                      <span className={classes.guideText}>사용가이드</span>
                      <span className={classes.guideType}>(파트너 이용자)</span>
                    </Flex>
                  </Flex>
                </Flex>
              </a>
            </Flex>
          </Flex>
          <Center mt={rem(50)}>
            <UnstyledButton
              onClick={() => navigate('/contact')}
              className={classes.button}
            >
              <span>파트너 신청하기</span>
            </UnstyledButton>
          </Center>
        </Flex>
        <Space mb={rem(80)} />
      </BrowserView>
      <MobileView>
        <Space w={4} h={5} mb={rem(42)} />
        {/* 메인배너 */}
        <BackgroundImage
          src={AppBanner}
          miw={rem(390)}
          mih={rem(220)}
          style={{
            zIndex: -2,
            position: 'relative',
          }}
        >
          <Flex
            direction={'column'}
            gap={rem(15)}
            justify={'center'}
            align={'center'}
            className={classnames(
              classes.mainContents01,
              classes.m_mainContents1
            )}
          >
            <span className={classnames(classes.title, classes.m_title)}>
              SERVICE
            </span>
            <span className={classes.m_pageDescription}>닥터벤</span>
          </Flex>
        </BackgroundImage>
        <Space mb={rem(34)} />
        <Flex direction={'column'} align={'center'} gap={rem(30)}>
          <Flex direction={'column'} gap={rem(20)} mt={rem(15)}>
            {/* 닥터벤 앱 다운로드 */}
            <span
              className={classnames(
                classes.colorEmphasis,
                classes.emphasis,
                classes.m_subTitle
              )}
            >
              닥터벤 앱 다운로드
            </span>
            <Flex direction={'column'} gap={rem(20)}>
              <BackgroundImage
                src={AppUI}
                w={rem(330)}
                h={rem(60)}
                style={{
                  position: 'relative',
                }}
                className={classnames(
                  classes.sectionBackground,
                  classes.m_sectionBackground
                )}
              >
                <Flex
                  h={'inherit'}
                  align={'center'}
                  justify={'flex-end'}
                  pr={rem(30)}
                >
                  <Image
                    src={Phone}
                    className={classes.m_phone}
                    styles={{
                      imageWrapper: {
                        maxWidth: rem(100),
                        height: rem(300),
                      },
                    }}
                  />
                  <Link
                    to={
                      'https://play.google.com/store/apps/details?id=com.Bench.Drben&pcampaignid=web_share&pli=1'
                    }
                  >
                    <Image
                      src={GoogleButton}
                      width={rem(157)}
                      height={rem(42)}
                    />
                  </Link>
                </Flex>
              </BackgroundImage>
              <BackgroundImage
                src={AppUI}
                w={rem(330)}
                h={rem(60)}
                style={{
                  position: 'relative',
                }}
                className={classnames(
                  classes.sectionBackground,
                  classes.m_sectionBackground
                )}
              >
                <Flex
                  h={'inherit'}
                  align={'center'}
                  justify={'flex-end'}
                  pr={rem(30)}
                >
                  <Image
                    src={Phone}
                    className={classes.m_phone}
                    styles={{
                      imageWrapper: {
                        maxWidth: rem(100),
                        height: rem(300),
                      },
                    }}
                  />
                  <Link
                    to={
                      'https://apps.apple.com/kr/app/%EB%8B%A5%ED%84%B0%EB%B2%A4/id6471632219'
                    }
                  >
                    <Image
                      src={AppleButton}
                      width={rem(157)}
                      height={rem(32)}
                    />
                  </Link>
                </Flex>
              </BackgroundImage>
            </Flex>
            <Space mb={rem(15)} />
            {/* 닥터벤 기능 둘러보기 */}
            <span
              className={classnames(
                classes.colorEmphasis,
                classes.emphasis,
                classes.m_subTitle
              )}
            >
              닥터벤 기능 둘러보기
            </span>
          </Flex>
          <Image src={RealAppUI} />
          <Flex direction={'column'} gap={rem(20)} mt={rem(15)}>
            {/* 설치 및 사용 가이드 */}
            <span
              className={classnames(
                classes.colorEmphasis,
                classes.emphasis,
                classes.m_subTitle
              )}
            >
              설치 및 사용 가이드
            </span>
            <Flex direction={'column'} gap={rem(20)}>
              <a
                href={urls.basic}
                download={'닥터벤(Dr.BEN) 사용가이드_일반.pdf'}
              >
                <Flex
                  className={classnames(classes.guideBox, classes.m_guideBox)}
                  align={'center'}
                  gap={rem(18)}
                >
                  <Image src={Male} width={rem(150)} height={rem(150)} />
                  <Flex direction={'column'} align={'center'} gap={rem(10)}>
                    <Image src={Logo01} width={rem(84)} height={rem(55)} />
                    <Flex direction={'column'} align={'center'}>
                      <span
                        className={classnames(
                          classes.guideText,
                          classes.m_guideText
                        )}
                      >
                        사용가이드
                      </span>
                      <span
                        className={classnames(
                          classes.guideType,
                          classes.m_guideType
                        )}
                      >
                        (일반 이용자)
                      </span>
                    </Flex>
                  </Flex>
                </Flex>
              </a>
              <a
                href={urls.partner}
                download={'닥터벤(Dr.BEN) 사용가이드_파트너.pdf'}
              >
                <Flex
                  className={classnames(
                    classes.guideBox,
                    classes.m_guideBox,
                    classes.colorGuideBox
                  )}
                  align={'center'}
                  gap={rem(18)}
                >
                  <Image src={Female} width={rem(150)} height={rem(150)} />
                  <Flex direction={'column'} align={'center'} gap={rem(10)}>
                    <Image src={Logo2} width={rem(84)} height={rem(55)} />
                    <Flex direction={'column'} align={'center'}>
                      <span
                        className={classnames(
                          classes.guideText,
                          classes.m_guideText
                        )}
                      >
                        사용가이드
                      </span>
                      <span
                        className={classnames(
                          classes.guideType,
                          classes.m_guideType
                        )}
                      >
                        (파트너 이용자)
                      </span>
                    </Flex>
                  </Flex>
                </Flex>
              </a>
            </Flex>
            <UnstyledButton
              mt={rem(10)}
              onClick={() => navigate('/contact')}
              className={classes.fillButton}
            >
              <span>파트너 신청하기</span>
            </UnstyledButton>
          </Flex>
        </Flex>
        <Space mb={rem(80)} />
      </MobileView>
    </Layout>
  );
};

const useStyles = createStyles(() => ({
  mainContents01: {
    paddingInline: rem(50),
    height: rem(400),
    color: 'white',
    ':after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: -1,
    },
  },
  m_mainContents1: {
    height: rem(220),
    paddingInline: rem(30),
    paddingBlock: rem(30),
  },
  title: {
    fontSize: rem(96),
    fontWeight: 700,
    lineHeight: rem(115),
  },
  m_title: {
    fontSize: rem(36),
    fontWeight: 700,
    lineHeight: rem(43),
  },
  pageDescription: {
    fontSize: rem(36),
    lineHeight: rem(43),
  },
  m_pageDescription: {
    fontSize: rem(16),
    lineHeight: rem(19),
  },
  aiVideoProductionTitle: {
    fontSize: rem(48),
    fontWeight: 800,
  },
  m_aiVideoProductionTitle: {
    fontSize: rem(32),
  },
  introduceBox: {
    maxWidth: rem(1280),
    paddingInline: rem(30),
    marginInline: 'auto',
    marginTop: rem(50),
  },
  emphasis: {
    fontWeight: 700,
    fontSize: rem(16),
  },
  colorEmphasis: {
    color: '#2C93AD',
  },
  m_subTitle: {
    fontWeight: 600,
    lineHeight: rem(19),
  },
  producingGrid: {
    border: '1px solid #CCCCCC',
    borderRadius: rem(16),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: rem(320),
    maxHeight: rem(164),
    paddingInline: rem(60),
    paddingBlock: rem(30),
  },
  producingGridText: {
    fontSize: rem(20),
    fontWeight: 600,
  },
  producingArrowGrid: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingInline: rem(55),
    paddingBlock: rem(30),
  },
  middleArrowBox: {
    padding: 0,
  },
  middleArrow: {
    width: rem(300),
    marginBlock: rem(15),
  },
  arrowDown: {
    transform: 'rotate(90deg)',
  },
  arrowLeft: {
    transform: 'rotate(180deg)',
  },
  priceGrid: {
    border: '1px solid #CCCCCC',
    borderRadius: rem(16),
    paddingInline: rem(20),
    paddingBlock: rem(26),
    minHeight: rem(343),
  },
  priceTitle: {
    fontWeight: 600,
    fontSize: rem(36),
    textAlign: 'center',
  },
  priceCondition: {
    flex: 1,
    fontSize: rem(18),
    fontWeight: 600,
    listStyle: 'inside',
  },
  priceBox: {
    minHeight: rem(73),
  },
  priceText: {
    display: 'block',
    fontWeight: 600,
  },
  originPrice: {
    fontSize: rem(18),
    color: '#CCCCCC',
    textDecoration: 'line-through',
    lineHeight: rem(25),
  },
  discountPrice: {
    fontSize: rem(32),
    lineHeight: rem(45),
  },
  addPriceBox: {
    border: '1px solid #CCCCCC',
    borderRadius: rem(16),
    marginInline: rem(80),
    paddingBlock: rem(50),
    paddingInline: rem(57),
  },
  button: {
    maxWidth: rem(400),
    minWidth: rem(330),
    height: rem(52),
    border: `${rem(1)} solid #2A93AD`,
    borderRadius: rem(16),
    backgroundColor: '#2D93AD',
    color: 'white',
    textAlign: 'center',
    paddingBlock: rem(14),
    fontSize: rem(18),
    fontWeight: 500,
    lineHeight: rem(25),
    ':hover': {
      color: '#2C93AD',
      backgroundColor: 'white',
    },
  },

  fillButton: {
    width: rem(330),
    height: rem(52),
    border: `${rem(1)} solid #2C93AD`,
    borderRadius: rem(16),
    color: '#2C93AD',
    backgroundColor: 'white',
    textAlign: 'center',
    paddingBlock: rem(14),
    fontSize: rem(18),
    fontWeight: 500,
    lineHeight: rem(25),
    ':hover': { backgroundColor: '#2D93AD', color: 'white' },
  },
  m_priceGrid: {
    paddingInline: rem(40),
  },
  m_priceTitle: {
    textAlign: 'start',
  },
  m_priceCondition: {
    fontSize: rem(16),
  },
  m_originPrice: {
    fontSize: rem(16),
  },
  m_addPriceBox: {
    border: '1px solid #CCCCCC',
    borderRadius: rem(16),
    paddingInline: rem(15),
    paddingBlock: rem(25),
  },
  addPriceInfoTitle: {
    fontSize: rem(24),
    fontWeight: 600,
    lineHeight: rem(25),
  },
  processContainer: {
    width: 'inherit',
    boxSizing: 'border-box',
  },
  processBox: {
    minWidth: rem(300),
    border: `${rem(1)} solid #CCCCCC`,
    borderRadius: rem(16),
    paddingBlock: rem(38),
    boxSizing: 'border-box',
  },
  sectionBackground: {
    borderRadius: rem(16),
    border: `${rem(1)} solid #CCCCCC`,
    ':before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(255, 255, 255, 0.9)',
      borderRadius: rem(16),
      border: `${rem(1)} solid #CCCCCC`,
    },
    position: 'relative',
    overflow: 'hidden',
  },
  m_sectionBackground: {
    ':before': {
      backgroundColor: 'rgba(255, 255, 255)',
    },
  },
  phone: {
    position: 'absolute',
    top: '5%',
    left: '10%',
  },
  m_phone: {
    position: 'absolute',
    top: '-125%',
    left: '5%',
  },
  guideContainer: {},
  guideBox: {
    border: `${rem(1)} solid #CCCCCC`,
    borderRadius: rem(16),
    maxWidth: rem(490),
    paddingBlock: rem(43),
    paddingLeft: rem(0),
    paddingRight: rem(30),
  },
  m_guideBox: {
    maxWidth: rem(330),
    paddingBlock: rem(25),
    paddingLeft: rem(19),
    paddingRight: rem(43),
  },
  colorGuideBox: {
    backgroundColor: '#2C93AD',
    color: 'white',
  },
  guideText: {
    fontSize: rem(36),
    fontWeight: 700,
    lineHeight: rem(50),
  },
  m_guideText: {
    fontSize: rem(24),
    lineHeight: rem(30),
  },
  guideType: {
    fontWeight: 700,
    fontSize: rem(24),
    lineHeight: rem(50),
  },
  m_guideType: {
    fontSize: rem(16),
    lineHeight: rem(30),
  },
}));

export default AppIntroduction;
