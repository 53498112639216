import {
  BackgroundImage,
  Flex,
  Image,
  SimpleGrid,
  Space,
  Stack,
  createStyles,
  rem,
} from '@mantine/core';
import { Layout } from 'src/components';
import { BrowserView, MobileView } from 'react-device-detect';
import classnames from 'classnames';
import Brand from '../assets/brand.png';
import Logo from '../assets/black-logo.png';
import Feature01 from '../assets/feature01.png';
import Feature02 from '../assets/feature02.png';
import { useNavigate } from 'react-router-dom';
import MLogo from '../assets/web_logo.png';
import MainBanner from '../assets/about-main-banner.jpg';
import MainBanner02 from '../assets/about-main-banner02.jpg';
import ApplicationApp from '../assets/application-app.jpg';
import HeartIcon from '../assets/heart_plus.svg';
import CalendarIcon from '../assets/calendar_clock.svg';
import DocIcon from '../assets/doc_plus.svg';

const About = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();

  return (
    <Layout tabs={false}>
      <BrowserView>
        {/* 메인 배너 */}
        <BackgroundImage
          h={rem(600)}
          miw={rem(390)}
          mih={rem(430)}
          src={MainBanner}
          style={{
            zIndex: -2,
            position: 'relative',
          }}
          className={classes.sectionBackgroundPosition}
        >
          <Flex
            className={classes.mainContents01}
            direction={'column'}
            justify={'center'}
            align={'center'}
            gap={rem(0)}
          >
            <Image
              maw={rem(1020)}
              mah={rem(294)}
              miw={rem(370)}
              mih={rem(164)}
              src={Logo}
            />
            <span className={classes.aboutText}>ABOUT</span>
            <span className={classes.aboutSubText}>
              회사개요 | 연혁 | 사업분야 | CI & BI
            </span>
          </Flex>
        </BackgroundImage>

        <Flex direction={'column'} className={classes.introduceBox}>
          <Space mb={rem(100)} />
          <span className={classes.aboutDescription}>
            벤치는 환자, 보호자들이 신뢰할 수 있는 검증된 의료 영상 콘텐츠를
            주치의의 진료 프로세스에 맞추어 제작하고,
            <br />
            AI 알고리즘을 통해 진료 전 환자에게 자동 추천하여 교육하는 Dr.BEN
            플랫폼을 운영합니다.
          </span>
        </Flex>
        <Space mb={rem(100)} />
        {/* 세컨드 배너 */}
        <BackgroundImage
          // h={rem(600)}
          miw={rem(390)}
          mih={rem(430)}
          src={MainBanner02}
          style={{
            zIndex: -2,
            position: 'relative',
          }}
          className={classes.sectionBackgroundPosition}
        >
          <Flex
            className={classnames(classes.contents02, classes.m_contents02)}
            direction={'column'}
            justify={'center'}
            align={'center'}
            gap={rem(50)}
            style={{
              zIndex: -1,
              position: 'relative',
            }}
          >
            <Flex
              className={classes.card}
              gap={rem(10)}
              align={'center'}
              justify={'center'}
            >
              <span className={classes.featureTitle}>MISSION</span>
              <Space mr={rem(10)} />
              <Image
                src={HeartIcon}
                maw={rem(60)}
                mah={rem(60)}
                miw={rem(23)}
                mih={rem(23)}
              />
              <span className={classnames(classes.featureText)}>
                진료를 더욱 효율적으로, <br />
                의사- 환자 간의 신뢰관계 강화
              </span>
            </Flex>
            <Flex
              gap={rem(50)}
              align={'center'}
              className={classes.responsiveFlex}
            >
              <Flex
                className={classes.card}
                gap={rem(10)}
                align={'center'}
                justify={'center'}
              >
                <span className={classes.featureTitle}>VISION</span>
                <Space mr={rem(10)} />
                <Image
                  src={DocIcon}
                  maw={rem(60)}
                  mah={rem(60)}
                  miw={rem(23)}
                  mih={rem(23)}
                />
                <span className={classnames(classes.featureText)}>
                  신뢰에 기반한 환자-의사 관계의 <br />
                  정립을 통해 바른 진료 문화 정착
                </span>
              </Flex>
              <Flex
                className={classes.card}
                gap={rem(10)}
                align={'center'}
                justify={'center'}
              >
                <span className={classes.featureTitle}>
                  CORE
                  <br />
                  VALUE
                </span>
                <Space mr={rem(10)} />
                <Image
                  src={CalendarIcon}
                  maw={rem(60)}
                  mah={rem(60)}
                  miw={rem(23)}
                  mih={rem(23)}
                />
                <ul
                  className={classnames(classes.featureText, classes.cardList)}
                >
                  <li>진료시간의 효율화</li>
                  <li>진료 만족의 증대</li>
                  <li>환자와 의료진의 상호 노력 존중 및 강화</li>
                  <li>의료진 피로도 최소화</li>
                </ul>
              </Flex>
            </Flex>

            <span className={classes.cardLoop} />
          </Flex>
        </BackgroundImage>
        <Space mb={rem(50)} />
        <Flex direction={'column'} className={classes.introduceBox}>
          {/* 연혁 */}
          <span className={classes.companyOverviewTitle}>연혁</span>
          <Space mb={rem(30)} />
          <Flex justify={'flex-end'} gap={rem(100)}>
            <Flex gap={rem(102)}>
              <span className={classes.featureTitle}>2023</span>
              <ul
                className={classnames(classes.featureText, classes.featureList)}
              >
                <li>예비창업패키지 선정</li>
                <li>Dr.BEN 플랫폼 개발 착수</li>
                <li>주식회사 벤치 설립(23.08.02)</li>
                <li>뉴테크 특화콘텐츠 지원사업 선정</li>
                <li>특허 1건 출원</li>
                <li>미디어 뉴테크 대전 우수상</li>
              </ul>
            </Flex>
            <Flex gap={rem(102)}>
              <span className={classes.featureTitle}>2024</span>
              <ul
                className={classnames(classes.featureText, classes.featureList)}
              >
                <li>Dr.BEN 플랫폼 운영</li>
                <li>전향적 임상연구 진행</li>
              </ul>
            </Flex>
          </Flex>
          <Space mb={rem(100)} />

          {/* CI & BI */}
          <span className={classes.companyOverviewTitle}>CI & BI</span>
          <Flex direction={'column'} gap={rem(50)}>
            <Flex
              className={classes.feature04}
              direction={'column'}
              gap={rem(20)}
            >
              <Image
                maw={rem(1280)}
                mah={rem(352)}
                miw={rem(330)}
                mih={rem(102)}
                src={Brand}
              />
              <span>
                <strong className={classes.emphasis}>
                  환자들이 진료실 앞 의자에 대기하는 시간을 소중히 만들자
                </strong>
                는 의미와 함께
                <br />
                <strong className={classes.emphasis}>
                  환자 교육 영상을 제작하고 플랫폼을 제공하는 회사
                </strong>
                라는 의미를 나타낼 수 있도록 벤치와 카메라를 형상화하여
                <br />
                로고를 제작하였습니다. 이용자들에게 친근하고 쉽게 다가갈 수
                있도록 직관적이고 단순한 형태의 로고를 제작하였으며,
                <br />
                신뢰감과 안정감을 주는 짙은 청색을 조합하여 로고를
                제작하였습니다.
              </span>
            </Flex>
            <Flex
              className={classes.feature04}
              direction={'column'}
              gap={rem(20)}
            >
              <span className={classes.emphasis}>Logo construction</span>
              <Image
                maw={rem(1280)}
                mah={rem(223)}
                miw={rem(330)}
                mih={rem(102)}
                src={Feature02}
              />
            </Flex>
            <Flex
              className={classes.feature04}
              direction={'column'}
              gap={rem(20)}
            >
              <span className={classes.emphasis}>Dr.BEN Logo</span>
              <Flex align={'center'} gap={rem(124)} pl={rem(60)}>
                <Image
                  maw={rem(328)}
                  mah={rem(328)}
                  miw={rem(249)}
                  mih={rem(249)}
                  src={MLogo}
                />
                <span>
                  <strong className={classes.emphasis}>
                    벤치에서 만나는 의사
                  </strong>
                  라는 의미를 담아 플랫폼 이름을{' '}
                  <strong className={classes.emphasis}>닥터벤(Dr.BEN)</strong>
                  으로 지었습니다.
                  <br />
                  이용자들에게 친숙함과 안정감을 제공할 수 있도록 벤치 스튜디오
                  로고와 통일감 있는 형태로 제작하였습니다.
                </span>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <Space mb={rem(50)} />
      </BrowserView>
      <MobileView>
        <Space mb={rem(50)} />
        {/* 메인 배너 */}
        <BackgroundImage
          miw={rem(390)}
          mih={rem(430)}
          src={MainBanner}
          style={{
            zIndex: -2,
            position: 'relative',
          }}
          className={classes.m_sectionBackgroundPosition}
        >
          <Flex
            className={classnames(
              classes.mainContents01,
              classes.m_mainContents01
            )}
            direction={'column'}
            justify={'center'}
            align={'center'}
            gap={rem(0)}
          >
            <Image
              styles={{
                image: {
                  minWidth: rem(370),
                  minHeight: rem(164),
                },
              }}
              src={Logo}
            />
            <span className={classes.aboutText}>ABOUT</span>
            <span className={classes.aboutSubText}>
              회사개요 | 연혁 | 사업분야 | CI & BI
            </span>
          </Flex>
        </BackgroundImage>

        <Flex
          direction={'column'}
          className={classnames(classes.introduceBox, classes.m_introduceBox)}
        >
          <Space mb={rem(50)} />
          <span
            className={classnames(
              classes.aboutDescription,
              classes.m_aboutDescription
            )}
          >
            벤치는 환자, 보호자들이 신뢰할 수 있는 검증된
            <br /> 의료 영상 콘텐츠를 주치의의 진료 프로세스에
            <br /> 맞추어 제작하고, AI 알고리즘을 통해 진료 전 환자에게
            <br /> 자동 추천하여 교육하는 Dr.BEN 플랫폼을 운영합니다.
          </span>
          <Space mb={rem(50)} />
        </Flex>

        <BackgroundImage
          miw={rem(390)}
          mih={rem(430)}
          src={MainBanner02}
          style={{
            zIndex: -2,
            position: 'relative',
          }}
          className={classes.m_sectionBackgroundPosition}
        >
          <Flex
            className={classnames(classes.contents02, classes.m_contents02)}
            direction={'column'}
            justify={'center'}
            align={'center'}
            gap={rem(30)}
          >
            <Flex
              className={classes.m_card}
              gap={rem(10)}
              direction={'column'}
              align={'center'}
              justify={'center'}
            >
              <span className={classes.m_cardTitle}>MISSION</span>
              <Flex gap={rem(10)} align={'center'} justify={'center'}>
                <Image
                  src={HeartIcon}
                  maw={rem(60)}
                  mah={rem(60)}
                  miw={rem(23)}
                  mih={rem(23)}
                />
                <span className={classnames(classes.m_cardText)}>
                  진료를 더욱 효율적으로, <br />
                  의사- 환자 간의 신뢰관계 강화
                </span>
              </Flex>
            </Flex>
            <Flex
              className={classes.m_card}
              gap={rem(10)}
              direction={'column'}
              align={'center'}
              justify={'center'}
            >
              <span className={classes.m_cardTitle}>VISION</span>
              <Flex gap={rem(10)} align={'center'} justify={'center'}>
                <Image
                  src={DocIcon}
                  maw={rem(60)}
                  mah={rem(60)}
                  miw={rem(23)}
                  mih={rem(23)}
                />
                <span className={classnames(classes.m_cardText)}>
                  신뢰에 기반한 환자-의사 관계의 <br />
                  정립을 통해 바른 진료 문화 정착
                </span>
              </Flex>
            </Flex>
            <Flex
              className={classes.m_card}
              gap={rem(10)}
              direction={'column'}
              align={'center'}
              justify={'center'}
            >
              <span className={classes.m_cardTitle}>
                CORE
                <br />
                VALUE
              </span>
              <Flex gap={rem(10)} align={'center'} justify={'center'}>
                <Image
                  src={CalendarIcon}
                  maw={rem(60)}
                  mah={rem(60)}
                  miw={rem(23)}
                  mih={rem(23)}
                />

                <ul
                  className={classnames(classes.m_cardText, classes.cardList)}
                >
                  <li>진료시간의 효율화</li>
                  <li>진료 만족의 증대</li>
                  <li>환자와 의료진의 상호 노력 존중 및 강화</li>
                  <li>의료진 피로도 최소화</li>
                </ul>
              </Flex>
            </Flex>
          </Flex>
        </BackgroundImage>

        <Space mb={rem(28)} />
        <Flex
          direction={'column'}
          className={classnames(classes.introduceBox, classes.m_introduceBox)}
        >
          {/* 연혁 */}
          <span
            className={classnames(
              classes.companyOverviewTitle,
              classes.m_companyOverviewTitle
            )}
          >
            연혁
          </span>
          <Space mb={rem(30)} />
          <Flex direction={'column'} gap={rem(30)}>
            <Flex direction={'column'} gap={rem(20)}>
              <span className={classes.featureTitle}>2024</span>
              <ul
                className={classnames(classes.featureText, classes.featureList)}
              >
                <li>Dr.BEN 플랫폼 운영</li>
                <li>전향적 임상연구 진행</li>
              </ul>
            </Flex>
            <Flex direction={'column'} gap={rem(20)}>
              <span className={classes.featureTitle}>2023</span>
              <ul
                className={classnames(classes.featureText, classes.featureList)}
              >
                <li>예비창업패키지 선정</li>
                <li>Dr.BEN 플랫폼 개발 착수</li>
                <li>주식회사 벤치 설립(23.08.02)</li>
                <li>뉴테크 특화콘텐츠 지원사업 선정</li>
                <li>특허 1건 출원</li>
                <li>미디어 뉴테크 대전 우수상</li>
              </ul>
            </Flex>
          </Flex>
          <Space mb={rem(50)} />

          {/* CI & BI */}
          <span
            className={classnames(
              classes.companyOverviewTitle,
              classes.m_companyOverviewTitle
            )}
          >
            CI & BI
          </span>
          <Space mb={rem(20)} />
          <Flex direction={'column'} gap={rem(30)}>
            <Flex
              className={classnames(classes.feature04, classes.m_feature04)}
              direction={'column'}
              gap={rem(20)}
            >
              <Image
                miw={rem(330)}
                mih={rem(102)}
                styles={{
                  image: {
                    minWidth: rem(330),
                    minHeight: rem(102),
                  },
                }}
                src={Brand}
              />
              <span>
                <strong className={classes.emphasis}>
                  환자들이 진료실 앞 의자에 대기하는 시간을 소중히
                  <br /> 만들자
                </strong>
                는 의미와 함께{' '}
                <strong className={classes.emphasis}>
                  환자 교육 영상을 제작하고
                  <br /> 플랫폼을 제공하는 회사
                </strong>
                라는 의미를 나타낼 수 있도록
                <br /> 벤치와 카메라를 형상화하여 로고를 제작하였습니다.
                <br />
                이용자들에게 친근하고 쉽게 다가갈 수 있도록
                <br /> 직관적이고 단순한 형태의 로고를 제작하였으며,
                <br /> 신뢰감과 안정감을 주는 짙은 청색을 조합하여 로고를
                <br />
                제작하였습니다.
              </span>
            </Flex>
            <Flex
              className={classnames(classes.feature04, classes.m_feature04)}
              direction={'column'}
              gap={rem(20)}
            >
              <span className={classes.emphasis}>Logo construction</span>
              <Image
                maw={rem(1280)}
                mah={rem(223)}
                miw={rem(330)}
                mih={rem(249)}
                styles={{
                  image: {
                    minWidth: rem(330),
                    minHeight: rem(249),
                  },
                }}
                src={Feature01}
              />
            </Flex>
            <Flex
              className={classnames(classes.feature04, classes.m_feature04)}
              direction={'column'}
              gap={rem(20)}
            >
              <span className={classes.emphasis}>Dr.BEN Logo</span>
              <Flex direction={'column'} gap={rem(20)}>
                <Image
                  styles={{
                    image: {
                      minWidth: rem(249),
                      minHeight: rem(249),
                    },
                  }}
                  src={MLogo}
                />
                <span>
                  <strong className={classes.emphasis}>
                    벤치에서 만나는 의사
                  </strong>
                  라는 의미를 담아
                  <br /> 플랫폼 이름을
                  <strong className={classes.emphasis}>닥터벤(Dr.BEN)</strong>
                  으로 지었습니다.
                  <br />
                  이용자들에게 친숙함과 안정감을 제공할 수 있도록
                  <br /> 벤치 스튜디오 로고와 통일감 있는 형태로
                  <br /> 제작하였습니다.
                </span>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <Space mb={rem(50)} />
      </MobileView>
    </Layout>
  );
};

const useStyles = createStyles(() => ({
  mainContents01: {
    paddingTop: rem(84),
    paddingBottom: rem(103),
    paddingInline: rem(50),
    height: rem(600),
    boxSizing: 'border-box',
    color: '#2C93AD',
    ':after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(234, 244, 247, 0.6)',
      zIndex: -1,
    },
  },
  m_mainContents01: {
    padding: 0,
    height: rem(430),
    ':after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(234, 244, 247, 0.6)',
      zIndex: -1,
    },
  },
  contents02: {
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    ':after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(234, 244, 247, 0.6)',
      zIndex: -1,
    },
  },
  m_contents02: {
    paddingBlock: rem(58),
    width: '100%',
    height: '100%',
    ':after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(234, 244, 247, 0.6)',
      zIndex: -1,
    },
  },
  aboutDescription: {
    fontSize: rem(24),
    fontWeight: 500,
    lineHeight: rem(35),
    textAlign: 'center',
    wordBreak: 'keep-all',
  },
  m_aboutDescription: {
    fontSize: rem(16),
    lineHeight: rem(25),
  },
  sectionBackground: {
    ':before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
  },
  sectionWhiteBackground: {
    border: `${rem(1)} solid #CCCCCC`,
    borderRadius: rem(16),
    paddingLeft: rem(60),
    paddingRight: rem(154),
    ':before': {
      borderRadius: rem(16),
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(234, 244, 247, 0.6)',
    },
  },
  sectionBackgroundPosition: {
    backgroundSize: 'cover',
    backgroundPosition: '0 75%',
  },
  m_sectionBackgroundPosition: {
    backgroundSize: 'cover',
    backgroundPosition: '0% 0%',
  },
  introduceBox: {
    maxWidth: rem(1280),
    paddingInline: rem(30),
    marginInline: 'auto',
  },
  m_introduceBox: {
    width: rem(330),
  },
  mainContents02: {
    paddingBlock: rem(90),
    paddingBottom: rem(93),
  },
  mainContents03: {
    paddingBlock: rem(90),
    paddingInline: rem(160),
  },
  m_mainContents03: {
    paddingBlock: rem(50),
    paddingInline: rem(30),
  },
  mainContents04: {
    paddingInline: rem(160),
  },
  m_mainContents04: {
    paddingInline: rem(10),
  },
  aboutText: {
    fontSize: rem(36),
    fontWeight: 700,
    marginTop: rem(35),
  },
  aboutSubText: {
    marginTop: rem(20),
    fontSize: rem(18),
    fontWeight: 500,
  },
  companyOverviewTitle: {
    display: 'block',
    paddingBlock: rem(50),
    fontSize: rem(48),
    fontWeight: 800,
  },
  companyOverviewCenterTitle: {
    textAlign: 'center',
  },
  m_companyOverviewTitle: {
    fontSize: rem(36),
    padding: 0,
    paddingTop: rem(37),
  },
  companyOverviewText: {
    fontSize: rem(18),
    fontWeight: 500,
    color: '#666666',
    lineHeight: rem(30),
  },
  m_companyOverviewText: {
    fontSize: rem(16),
    lineHeight: rem(25),
    marginBlock: rem(30),
  },
  gridBox: {},
  gridBox02: {
    border: `${rem(1)} solid #cccccc`,
    borderRadius: rem(16),
    backgroundColor: 'white',
    fontSize: rem(18),
    fontWeight: 600,
    lineHeight: rem(25),
    paddingInline: rem(40),
    paddingBlock: rem(42),
  },
  m_gridBox02: {
    border: `${rem(1)} solid #cccccc`,
    borderRadius: rem(16),
    backgroundColor: 'white',
    fontSize: rem(16),
    fontWeight: 600,
    lineHeight: rem(25),
    paddingInline: rem(24),
    paddingBlock: rem(28),
  },
  overviewKey: {
    fontSize: rem(32),
    fontWeight: 600,
    color: '#666666',
  },
  m_overviewKey: {
    fontSize: rem(24),
  },
  overviewValue: {
    fontSize: rem(18),
    fontWeight: 600,
    color: '#2C93AD',
    lineHeight: rem(25),
  },
  m_overviewValue: {
    fontSize: rem(16),
  },
  overviewObjectDivider: {
    fontSize: rem(16),
    fontWeight: 500,
    color: '#CCCCCC',
    lineHeight: rem(25),
  },
  featureTitle: {
    fontSize: rem(48),
    fontWeight: 600,
    color: '#2C93AD',
  },
  m_featureTitle: {
    fontSize: rem(32),
  },
  featureText: {
    fontSize: rem(18),
    fontWeight: 600,
    lineHeight: rem(25),
  },
  businessSectorTitle: {
    fontSize: rem(32),
    fontWeight: 600,
  },
  m_businessSectorTitle: {
    fontSize: rem(24),
    fontWeight: 600,
  },
  businessSectorButton: {
    width: '100%',
    border: `${rem(1)} solid #2A93AD`,
    borderRadius: rem(16),
    color: '#2C93AD',
    textAlign: 'center',
    paddingBlock: rem(14),
    fontSize: rem(18),
    fontWeight: 500,
    lineHeight: rem(25),

    ':hover': {
      backgroundColor: '#2D93AD',
      color: 'white',
    },
  },
  featureList: {
    listStyle: 'inside',
    lineHeight: rem(40),
  },
  feature04: {
    fontSize: rem(18),
    fontWeight: 500,
    lineHeight: rem(25),
  },
  m_feature04: {
    fontSize: rem(16),
  },
  emphasis: {
    fontWeight: 700,
    color: '#2C93AD',
  },
  m_emphasis: {
    fontWeight: 700,
    color: '#2C93AD',
  },

  card: {
    position: 'relative',
    display: 'block',
    width: rem(600),
    height: rem(230),
    borderRadius: rem(100),
    backgroundColor: '#fff',
    boxShadow: `8px 0 40px rgba(0, 0, 0, 0.1)`,
    zIndex: 10,
  },
  m_card: {
    position: 'relative',
    display: 'block',
    width: rem(330),
    height: rem(200),
    borderRadius: rem(30),
    backgroundColor: '#fff',
    boxShadow: `8px 0 40px rgba(0, 0, 0, 0.1)`,
    zIndex: 10,
  },
  cardList: {
    listStyle: 'inside',
  },
  cardLoop: {
    position: 'absolute',
    width: rem(640),
    height: rem(356),
    borderRadius: '50%',
    border: `${rem(50)} solid #2C93AD`,
    zIndex: 1,
    [`@media (max-width: ${rem(1280)})`]: {
      borderRadius: '40%',
      width: rem(350),
      height: rem(550),
    },
  },
  m_cardTitle: {
    fontSize: rem(32),
    fontWeight: 500,
    color: '#2C93AD',
  },
  m_cardText: {
    fontSize: rem(16),
    fontWeight: 500,
    whiteSpace: 'nowrap',
    lineHeight: rem(25),
  },
  responsiveFlex: {
    display: 'flex',
    [`@media (max-width: ${rem(1280)})`]: {
      flexDirection: 'column',
    },
  },
}));

export default About;
