import { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Center,
  Flex,
  Input,
  LoadingOverlay,
  Modal,
  Select,
  Space,
  Stack,
  Title,
  UnstyledButton,
  createStyles,
  rem,
} from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { IconArrowLeft } from '@tabler/icons-react';
import { Layout } from 'src/components';
import { client } from 'src';
import { createVideoModel } from 'src/graphql/mutations';
import { listPartnerModels } from 'src/graphql/queries';
import { extract } from '@extractus/oembed-extractor';
import { useDisclosure } from '@mantine/hooks';

const VideoAdd = () => {
  const { classes } = useStyles();

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [linkSearchOpened, linkSearchHandler] = useDisclosure(false);

  const [partnerId, setPartnerId] = useState<
    { label: string; value: string }[]
  >([]);
  const [videoInfo, setVideoInfo] = useState({
    title: '',
    retitle: '',
    author: '',
    url: '',
    tag: '',
    partnermodelID: '',
  });

  useEffect(() => {
    onGetPartner();
  }, []);

  const onGetPartner = async () => {
    const result = await client.graphql({ query: listPartnerModels });
    const list = result.data.listPartnerModels.items;

    const selectList = list.map((data) => {
      const { id, name } = data;
      return {
        label: name,
        value: id,
      };
    });
    setPartnerId(selectList);
  };

  const onAddVideo = async () => {
    const { title, retitle, author, url, tag, partnermodelID } = videoInfo;

    if (
      title.length == 0 ||
      author.length == 0 ||
      url.length == 0 ||
      partnermodelID.length == 0
    ) {
      alert('필수 항목이 입력되지 않았습니다. 입력해 주세요.');
      return;
    }
    try {
      setIsLoading(true);
      const result = await client.graphql({
        query: createVideoModel,
        variables: {
          input: {
            title,
            retitle: retitle.length === 0 ? title : retitle,
            author,
            url,
            tag,
            partnermodelID: partnermodelID,
          },
        },
      });

      if (result.errors && result.errors.length > 0) {
        alert('데이터 추가를 실패했습니다. 다시 시도해 주세요.');
        return;
      }

      setTimeout(() => {
        setIsLoading(false);
        navigate('/admin/video');
      }, 1800);
    } catch (error) {
      console.error(error);
    }
  };

  const onSearchVideoInfo = async () => {
    try {
      const result = await extract(videoInfo.url);

      if (videoInfo.url.length == 0) {
        alert('링크를 입력해 주세요.');
        return;
      } else {
        setVideoInfo({
          ...videoInfo,
          title: result.title || 'Undefined',
          url: videoInfo.url,
        });

        linkSearchHandler.close();
      }
    } catch (error) {
      console.error('잘못된 URL: ', error);
      alert('올바른 URL을 입력해 주세요.');
    }
  };

  return (
    <Layout>
      <LoadingOverlay style={{ position: 'fixed' }} visible={isLoading} />
      <Space w={1} h={1} mb={rem(50)} />
      <Flex direction={'column'} justify={'center'} align={'center'} h={'100%'}>
        <Box className={classes.buttonList}>
          <Flex justify={'space-between'}>
            <UnstyledButton onClick={() => navigate('/admin/video')}>
              <IconArrowLeft />
            </UnstyledButton>
            <Title order={4}>
              <span>비디오 추가</span>
            </Title>
            <Button onClick={onAddVideo}>추가</Button>
          </Flex>
          <Stack>
            <Box>
              <Input.Wrapper label="수정 제목">
                <Input
                  onChange={(event) =>
                    setVideoInfo((prev) => ({
                      ...prev,
                      retitle: event.target.value,
                    }))
                  }
                />
              </Input.Wrapper>
            </Box>
            <Box>
              <Input.Wrapper label="작성자" withAsterisk>
                <Input
                  onChange={(event) =>
                    setVideoInfo((prev) => ({
                      ...prev,
                      author: event.target.value,
                    }))
                  }
                />
              </Input.Wrapper>
            </Box>
            <Box>
              <Flex direction={'column'}>
                <Input.Wrapper label="원제목" withAsterisk>
                  <Input disabled value={videoInfo.title} />
                </Input.Wrapper>
                <Flex align={'flex-end'} gap={rem(10)}>
                  <Input.Wrapper style={{ flex: 1 }} label="URL" withAsterisk>
                    <Input disabled value={videoInfo.url} />
                  </Input.Wrapper>
                  <Button onClick={linkSearchHandler.open} variant="default">
                    찾기
                  </Button>
                </Flex>
              </Flex>
            </Box>
            <Box>
              <Input.Wrapper label="태그">
                <Input
                  placeholder="ex ) 질병, 약품, 진단"
                  onChange={(event) =>
                    setVideoInfo((prev) => ({
                      ...prev,
                      tag: event.target.value,
                    }))
                  }
                />
              </Input.Wrapper>
            </Box>
            <Box>
              <Select
                label="파트너"
                withAsterisk
                data={partnerId}
                onChange={(value) =>
                  setVideoInfo((prev) => ({
                    ...prev,
                    partnermodelID: value || '',
                  }))
                }
              />
            </Box>
          </Stack>
        </Box>
      </Flex>
      <Modal
        title="영상 링크 정보 찾기"
        opened={linkSearchOpened}
        onClose={linkSearchHandler.close}
      >
        <Input.Wrapper label="URL">
          <Input
            onChange={(event) =>
              setVideoInfo((prev) => ({
                ...prev,
                url: event.target.value,
              }))
            }
          />
        </Input.Wrapper>
        <Space w={1} h={1} mb={rem(20)} />
        <Center>
          <Button onClick={onSearchVideoInfo}>확인</Button>
        </Center>
      </Modal>
    </Layout>
  );
};

const useStyles = createStyles(() => ({
  buttonList: {
    width: '80%',
    maxWidth: rem(500),
  },
}));

export default VideoAdd;
