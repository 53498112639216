const onCheckUser = () => {
  const userCheckString = localStorage.getItem('login');
  const userCheckBoolean = JSON.parse(userCheckString || 'false');

  if (userCheckBoolean) {
    return true;
  }
  return false;
};

export default onCheckUser;
