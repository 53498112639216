import {
  Box,
  Button,
  Flex,
  Input,
  LoadingOverlay,
  Space,
  Stack,
  Title,
  UnstyledButton,
  createStyles,
  rem,
} from '@mantine/core';
import { useNavigate, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { IconArrowLeft } from '@tabler/icons-react';
import { Layout } from 'src/components';
import { client } from 'src';
import { getDepartmentModel } from 'src/graphql/queries';
import { updateDepartmentModel } from 'src/graphql/mutations';

const DepartmentEdit = () => {
  const { classes } = useStyles();

  const { id } = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [dept, setDept] = useState({
    hospital: '',
    dept: '',
  });

  useEffect(() => {
    onGetDept();
  }, []);

  const onGetDept = async () => {
    try {
      const result = await client.graphql({
        query: getDepartmentModel,
        variables: {
          id: String(id),
        },
      });

      if (result.errors && result.errors.length > 0) {
        alert('데이터 불러오는데 실패했습니다. 다시 시도해 주세요.');
        return;
      }

      const item = result.data.getDepartmentModel;

      if (item) {
        setDept({
          hospital: item.hospital,
          dept: item.dept,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onEditDept = async () => {
    try {
      if (dept.hospital.length == 0 || dept.dept.length == 0) {
        alert('필수 항목이 입력되지 않았습니다. 입력해 주세요.');
        return;
      }
      setIsLoading(true);
      const result = await client.graphql({
        query: updateDepartmentModel,
        variables: {
          input: {
            id: String(id),
            hospital: dept.hospital,
            dept: dept.dept,
          },
        },
      });

      if (result.errors && result.errors.length > 0) {
        alert('데이터 수정을 실패했습니다. 다시 시도해 주세요.');
        return;
      }

      setTimeout(() => {
        setIsLoading(false);
        navigate('/admin/department');
      }, 1800);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Layout>
      <LoadingOverlay style={{ position: 'fixed' }} visible={isLoading} />
      <Space w={1} h={1} mb={rem(50)} />
      <Flex direction={'column'} justify={'center'} align={'center'} h={'100%'}>
        <Box className={classes.form}>
          <Flex justify={'space-between'}>
            <UnstyledButton onClick={() => navigate('/admin/department')}>
              <IconArrowLeft />
            </UnstyledButton>
            <Title order={4}>
              <span>부서 정보 수정</span>
            </Title>
            <Button onClick={onEditDept}>수정</Button>
          </Flex>
          <Stack>
            <Box>
              <Input.Wrapper label="병원" withAsterisk>
                <Input
                  value={dept.hospital}
                  onChange={(event) =>
                    setDept({ ...dept, hospital: event.target.value })
                  }
                />
              </Input.Wrapper>
            </Box>
            <Box>
              <Input.Wrapper label="소속" withAsterisk>
                <Input
                  value={dept.dept}
                  onChange={(event) =>
                    setDept({ ...dept, dept: event.currentTarget?.value })
                  }
                />
              </Input.Wrapper>
            </Box>
          </Stack>
        </Box>
      </Flex>
    </Layout>
  );
};

const useStyles = createStyles(() => ({
  form: {
    width: '80%',
    maxWidth: rem(500),
  },
}));

export default DepartmentEdit;
