import {
  Box,
  Center,
  Flex,
  Space,
  UnstyledButton,
  createStyles,
  rem,
} from '@mantine/core';
import {
  IconBuildingHospital,
  IconPill,
  IconClipboardText,
  IconBrandZoom,
  IconUsers,
  IconCertificate,
} from '@tabler/icons-react';
import { cloneElement, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { savePageNumber } from 'src/utils/localStoragePage';

const iconStyle = { width: rem(12), height: rem(12) };

const TabList = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const onNavigate = (path: string) => {
    savePageNumber(1);
    navigate(path);
  };

  const tabButtons = useMemo(() => {
    const createTabButton = (
      icon: React.ReactElement,
      text: string,
      path: string
    ) => (
      <UnstyledButton
        key={path}
        style={{
          borderBottom: pathname === path ? '2px solid #228be6' : 'transparent',
        }}
        className={classes.tabButton}
        onClick={() => onNavigate(path)}
      >
        {cloneElement(icon, { style: iconStyle })}
        <span className={classes.tabText}>{text}</span>
      </UnstyledButton>
    );
    return [
      createTabButton(<IconUsers />, 'PATIENT', '/admin/patient'),
      createTabButton(<IconCertificate />, 'PARTNER', '/admin/partner'),
      createTabButton(
        <IconBuildingHospital />,
        'DEPARTMENT',
        '/admin/department'
      ),
      createTabButton(<IconPill />, 'MEDICATION', '/admin/medication'),
      createTabButton(<IconClipboardText />, 'DIAGNOSIS', '/admin/diagnosis'),
      createTabButton(<IconBrandZoom />, 'VIDEO', '/admin/video'),
    ];
  }, [pathname]);

  return (
    <>
      <Space w={1} h={1} mb={rem(8)} />
      <Center>
        <Box style={{ width: '100%', maxWidth: rem(1190) }}>
          <Flex
            style={{ marginBottom: rem(2), borderBottom: '2px solid #cccccc' }}
          >
            {tabButtons}
          </Flex>
        </Box>
      </Center>
    </>
  );
};

const useStyles = createStyles(() => ({
  tabText: {
    fontSize: rem(14),
  },
  tabButton: {
    padding: rem(10),
    marginBottom: rem(-2),
    display: 'flex',
    gap: rem(5),
  },
}));

export default TabList;
