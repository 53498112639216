import { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Center,
  Flex,
  Input,
  LoadingOverlay,
  Select,
  Space,
  Stack,
  Title,
  UnstyledButton,
  createStyles,
  rem,
} from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { IconArrowLeft } from '@tabler/icons-react';
import { Layout } from 'src/components';
import { client } from 'src';
import { listDepartmentModels } from 'src/graphql/queries';
import { createPartnerModel } from 'src/graphql/mutations';

const PartnerAdd = () => {
  const { classes } = useStyles();

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [departments, setDepartments] = useState<
    { label: string; value: string }[]
  >([]);

  const [partnerInfo, setPartnerInfo] = useState({
    name: '',
    phone: '',
    dept: '',
  });

  useEffect(() => {
    onGetDepartments();
  }, []);

  const onGetDepartments = async () => {
    const result = await client.graphql({ query: listDepartmentModels });
    const list = result.data.listDepartmentModels.items;

    const selectList = list.map((data) => {
      const { id, hospital, dept } = data;
      return {
        label: `${hospital} - ${dept}`,
        value: id,
      };
    });
    setDepartments(selectList);
  };

  const onAddPartner = async () => {
    const { name, phone, dept } = partnerInfo;

    if (
      name.length == 0 ||
      phone.length == 0 ||
      phone.length !== 11 ||
      dept.length == 0
    ) {
      alert('필수 항목이 올바르게 입력되지 않았습니다. 입력해 주세요.');
      return;
    }
    try {
      setIsLoading(true);
      const result = await client.graphql({
        query: createPartnerModel,
        variables: {
          input: {
            name: name,
            username: `+82${phone.slice(1, undefined)}`,
            departmentmodelID: dept,
            point: 0,
          },
        },
      });

      if (result.errors && result.errors.length > 0) {
        alert('데이터 추가에 실패했습니다. 다시 시도해 주세요.');
        return;
      }

      setTimeout(() => {
        setIsLoading(false);
        navigate('/admin/partner');
      }, 1800);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Layout tabs={true}>
      <LoadingOverlay style={{ position: 'fixed' }} visible={isLoading} />
      <Space w={1} h={1} mb={rem(50)} />
      <Flex direction={'column'} justify={'center'} align={'center'} h={'100%'}>
        <Box className={classes.form}>
          <Flex justify={'space-between'}>
            <UnstyledButton onClick={() => navigate('/admin/partner')}>
              <IconArrowLeft />
            </UnstyledButton>
            <Center>
              <Title order={4}>
                <span>파트너 추가</span>
              </Title>
            </Center>
            <Button onClick={onAddPartner}>추가</Button>
          </Flex>
          <Stack>
            <Box>
              <Input.Wrapper withAsterisk label="이름">
                <Input
                  onChange={(event) =>
                    setPartnerInfo((prev) => ({
                      ...prev,
                      name: event.target.value,
                    }))
                  }
                />
              </Input.Wrapper>
            </Box>
            <Box>
              <Input.Wrapper label="전화번호" withAsterisk>
                <Input
                  placeholder="'-'를 제외하고 입력해 주세요."
                  onChange={(event) =>
                    setPartnerInfo((prev) => ({
                      ...prev,
                      phone: event.target.value,
                    }))
                  }
                />
              </Input.Wrapper>
            </Box>
            <Box>
              <Select
                label="부서"
                withAsterisk
                data={departments}
                onChange={(value) =>
                  setPartnerInfo((prev) => ({ ...prev, dept: value || '' }))
                }
              />
            </Box>
          </Stack>
        </Box>
      </Flex>
    </Layout>
  );
};

const useStyles = createStyles(() => ({
  form: {
    width: '80%',
    maxWidth: rem(500),
  },
}));

export default PartnerAdd;
