import {
  Box,
  Center,
  Flex,
  HoverCard,
  Image,
  Stack,
  UnstyledButton,
  createStyles,
  rem,
} from '@mantine/core';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import B_Logo from '../assets/logo.png';
import M_Logo from '../assets/fit_logo.png';

import { ReactNode, useEffect, useMemo, useState } from 'react';
import { MobileView, BrowserView } from 'react-device-detect';
import TabList from './TabList';
import classnames from 'classnames';
import Hamburger from '../assets/hamburger.png';
import Close from '../assets/close.svg';

interface LayoutType {
  tabs?: boolean;
  children: ReactNode;
}

const Layout = ({ tabs = true, children }: LayoutType) => {
  const { classes } = useStyles();
  const navigate = useNavigate();

  const [toggle, setToggle] = useState(false);
  const [isLogin, setIsLogin] = useState(false);

  const location = useLocation();

  useEffect(() => {
    const onFetch = async () => {
      const loginStatus = await JSON.parse(
        localStorage.getItem('login') ?? 'false'
      );

      setIsLogin(loginStatus);
    };

    onFetch();
  }, []);

  const onLogOut = async () => {
    if (location.pathname === '/login' && !isLogin) {
      alert('이미 로그아웃 상태입니다.');
      return;
    }

    if (isLogin && location.pathname.includes('admin')) {
      localStorage.setItem('login', 'false');
      navigate('/login');
    }
  };

  const headerContent = useMemo(
    () => (
      <header className={classes.header}>
        <BrowserView className={classes.b_header}>
          <div className={classes.contents}>
            <Flex h={'100%'} justify={'space-between'} align={'center'}>
              <Link to={'/'}>
                <Image src={B_Logo} />
              </Link>
              <Flex align={'center'} gap={rem(20)}>
                <Link
                  className={classnames(
                    classes.navLink,
                    location.pathname === '/about' && classes.activeNavLink
                  )}
                  to={'/about'}
                >
                  ABOUT
                </Link>
                <HoverCard shadow="md">
                  <HoverCard.Target>
                    <Link
                      className={classnames(
                        classes.navLink,
                        location.pathname === '/service' &&
                          classes.activeNavLink
                      )}
                      to={'/service'}
                    >
                      SERVICE
                    </Link>
                  </HoverCard.Target>
                  <HoverCard.Dropdown>
                    <Flex direction={'column'} align={'center'} gap={rem(20)}>
                      <Link
                        className={classnames(
                          classes.navLink,
                          location.pathname ===
                            '/service/ai-video-production' &&
                            classes.activeNavLink
                        )}
                        to={'/service/ai-video-production'}
                      >
                        영상 제작
                      </Link>
                      <Link
                        className={classnames(
                          classes.navLink,
                          location.pathname === '/service/app-introduction' &&
                            classes.activeNavLink
                        )}
                        to={'/service/app-introduction'}
                      >
                        닥터벤
                      </Link>
                    </Flex>
                  </HoverCard.Dropdown>
                </HoverCard>

                <Link
                  className={classnames(
                    classes.navLink,
                    location.pathname === '/pricing' && classes.activeNavLink
                  )}
                  to={'/pricing'}
                >
                  PRICING
                </Link>
                <Link
                  className={classnames(
                    classes.navLink,
                    location.pathname === '/contact' && classes.activeNavLink
                  )}
                  to={'/contact'}
                >
                  CONTACT
                </Link>
              </Flex>
            </Flex>
          </div>
        </BrowserView>
        <MobileView className={classes.m_header}>
          <Flex justify={'space-between'} align={'center'} h={rem(69)}>
            <Link to={'/'}>
              <Image width={rem(69)} src={M_Logo} />
            </Link>
            <UnstyledButton onClick={() => setToggle((prev) => !prev)}>
              <Image src={Hamburger} />
            </UnstyledButton>
          </Flex>
        </MobileView>
      </header>
    ),
    []
  );

  const mainContent = useMemo(
    () => (
      <main className={classes.main}>
        {tabs ? (
          <Center>
            <Stack className={classes.mainContainer}>
              <TabList />
              {children}
            </Stack>
          </Center>
        ) : (
          children
        )}
      </main>
    ),
    [children]
  );

  const footerContent = useMemo(
    () => (
      <footer className={classes.footer}>
        <BrowserView className={classes.b_footer}>
          <Center className={classes.centerFooter}>
            <Flex direction={'column'} align={'center'} gap={rem(36)}>
              <Flex className={classes.lineHeightFooter} direction={'column'}>
                <span>주식회사 벤치 | 대구광역시 달서구 달구벌대로 1053</span>
                <span>대표자 김인철 | 설립일 2023.08.01</span>
                <span>문의 및 제안 drben.benchstudio@gmail.com</span>
              </Flex>
              <Flex gap={rem(10)}>
                <span>
                  <Link className={classes.link} to={'/terms'}>
                    이용약관
                  </Link>
                </span>
                <span>
                  <Link
                    className={classes.link}
                    style={{
                      fontWeight: 700,
                    }}
                    to={'/privacy'}
                  >
                    개인정보보호정책
                  </Link>
                </span>
                <span>
                  <Link
                    className={classes.link}
                    to={isLogin ? '/admin/patient' : '/login'}
                  >
                    관리자 메뉴
                  </Link>
                </span>
                {isLogin && (
                  <UnstyledButton className={classes.link} onClick={onLogOut}>
                    <span>로그아웃</span>
                  </UnstyledButton>
                )}
              </Flex>
            </Flex>
          </Center>
        </BrowserView>
        <MobileView className={classes.m_footer}>
          <Center className={classes.centerFooter} pt={rem(10)}>
            <Flex direction={'column'} align={'center'} gap={rem(36)}>
              <Flex className={classes.lineHeightFooter} direction={'column'}>
                <span>주식회사 벤치 | 대구광역시 달서구 달구벌대로 1053</span>
                <span>대표자 김인철 | 설립일 2023.08.01</span>
                <span>문의 및 제안 drben.benchstudio@gmail.com</span>
              </Flex>
              <Flex gap={rem(10)}>
                <span>
                  <Link className={classes.link} to={'/terms'}>
                    이용약관
                  </Link>
                </span>
                <span>
                  <Link
                    className={classes.link}
                    style={{
                      fontWeight: 700,
                    }}
                    to={'/privacy'}
                  >
                    개인정보보호정책
                  </Link>
                </span>
                <span>
                  <Link
                    className={classes.link}
                    to={isLogin ? '/admin/patient' : '/login'}
                  >
                    관리자 메뉴
                  </Link>
                </span>
                {isLogin ? (
                  <UnstyledButton className={classes.link} onClick={onLogOut}>
                    <span>로그아웃</span>
                  </UnstyledButton>
                ) : null}
              </Flex>
            </Flex>
          </Center>
        </MobileView>
      </footer>
    ),
    [isLogin]
  );

  return (
    <div className={classes.container}>
      {headerContent}
      {mainContent}
      {footerContent}
      {toggle ? (
        <>
          <Box className={classes.sideShadowBox}></Box>
          <Box className={classes.sideBox}>
            <UnstyledButton
              className={classes.sideCloseButton}
              onClick={() => {
                setToggle(false);
              }}
            >
              <Image src={Close} />
            </UnstyledButton>
            <Flex direction={'column'} gap={rem(50)}>
              <Flex direction={'column'} gap={rem(30)}>
                <Link
                  className={classnames(classes.navLink, classes.m_navLink)}
                  to={'/about'}
                >
                  ABOUT
                </Link>
                <Flex direction={'column'} gap={rem(30)}>
                  <Link
                    className={classnames(classes.navLink, classes.m_navLink)}
                    to={'/service'}
                  >
                    SERVICE
                  </Link>
                  <Flex direction={'column'} gap={rem(30)} pl={rem(30)}>
                    <Link
                      className={classnames(classes.navLink, classes.m_navLink)}
                      to={'/service/ai-video-production'}
                    >
                      영상 제작
                    </Link>
                    <Link
                      className={classnames(classes.navLink, classes.m_navLink)}
                      to={'/service/app-introduction'}
                    >
                      닥터벤
                    </Link>
                  </Flex>
                </Flex>
                <Link
                  className={classnames(classes.navLink, classes.m_navLink)}
                  to={'/pricing'}
                >
                  PRICING
                </Link>
                <Link
                  className={classnames(classes.navLink, classes.m_navLink)}
                  to={'/contact'}
                >
                  CONTACT
                </Link>
              </Flex>
            </Flex>
          </Box>
        </>
      ) : null}
    </div>
  );
};

const useStyles = createStyles(() => ({
  container: {
    height: 'inherit',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  contents: {
    width: rem(1280),
  },
  header: {
    width: '100%',
    backgroundColor: 'white',
  },
  b_header: {
    paddingBlock: rem(18),
    display: 'flex',
    justifyContent: 'center',
  },
  m_header: {
    paddingLeft: rem(19),
    paddingRight: rem(24),
    paddingTop: rem(10),
    maxHeight: rem(32),
  },
  navLink: {
    width: rem(100),
    textAlign: 'center',
    color: '#666666',
    textDecoration: 'none',
    ':hover': {
      fontWeight: 600,
    },
  },
  m_navLink: {
    fontSize: rem(20),
    width: 'auto',
    textAlign: 'start',
  },
  activeNavLink: {
    color: '#14C7C1',
    fontWeight: 600,
  },
  sideBox: {
    position: 'absolute',
    right: 0,
    backgroundColor: 'rgba(255,255,255,.9)',
    height: 'inherit',
    width: '70%',
    zIndex: 999,
    padding: rem(30),
  },
  sideShadowBox: {
    position: 'absolute',
    zIndex: 999,
    left: 0,
    right: 0,
    backgroundColor: 'rgba(0,0,0,0.5)',
    height: 'inherit',
  },
  sideCloseButton: {
    zIndex: 999,
    position: 'absolute',
    top: 0,
    left: -53,
    padding: rem(8),
  },
  main: { flex: 1, width: '100%' },
  mainContainer: {
    width: rem(1190),
  },
  footer: {
    width: '100%',
    borderTop: '1px solid #eeeeee',
    fontSize: rem(14),
    backgroundColor: '#2C93AD',
  },
  b_footer: {
    paddingBlock: rem(40),
  },
  m_footer: {
    paddingBlock: rem(50),
  },
  centerFooter: {
    width: '100%',
    textAlign: 'center',
    verticalAlign: 'middle',
    color: 'white',
    height: rem(96),
  },
  lineHeightFooter: {
    lineHeight: rem(17),
  },
  link: {
    color: 'white',
    textDecoration: 'none',
    fontSize: rem(14),
  },
}));

export default Layout;
