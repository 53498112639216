import {
  BackgroundImage,
  Flex,
  Image,
  Space,
  UnstyledButton,
  createStyles,
  rem,
} from '@mantine/core';

import MainBanner from '../assets/service-main-banner.jpg';
import Logo from '../assets/black-logo.png';
import PhotoBanner from '../assets/photo-section-banner.jpg';
import AppUI from '../assets/application-app.jpg';
import ContactBanner from '../assets/contact-main-banner.jpg';

import { Layout } from 'src/components';
import classnames from 'classnames';
import { BrowserView, MobileView } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

const Service = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  return (
    <Layout tabs={false}>
      <BrowserView>
        {/* 메인 배너 */}
        <BackgroundImage
          h={rem(600)}
          mih={rem(430)}
          src={MainBanner}
          style={{
            zIndex: -2,
            position: 'relative',
          }}
        >
          <Flex
            className={classes.mainContents01}
            direction={'column'}
            justify={'center'}
            align={'center'}
            gap={rem(35)}
          >
            <Image
              maw={rem(1020)}
              mah={rem(294)}
              miw={rem(370)}
              mih={rem(164)}
              src={Logo}
            />
            <Flex direction={'column'} align={'center'} gap={rem(20)}>
              <span className={classes.serviceText}>SERVICE</span>
              <span className={classes.serviceSubText}>사업분야</span>
            </Flex>
          </Flex>
        </BackgroundImage>
        <Space mb={rem(100)} />
        <Flex
          className={classes.introduceBox}
          justify={'space-between'}
          gap={rem(10)}
        >
          <BackgroundImage
            maw={rem(480)}
            src={PhotoBanner}
            style={{
              position: 'relative',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: rem(16),
            }}
            className={classes.sectionBackground}
          >
            <Flex
              className={classes.sectionBox}
              direction={'column'}
              align={'space-between'}
              gap={rem(95)}
            >
              <Flex direction={'column'} gap={rem(30)}>
                <span className={classes.aiTitle}>
                  주치의 기반 의료 교육 영상 제작
                </span>
                <span className={classes.aiText}>
                  영상 기획부터 제작 및 배포까지,
                  <br /> 풍부한 임상과 연구 경험을 보유한 벤치의 직원들과 함께
                  <br /> 신뢰할 수 있는 의료 교육 영상 콘텐츠를
                  <br />
                  나의 진료 프로세스에 맞추어 제작할 수 있어요
                </span>
              </Flex>
              <UnstyledButton
                onClick={() => navigate('/service/ai-video-production')}
                className={classes.button}
              >
                <span>자세히보기</span>
              </UnstyledButton>
            </Flex>
          </BackgroundImage>
          <BackgroundImage
            src={AppUI}
            maw={rem(480)}
            style={{
              position: 'relative',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: rem(16),
            }}
            className={classes.sectionBackground}
          >
            <Flex
              className={classes.sectionBox}
              direction={'column'}
              align={'space-between'}
              gap={rem(95)}
            >
              <Flex direction={'column'} gap={rem(30)}>
                <span className={classes.aiTitle}>닥터벤 (Dr.BEN) 플랫폼</span>
                <span className={classes.aiText}>
                  손 안에서 내가 가진 질환에 대한 모든 궁금증 해결!
                  <br />
                  AI 알고리즘을 통해 각 환자에게 맞춤형 교육 영상을
                  <br /> 제공하는 플랫폼 &apos;닥터벤&apos;과 함께 내가 가진
                  질환에 대한
                  <br />
                  세세한 궁금증을 모두 해결해 보아요
                </span>
              </Flex>
              <UnstyledButton
                onClick={() => navigate('/service/app-introduction')}
                className={classes.button}
              >
                <span>자세히보기</span>
              </UnstyledButton>
            </Flex>
          </BackgroundImage>
          <BackgroundImage
            src={ContactBanner}
            maw={rem(480)}
            style={{
              position: 'relative',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: rem(16),
            }}
            className={classes.sectionBackground}
          >
            <Flex
              className={classes.sectionBox}
              direction={'column'}
              align={'space-between'}
              gap={rem(95)}
            >
              <Flex direction={'column'} gap={rem(30)}>
                <span className={classes.aiTitle}>진료 효율화 컨설팅</span>
                <span className={classes.aiText}>
                  길어지는 진료 시간과 늘어나는 대기환자,
                  <br /> 보호자들의 불만으로 고민이신가요?
                  <br />
                  의료 영상을 통한 사전 교육으로 설명 시간을 획기적으로
                  <br /> 줄이고 더욱 효율적인 진료 문화를 만들 수 있습니다.
                  <br />
                  벤치에서 관련 컨설팅을 지금 바로 받아보세요
                </span>
              </Flex>
              <UnstyledButton
                onClick={() => navigate('/contact')}
                className={classes.button}
              >
                <span>문의하기</span>
              </UnstyledButton>
            </Flex>
          </BackgroundImage>
        </Flex>
        <Space mb={rem(100)} />
      </BrowserView>
      <MobileView>
        <Space w={4} h={5} mb={rem(42)} />
        {/* 메인 배너 */}
        <BackgroundImage
          miw={rem(390)}
          mih={rem(430)}
          src={MainBanner}
          style={{
            zIndex: -2,
            position: 'relative',
          }}
          className={classes.m_mainBackgroundPosition}
        >
          <Flex
            className={classnames(
              classes.mainContents01,
              classes.m_mainContents1
            )}
            direction={'column'}
            justify={'center'}
            align={'center'}
            gap={rem(50)}
          >
            <Image className={classes.logoImage} src={Logo} />
            <Flex direction={'column'} align={'center'}>
              <span className={classes.serviceText}>SERVICE</span>
              <span className={classes.serviceSubText}>사업분야</span>
            </Flex>
          </Flex>
        </BackgroundImage>
        <Space mb={rem(50)} />
        <BackgroundImage
          mih={rem(330)}
          src={PhotoBanner}
          style={{
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 0,
          }}
          className={classnames(
            classes.sectionBackground,
            classes.m_sectionBackground
          )}
        >
          <Flex
            direction={'column'}
            className={classes.m_sectionBox}
            justify={'space-between'}
            gap={rem(20)}
          >
            <Flex direction={'column'} gap={rem(20)}>
              <span className={classnames(classes.aiTitle, classes.m_aiTitle)}>
                주치의 기반
                <br /> 의료 교육 영상 제작
              </span>
              <span className={classnames(classes.aiText, classes.m_aiText)}>
                영상 기획부터 제작 및 배포까지,
                <br /> 풍부한 임상과 연구 경험을 보유한 벤치의 직원들과
                <br /> 함께 신뢰할 수 있는 의료 교육 영상 콘텐츠를
                <br /> 나의 진료 프로세스에 맞추어 제작할 수 있어요
              </span>
            </Flex>
            <UnstyledButton
              onClick={() => navigate('/service/ai-video-production')}
              className={classes.button}
            >
              <span>자세히보기</span>
            </UnstyledButton>
          </Flex>
        </BackgroundImage>
        <Space mb={rem(30)} />
        <BackgroundImage
          mih={rem(330)}
          src={AppUI}
          style={{
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: rem(0),
          }}
          className={classnames(
            classes.sectionBackground,
            classes.m_sectionBackground
          )}
        >
          <Flex
            direction={'column'}
            className={classes.m_sectionBox}
            justify={'space-between'}
            gap={rem(20)}
          >
            <Flex direction={'column'} gap={rem(20)}>
              <span className={classnames(classes.aiTitle, classes.m_aiTitle)}>
                닥터벤 (Dr.BEN) 플랫폼
              </span>
              <span className={classnames(classes.aiText, classes.m_aiText)}>
                손 안에서 내가 가진 질환에 대한 <br className={classes.br02} />
                모든 궁금증 해결!
                <br className={classes.br02} /> <br className={classes.br01} />
                AI 알고리즘을 통해 각 환자에게 맞춤형
                <br className={classes.br02} /> 교육 영상을
                <br className={classes.br01} />
                제공하는 플랫폼 &apos;닥터벤&apos;과 함께
                <br className={classes.br02} /> 내가 가진 질환에
                <br className={classes.br01} />
                대한 세세한 궁금증을 모두
                <br className={classes.br02} /> 해결해 보아요
              </span>
            </Flex>
            <UnstyledButton
              onClick={() => navigate('/service/app-introduction')}
              className={classes.button}
            >
              <span>자세히보기</span>
            </UnstyledButton>
          </Flex>
        </BackgroundImage>
        <Space mb={rem(30)} />
        <BackgroundImage
          miw={rem(390)}
          mih={rem(330)}
          src={ContactBanner}
          style={{
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 0,
          }}
          className={classnames(
            classes.sectionBackground,
            classes.m_sectionBackground
          )}
        >
          <Flex
            direction={'column'}
            className={classes.m_sectionBox}
            justify={'space-between'}
            gap={rem(20)}
          >
            <Flex direction={'column'} gap={rem(20)}>
              <span className={classnames(classes.aiTitle, classes.m_aiTitle)}>
                진료 효율화 컨설팅
              </span>
              <span className={classnames(classes.aiText, classes.m_aiText)}>
                길어지는 진료 시간과 늘어나는 대기환자,
                <br className={classes.br02} /> 보호자들의
                <br className={classes.br01} /> 불만으로 고민이신가요?
                <br className={classes.br02} /> 의료 영상을 통한 사전 교육
                <br className={classes.br01} /> 으로 설명 시간을
                <br className={classes.br02} /> 획기적으로 줄이고
                <br className={classes.br02} /> 더욱 효율적인
                <br className={classes.br01} /> 진료 문화를 만들 수 있습니다.
                <br className={classes.br01} /> <br className={classes.br02} />
                벤치에서 관련 컨설팅을 지금 바로 받아보세요
              </span>
            </Flex>
            <UnstyledButton
              onClick={() => navigate('/contact')}
              className={classes.button}
            >
              <span>문의하기</span>
            </UnstyledButton>
          </Flex>
        </BackgroundImage>
        <Space mb={rem(50)} />
      </MobileView>
    </Layout>
  );
};

const useStyles = createStyles(() => ({
  mainContents01: {
    paddingInline: rem(50),
    height: rem(600),
    color: '#2C93AD',
    ':after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(234, 244, 247, 0.5)',
      zIndex: -1,
    },
  },
  serviceText: {
    fontSize: rem(36),
    fontWeight: 700,
    marginTop: rem(35),
  },
  serviceSubText: {
    marginTop: rem(20),
    fontSize: rem(18),
    fontWeight: 500,
  },
  m_mainContents1: {
    height: rem(430),
    paddingInline: rem(30),
    paddingBlock: rem(30),
  },
  sectionBackground: {
    ':before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      borderRadius: rem(16),
    },
  },
  m_sectionBackground: {
    ':before': {
      borderRadius: rem(0),
    },
  },
  m_mainBackgroundPosition: {
    backgroundSize: 'cover',
    backgroundPosition: '65% 0%',
    backgroundRepeat: 'no-repeat',
  },
  sectionBox: {
    paddingBlock: rem(100),
    paddingInline: rem(30),
    boxSizing: 'border-box',
    zIndex: 1,
  },
  m_sectionBox: {
    paddingInline: rem(25),
    zIndex: 1,
  },
  aiTitle: {
    fontSize: rem(32),
    fontWeight: 600,
    color: 'white',
    wordBreak: 'keep-all',
  },
  m_aiTitle: {
    fontSize: rem(24),
    lineHeight: rem(28),
  },
  aiText: {
    color: 'white',
    fontSize: rem(18),
    fontWeight: 500,
    lineHeight: rem(25),
    wordBreak: 'keep-all',
  },
  m_aiText: {
    fontSize: rem(16),
  },
  button: {
    maxWidth: rem(400),
    minWidth: rem(330),
    height: rem(52),
    border: `${rem(1)} solid #2A93AD`,
    borderRadius: rem(16),
    backgroundColor: 'white',
    color: '#2C93AD',
    textAlign: 'center',
    paddingBlock: rem(14),
    fontSize: rem(18),
    fontWeight: 500,
    // lineHeight: rem(18),

    ':hover': {
      backgroundColor: '#2D93AD',
      color: 'white',
    },
  },
  introduceBox: {
    maxWidth: rem(1600),
    paddingInline: rem(30),
    marginInline: 'auto',
  },
  m_introduceBox: {
    paddingInline: rem(30),
    marginInline: 'auto',
  },
  companyOverviewTitle: {
    display: 'block',
    paddingBlock: rem(50),
    fontSize: rem(48),
    fontWeight: 800,
    textAlign: 'center',
  },
  m_companyOverviewTitle: {
    fontSize: rem(36),
    paddingBottom: rem(20),
  },
  br01: {
    display: 'block',
    [`@media (max-width: ${rem(450)})`]: {
      display: 'none',
    },
  },
  br02: {
    display: 'none',
    [`@media (max-width: ${rem(450)})`]: {
      display: 'block',
    },
  },
  logoImage: {
    minWidth: rem(370),
    minHeight: rem(164),
  },
}));

export default Service;
