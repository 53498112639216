/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedSubscription<InputType, OutputType> = string & {
  __generatedSubscriptionInput: InputType;
  __generatedSubscriptionOutput: OutputType;
};

export const onCreateTestModel = /* GraphQL */ `subscription OnCreateTestModel($filter: ModelSubscriptionTestModelFilterInput) {
  onCreateTestModel(filter: $filter) {
    id
    rank
    name_kor
    name_eng
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateTestModelSubscriptionVariables,
  APITypes.OnCreateTestModelSubscription
>;
export const onUpdateTestModel = /* GraphQL */ `subscription OnUpdateTestModel($filter: ModelSubscriptionTestModelFilterInput) {
  onUpdateTestModel(filter: $filter) {
    id
    rank
    name_kor
    name_eng
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateTestModelSubscriptionVariables,
  APITypes.OnUpdateTestModelSubscription
>;
export const onDeleteTestModel = /* GraphQL */ `subscription OnDeleteTestModel($filter: ModelSubscriptionTestModelFilterInput) {
  onDeleteTestModel(filter: $filter) {
    id
    rank
    name_kor
    name_eng
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteTestModelSubscriptionVariables,
  APITypes.OnDeleteTestModelSubscription
>;
export const onCreateNoticeModel = /* GraphQL */ `subscription OnCreateNoticeModel(
  $filter: ModelSubscriptionNoticeModelFilterInput
) {
  onCreateNoticeModel(filter: $filter) {
    id
    title
    content
    isDisplayed
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateNoticeModelSubscriptionVariables,
  APITypes.OnCreateNoticeModelSubscription
>;
export const onUpdateNoticeModel = /* GraphQL */ `subscription OnUpdateNoticeModel(
  $filter: ModelSubscriptionNoticeModelFilterInput
) {
  onUpdateNoticeModel(filter: $filter) {
    id
    title
    content
    isDisplayed
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateNoticeModelSubscriptionVariables,
  APITypes.OnUpdateNoticeModelSubscription
>;
export const onDeleteNoticeModel = /* GraphQL */ `subscription OnDeleteNoticeModel(
  $filter: ModelSubscriptionNoticeModelFilterInput
) {
  onDeleteNoticeModel(filter: $filter) {
    id
    title
    content
    isDisplayed
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteNoticeModelSubscriptionVariables,
  APITypes.OnDeleteNoticeModelSubscription
>;
export const onCreateDepartmentModel = /* GraphQL */ `subscription OnCreateDepartmentModel(
  $filter: ModelSubscriptionDepartmentModelFilterInput
) {
  onCreateDepartmentModel(filter: $filter) {
    id
    hospital
    dept
    PartnerModels {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateDepartmentModelSubscriptionVariables,
  APITypes.OnCreateDepartmentModelSubscription
>;
export const onUpdateDepartmentModel = /* GraphQL */ `subscription OnUpdateDepartmentModel(
  $filter: ModelSubscriptionDepartmentModelFilterInput
) {
  onUpdateDepartmentModel(filter: $filter) {
    id
    hospital
    dept
    PartnerModels {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateDepartmentModelSubscriptionVariables,
  APITypes.OnUpdateDepartmentModelSubscription
>;
export const onDeleteDepartmentModel = /* GraphQL */ `subscription OnDeleteDepartmentModel(
  $filter: ModelSubscriptionDepartmentModelFilterInput
) {
  onDeleteDepartmentModel(filter: $filter) {
    id
    hospital
    dept
    PartnerModels {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteDepartmentModelSubscriptionVariables,
  APITypes.OnDeleteDepartmentModelSubscription
>;
export const onCreatePartnerModel = /* GraphQL */ `subscription OnCreatePartnerModel(
  $filter: ModelSubscriptionPartnerModelFilterInput
) {
  onCreatePartnerModel(filter: $filter) {
    id
    username
    name
    point
    departmentmodelID
    VideoModels {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreatePartnerModelSubscriptionVariables,
  APITypes.OnCreatePartnerModelSubscription
>;
export const onUpdatePartnerModel = /* GraphQL */ `subscription OnUpdatePartnerModel(
  $filter: ModelSubscriptionPartnerModelFilterInput
) {
  onUpdatePartnerModel(filter: $filter) {
    id
    username
    name
    point
    departmentmodelID
    VideoModels {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdatePartnerModelSubscriptionVariables,
  APITypes.OnUpdatePartnerModelSubscription
>;
export const onDeletePartnerModel = /* GraphQL */ `subscription OnDeletePartnerModel(
  $filter: ModelSubscriptionPartnerModelFilterInput
) {
  onDeletePartnerModel(filter: $filter) {
    id
    username
    name
    point
    departmentmodelID
    VideoModels {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeletePartnerModelSubscriptionVariables,
  APITypes.OnDeletePartnerModelSubscription
>;
export const onCreateQuizModel = /* GraphQL */ `subscription OnCreateQuizModel($filter: ModelSubscriptionQuizModelFilterInput) {
  onCreateQuizModel(filter: $filter) {
    id
    question
    answer
    seq
    videomodelID
    source_name
    source_url
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateQuizModelSubscriptionVariables,
  APITypes.OnCreateQuizModelSubscription
>;
export const onUpdateQuizModel = /* GraphQL */ `subscription OnUpdateQuizModel($filter: ModelSubscriptionQuizModelFilterInput) {
  onUpdateQuizModel(filter: $filter) {
    id
    question
    answer
    seq
    videomodelID
    source_name
    source_url
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateQuizModelSubscriptionVariables,
  APITypes.OnUpdateQuizModelSubscription
>;
export const onDeleteQuizModel = /* GraphQL */ `subscription OnDeleteQuizModel($filter: ModelSubscriptionQuizModelFilterInput) {
  onDeleteQuizModel(filter: $filter) {
    id
    question
    answer
    seq
    videomodelID
    source_name
    source_url
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteQuizModelSubscriptionVariables,
  APITypes.OnDeleteQuizModelSubscription
>;
export const onCreateVideoModel = /* GraphQL */ `subscription OnCreateVideoModel(
  $filter: ModelSubscriptionVideoModelFilterInput
) {
  onCreateVideoModel(filter: $filter) {
    id
    title
    author
    url
    QuizModels {
      nextToken
      __typename
    }
    tag
    partnermodelID
    retitle
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateVideoModelSubscriptionVariables,
  APITypes.OnCreateVideoModelSubscription
>;
export const onUpdateVideoModel = /* GraphQL */ `subscription OnUpdateVideoModel(
  $filter: ModelSubscriptionVideoModelFilterInput
) {
  onUpdateVideoModel(filter: $filter) {
    id
    title
    author
    url
    QuizModels {
      nextToken
      __typename
    }
    tag
    partnermodelID
    retitle
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateVideoModelSubscriptionVariables,
  APITypes.OnUpdateVideoModelSubscription
>;
export const onDeleteVideoModel = /* GraphQL */ `subscription OnDeleteVideoModel(
  $filter: ModelSubscriptionVideoModelFilterInput
) {
  onDeleteVideoModel(filter: $filter) {
    id
    title
    author
    url
    QuizModels {
      nextToken
      __typename
    }
    tag
    partnermodelID
    retitle
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteVideoModelSubscriptionVariables,
  APITypes.OnDeleteVideoModelSubscription
>;
export const onCreatePatientModel = /* GraphQL */ `subscription OnCreatePatientModel(
  $filter: ModelSubscriptionPatientModelFilterInput
) {
  onCreatePatientModel(filter: $filter) {
    id
    username
    name
    birthdate
    gender
    partner_ids
    medication_ids
    pointed_video_ids
    watched_video_ids
    diagnosis_ids
    visited_history
    picture
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreatePatientModelSubscriptionVariables,
  APITypes.OnCreatePatientModelSubscription
>;
export const onUpdatePatientModel = /* GraphQL */ `subscription OnUpdatePatientModel(
  $filter: ModelSubscriptionPatientModelFilterInput
) {
  onUpdatePatientModel(filter: $filter) {
    id
    username
    name
    birthdate
    gender
    partner_ids
    medication_ids
    pointed_video_ids
    watched_video_ids
    diagnosis_ids
    visited_history
    picture
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdatePatientModelSubscriptionVariables,
  APITypes.OnUpdatePatientModelSubscription
>;
export const onDeletePatientModel = /* GraphQL */ `subscription OnDeletePatientModel(
  $filter: ModelSubscriptionPatientModelFilterInput
) {
  onDeletePatientModel(filter: $filter) {
    id
    username
    name
    birthdate
    gender
    partner_ids
    medication_ids
    pointed_video_ids
    watched_video_ids
    diagnosis_ids
    visited_history
    picture
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeletePatientModelSubscriptionVariables,
  APITypes.OnDeletePatientModelSubscription
>;
export const onCreateMedicationModel = /* GraphQL */ `subscription OnCreateMedicationModel(
  $filter: ModelSubscriptionMedicationModelFilterInput
) {
  onCreateMedicationModel(filter: $filter) {
    id
    rank
    name_eng
    name_kor
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateMedicationModelSubscriptionVariables,
  APITypes.OnCreateMedicationModelSubscription
>;
export const onUpdateMedicationModel = /* GraphQL */ `subscription OnUpdateMedicationModel(
  $filter: ModelSubscriptionMedicationModelFilterInput
) {
  onUpdateMedicationModel(filter: $filter) {
    id
    rank
    name_eng
    name_kor
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateMedicationModelSubscriptionVariables,
  APITypes.OnUpdateMedicationModelSubscription
>;
export const onDeleteMedicationModel = /* GraphQL */ `subscription OnDeleteMedicationModel(
  $filter: ModelSubscriptionMedicationModelFilterInput
) {
  onDeleteMedicationModel(filter: $filter) {
    id
    rank
    name_eng
    name_kor
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteMedicationModelSubscriptionVariables,
  APITypes.OnDeleteMedicationModelSubscription
>;
export const onCreateDiagnosisModel = /* GraphQL */ `subscription OnCreateDiagnosisModel(
  $filter: ModelSubscriptionDiagnosisModelFilterInput
) {
  onCreateDiagnosisModel(filter: $filter) {
    id
    rank
    code
    name_eng
    name_kor
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateDiagnosisModelSubscriptionVariables,
  APITypes.OnCreateDiagnosisModelSubscription
>;
export const onUpdateDiagnosisModel = /* GraphQL */ `subscription OnUpdateDiagnosisModel(
  $filter: ModelSubscriptionDiagnosisModelFilterInput
) {
  onUpdateDiagnosisModel(filter: $filter) {
    id
    rank
    code
    name_eng
    name_kor
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateDiagnosisModelSubscriptionVariables,
  APITypes.OnUpdateDiagnosisModelSubscription
>;
export const onDeleteDiagnosisModel = /* GraphQL */ `subscription OnDeleteDiagnosisModel(
  $filter: ModelSubscriptionDiagnosisModelFilterInput
) {
  onDeleteDiagnosisModel(filter: $filter) {
    id
    rank
    code
    name_eng
    name_kor
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteDiagnosisModelSubscriptionVariables,
  APITypes.OnDeleteDiagnosisModelSubscription
>;
