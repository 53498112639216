/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateTestModelInput = {
  id?: string | null,
  rank: number,
  name_kor: string,
  name_eng: string,
};

export type ModelTestModelConditionInput = {
  rank?: ModelIntInput | null,
  name_kor?: ModelStringInput | null,
  name_eng?: ModelStringInput | null,
  and?: Array< ModelTestModelConditionInput | null > | null,
  or?: Array< ModelTestModelConditionInput | null > | null,
  not?: ModelTestModelConditionInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type TestModel = {
  __typename: "TestModel",
  id: string,
  rank: number,
  name_kor: string,
  name_eng: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateTestModelInput = {
  id: string,
  rank?: number | null,
  name_kor?: string | null,
  name_eng?: string | null,
};

export type DeleteTestModelInput = {
  id: string,
};

export type CreateNoticeModelInput = {
  id?: string | null,
  title: string,
  content: string,
  isDisplayed: boolean,
};

export type ModelNoticeModelConditionInput = {
  title?: ModelStringInput | null,
  content?: ModelStringInput | null,
  isDisplayed?: ModelBooleanInput | null,
  and?: Array< ModelNoticeModelConditionInput | null > | null,
  or?: Array< ModelNoticeModelConditionInput | null > | null,
  not?: ModelNoticeModelConditionInput | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type NoticeModel = {
  __typename: "NoticeModel",
  id: string,
  title: string,
  content: string,
  isDisplayed: boolean,
  createdAt: string,
  updatedAt: string,
};

export type UpdateNoticeModelInput = {
  id: string,
  title?: string | null,
  content?: string | null,
  isDisplayed?: boolean | null,
};

export type DeleteNoticeModelInput = {
  id: string,
};

export type CreateDepartmentModelInput = {
  id?: string | null,
  hospital: string,
  dept: string,
};

export type ModelDepartmentModelConditionInput = {
  hospital?: ModelStringInput | null,
  dept?: ModelStringInput | null,
  and?: Array< ModelDepartmentModelConditionInput | null > | null,
  or?: Array< ModelDepartmentModelConditionInput | null > | null,
  not?: ModelDepartmentModelConditionInput | null,
};

export type DepartmentModel = {
  __typename: "DepartmentModel",
  id: string,
  hospital: string,
  dept: string,
  PartnerModels?: ModelPartnerModelConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelPartnerModelConnection = {
  __typename: "ModelPartnerModelConnection",
  items:  Array<PartnerModel | null >,
  nextToken?: string | null,
};

export type PartnerModel = {
  __typename: "PartnerModel",
  id: string,
  username: string,
  name: string,
  point: number,
  departmentmodelID: string,
  VideoModels?: ModelVideoModelConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelVideoModelConnection = {
  __typename: "ModelVideoModelConnection",
  items:  Array<VideoModel | null >,
  nextToken?: string | null,
};

export type VideoModel = {
  __typename: "VideoModel",
  id: string,
  title: string,
  author: string,
  url: string,
  QuizModels?: ModelQuizModelConnection | null,
  tag?: string | null,
  partnermodelID?: string | null,
  retitle?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelQuizModelConnection = {
  __typename: "ModelQuizModelConnection",
  items:  Array<QuizModel | null >,
  nextToken?: string | null,
};

export type QuizModel = {
  __typename: "QuizModel",
  id: string,
  question: string,
  answer: string,
  seq: number,
  videomodelID: string,
  source_name?: string | null,
  source_url?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateDepartmentModelInput = {
  id: string,
  hospital?: string | null,
  dept?: string | null,
};

export type DeleteDepartmentModelInput = {
  id: string,
};

export type CreatePartnerModelInput = {
  id?: string | null,
  username: string,
  name: string,
  point: number,
  departmentmodelID: string,
};

export type ModelPartnerModelConditionInput = {
  username?: ModelStringInput | null,
  name?: ModelStringInput | null,
  point?: ModelIntInput | null,
  departmentmodelID?: ModelIDInput | null,
  and?: Array< ModelPartnerModelConditionInput | null > | null,
  or?: Array< ModelPartnerModelConditionInput | null > | null,
  not?: ModelPartnerModelConditionInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type UpdatePartnerModelInput = {
  id: string,
  username?: string | null,
  name?: string | null,
  point?: number | null,
  departmentmodelID?: string | null,
};

export type DeletePartnerModelInput = {
  id: string,
};

export type CreateQuizModelInput = {
  id?: string | null,
  question: string,
  answer: string,
  seq: number,
  videomodelID: string,
  source_name?: string | null,
  source_url?: string | null,
};

export type ModelQuizModelConditionInput = {
  question?: ModelStringInput | null,
  answer?: ModelStringInput | null,
  seq?: ModelIntInput | null,
  videomodelID?: ModelIDInput | null,
  source_name?: ModelStringInput | null,
  source_url?: ModelStringInput | null,
  and?: Array< ModelQuizModelConditionInput | null > | null,
  or?: Array< ModelQuizModelConditionInput | null > | null,
  not?: ModelQuizModelConditionInput | null,
};

export type UpdateQuizModelInput = {
  id: string,
  question?: string | null,
  answer?: string | null,
  seq?: number | null,
  videomodelID?: string | null,
  source_name?: string | null,
  source_url?: string | null,
};

export type DeleteQuizModelInput = {
  id: string,
};

export type CreateVideoModelInput = {
  id?: string | null,
  title: string,
  author: string,
  url: string,
  tag?: string | null,
  partnermodelID?: string | null,
  retitle?: string | null,
};

export type ModelVideoModelConditionInput = {
  title?: ModelStringInput | null,
  author?: ModelStringInput | null,
  url?: ModelStringInput | null,
  tag?: ModelStringInput | null,
  partnermodelID?: ModelIDInput | null,
  retitle?: ModelStringInput | null,
  and?: Array< ModelVideoModelConditionInput | null > | null,
  or?: Array< ModelVideoModelConditionInput | null > | null,
  not?: ModelVideoModelConditionInput | null,
};

export type UpdateVideoModelInput = {
  id: string,
  title?: string | null,
  author?: string | null,
  url?: string | null,
  tag?: string | null,
  partnermodelID?: string | null,
  retitle?: string | null,
};

export type DeleteVideoModelInput = {
  id: string,
};

export type CreatePatientModelInput = {
  id?: string | null,
  username: string,
  name: string,
  birthdate: string,
  gender: Gender,
  partner_ids?: string | null,
  medication_ids?: string | null,
  pointed_video_ids?: string | null,
  watched_video_ids?: string | null,
  diagnosis_ids?: string | null,
  visited_history?: string | null,
  picture?: string | null,
};

export enum Gender {
  M = "M",
  F = "F",
}


export type ModelPatientModelConditionInput = {
  username?: ModelStringInput | null,
  name?: ModelStringInput | null,
  birthdate?: ModelStringInput | null,
  gender?: ModelGenderInput | null,
  partner_ids?: ModelStringInput | null,
  medication_ids?: ModelStringInput | null,
  pointed_video_ids?: ModelStringInput | null,
  watched_video_ids?: ModelStringInput | null,
  diagnosis_ids?: ModelStringInput | null,
  visited_history?: ModelStringInput | null,
  picture?: ModelStringInput | null,
  and?: Array< ModelPatientModelConditionInput | null > | null,
  or?: Array< ModelPatientModelConditionInput | null > | null,
  not?: ModelPatientModelConditionInput | null,
};

export type ModelGenderInput = {
  eq?: Gender | null,
  ne?: Gender | null,
};

export type PatientModel = {
  __typename: "PatientModel",
  id: string,
  username: string,
  name: string,
  birthdate: string,
  gender: Gender,
  partner_ids?: string | null,
  medication_ids?: string | null,
  pointed_video_ids?: string | null,
  watched_video_ids?: string | null,
  diagnosis_ids?: string | null,
  visited_history?: string | null,
  picture?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdatePatientModelInput = {
  id: string,
  username?: string | null,
  name?: string | null,
  birthdate?: string | null,
  gender?: Gender | null,
  partner_ids?: string | null,
  medication_ids?: string | null,
  pointed_video_ids?: string | null,
  watched_video_ids?: string | null,
  diagnosis_ids?: string | null,
  visited_history?: string | null,
  picture?: string | null,
};

export type DeletePatientModelInput = {
  id: string,
};

export type CreateMedicationModelInput = {
  id?: string | null,
  rank: number,
  name_eng: string,
  name_kor: string,
};

export type ModelMedicationModelConditionInput = {
  rank?: ModelIntInput | null,
  name_eng?: ModelStringInput | null,
  name_kor?: ModelStringInput | null,
  and?: Array< ModelMedicationModelConditionInput | null > | null,
  or?: Array< ModelMedicationModelConditionInput | null > | null,
  not?: ModelMedicationModelConditionInput | null,
};

export type MedicationModel = {
  __typename: "MedicationModel",
  id: string,
  rank: number,
  name_eng: string,
  name_kor: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateMedicationModelInput = {
  id: string,
  rank?: number | null,
  name_eng?: string | null,
  name_kor?: string | null,
};

export type DeleteMedicationModelInput = {
  id: string,
};

export type CreateDiagnosisModelInput = {
  id?: string | null,
  rank: number,
  code: string,
  name_eng: string,
  name_kor: string,
};

export type ModelDiagnosisModelConditionInput = {
  rank?: ModelIntInput | null,
  code?: ModelStringInput | null,
  name_eng?: ModelStringInput | null,
  name_kor?: ModelStringInput | null,
  and?: Array< ModelDiagnosisModelConditionInput | null > | null,
  or?: Array< ModelDiagnosisModelConditionInput | null > | null,
  not?: ModelDiagnosisModelConditionInput | null,
};

export type DiagnosisModel = {
  __typename: "DiagnosisModel",
  id: string,
  rank: number,
  code: string,
  name_eng: string,
  name_kor: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateDiagnosisModelInput = {
  id: string,
  rank?: number | null,
  code?: string | null,
  name_eng?: string | null,
  name_kor?: string | null,
};

export type DeleteDiagnosisModelInput = {
  id: string,
};

export type ModelTestModelFilterInput = {
  id?: ModelIDInput | null,
  rank?: ModelIntInput | null,
  name_kor?: ModelStringInput | null,
  name_eng?: ModelStringInput | null,
  and?: Array< ModelTestModelFilterInput | null > | null,
  or?: Array< ModelTestModelFilterInput | null > | null,
  not?: ModelTestModelFilterInput | null,
};

export type ModelTestModelConnection = {
  __typename: "ModelTestModelConnection",
  items:  Array<TestModel | null >,
  nextToken?: string | null,
};

export type SearchableTestModelFilterInput = {
  id?: SearchableIDFilterInput | null,
  rank?: SearchableIntFilterInput | null,
  name_kor?: SearchableStringFilterInput | null,
  name_eng?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  and?: Array< SearchableTestModelFilterInput | null > | null,
  or?: Array< SearchableTestModelFilterInput | null > | null,
  not?: SearchableTestModelFilterInput | null,
};

export type SearchableIDFilterInput = {
  ne?: string | null,
  gt?: string | null,
  lt?: string | null,
  gte?: string | null,
  lte?: string | null,
  eq?: string | null,
  match?: string | null,
  matchPhrase?: string | null,
  matchPhrasePrefix?: string | null,
  multiMatch?: string | null,
  exists?: boolean | null,
  wildcard?: string | null,
  regexp?: string | null,
  range?: Array< string | null > | null,
};

export type SearchableIntFilterInput = {
  ne?: number | null,
  gt?: number | null,
  lt?: number | null,
  gte?: number | null,
  lte?: number | null,
  eq?: number | null,
  range?: Array< number | null > | null,
};

export type SearchableStringFilterInput = {
  ne?: string | null,
  gt?: string | null,
  lt?: string | null,
  gte?: string | null,
  lte?: string | null,
  eq?: string | null,
  match?: string | null,
  matchPhrase?: string | null,
  matchPhrasePrefix?: string | null,
  multiMatch?: string | null,
  exists?: boolean | null,
  wildcard?: string | null,
  regexp?: string | null,
  range?: Array< string | null > | null,
};

export type SearchableTestModelSortInput = {
  field?: SearchableTestModelSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableTestModelSortableFields {
  id = "id",
  rank = "rank",
  name_kor = "name_kor",
  name_eng = "name_eng",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export enum SearchableSortDirection {
  asc = "asc",
  desc = "desc",
}


export type SearchableTestModelAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchableTestModelAggregateField,
};

export enum SearchableAggregateType {
  terms = "terms",
  avg = "avg",
  min = "min",
  max = "max",
  sum = "sum",
}


export enum SearchableTestModelAggregateField {
  id = "id",
  rank = "rank",
  name_kor = "name_kor",
  name_eng = "name_eng",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableTestModelConnection = {
  __typename: "SearchableTestModelConnection",
  items:  Array<TestModel | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type SearchableAggregateResult = {
  __typename: "SearchableAggregateResult",
  name: string,
  result?: SearchableAggregateGenericResult | null,
};

export type SearchableAggregateGenericResult = SearchableAggregateScalarResult | SearchableAggregateBucketResult


export type SearchableAggregateScalarResult = {
  __typename: "SearchableAggregateScalarResult",
  value: number,
};

export type SearchableAggregateBucketResult = {
  __typename: "SearchableAggregateBucketResult",
  buckets?:  Array<SearchableAggregateBucketResultItem | null > | null,
};

export type SearchableAggregateBucketResultItem = {
  __typename: "SearchableAggregateBucketResultItem",
  key: string,
  doc_count: number,
};

export type ModelNoticeModelFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  content?: ModelStringInput | null,
  isDisplayed?: ModelBooleanInput | null,
  and?: Array< ModelNoticeModelFilterInput | null > | null,
  or?: Array< ModelNoticeModelFilterInput | null > | null,
  not?: ModelNoticeModelFilterInput | null,
};

export type ModelNoticeModelConnection = {
  __typename: "ModelNoticeModelConnection",
  items:  Array<NoticeModel | null >,
  nextToken?: string | null,
};

export type SearchableNoticeModelFilterInput = {
  id?: SearchableIDFilterInput | null,
  title?: SearchableStringFilterInput | null,
  content?: SearchableStringFilterInput | null,
  isDisplayed?: SearchableBooleanFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  and?: Array< SearchableNoticeModelFilterInput | null > | null,
  or?: Array< SearchableNoticeModelFilterInput | null > | null,
  not?: SearchableNoticeModelFilterInput | null,
};

export type SearchableBooleanFilterInput = {
  eq?: boolean | null,
  ne?: boolean | null,
};

export type SearchableNoticeModelSortInput = {
  field?: SearchableNoticeModelSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableNoticeModelSortableFields {
  id = "id",
  title = "title",
  content = "content",
  isDisplayed = "isDisplayed",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableNoticeModelAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchableNoticeModelAggregateField,
};

export enum SearchableNoticeModelAggregateField {
  id = "id",
  title = "title",
  content = "content",
  isDisplayed = "isDisplayed",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableNoticeModelConnection = {
  __typename: "SearchableNoticeModelConnection",
  items:  Array<NoticeModel | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type ModelDepartmentModelFilterInput = {
  id?: ModelIDInput | null,
  hospital?: ModelStringInput | null,
  dept?: ModelStringInput | null,
  and?: Array< ModelDepartmentModelFilterInput | null > | null,
  or?: Array< ModelDepartmentModelFilterInput | null > | null,
  not?: ModelDepartmentModelFilterInput | null,
};

export type ModelDepartmentModelConnection = {
  __typename: "ModelDepartmentModelConnection",
  items:  Array<DepartmentModel | null >,
  nextToken?: string | null,
};

export type SearchableDepartmentModelFilterInput = {
  id?: SearchableIDFilterInput | null,
  hospital?: SearchableStringFilterInput | null,
  dept?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  and?: Array< SearchableDepartmentModelFilterInput | null > | null,
  or?: Array< SearchableDepartmentModelFilterInput | null > | null,
  not?: SearchableDepartmentModelFilterInput | null,
};

export type SearchableDepartmentModelSortInput = {
  field?: SearchableDepartmentModelSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableDepartmentModelSortableFields {
  id = "id",
  hospital = "hospital",
  dept = "dept",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableDepartmentModelAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchableDepartmentModelAggregateField,
};

export enum SearchableDepartmentModelAggregateField {
  id = "id",
  hospital = "hospital",
  dept = "dept",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableDepartmentModelConnection = {
  __typename: "SearchableDepartmentModelConnection",
  items:  Array<DepartmentModel | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type ModelPartnerModelFilterInput = {
  id?: ModelIDInput | null,
  username?: ModelStringInput | null,
  name?: ModelStringInput | null,
  point?: ModelIntInput | null,
  departmentmodelID?: ModelIDInput | null,
  and?: Array< ModelPartnerModelFilterInput | null > | null,
  or?: Array< ModelPartnerModelFilterInput | null > | null,
  not?: ModelPartnerModelFilterInput | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type SearchablePartnerModelFilterInput = {
  id?: SearchableIDFilterInput | null,
  username?: SearchableStringFilterInput | null,
  name?: SearchableStringFilterInput | null,
  point?: SearchableIntFilterInput | null,
  departmentmodelID?: SearchableIDFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  and?: Array< SearchablePartnerModelFilterInput | null > | null,
  or?: Array< SearchablePartnerModelFilterInput | null > | null,
  not?: SearchablePartnerModelFilterInput | null,
};

export type SearchablePartnerModelSortInput = {
  field?: SearchablePartnerModelSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchablePartnerModelSortableFields {
  id = "id",
  username = "username",
  name = "name",
  point = "point",
  departmentmodelID = "departmentmodelID",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchablePartnerModelAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchablePartnerModelAggregateField,
};

export enum SearchablePartnerModelAggregateField {
  id = "id",
  username = "username",
  name = "name",
  point = "point",
  departmentmodelID = "departmentmodelID",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchablePartnerModelConnection = {
  __typename: "SearchablePartnerModelConnection",
  items:  Array<PartnerModel | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type ModelQuizModelFilterInput = {
  id?: ModelIDInput | null,
  question?: ModelStringInput | null,
  answer?: ModelStringInput | null,
  seq?: ModelIntInput | null,
  videomodelID?: ModelIDInput | null,
  source_name?: ModelStringInput | null,
  source_url?: ModelStringInput | null,
  and?: Array< ModelQuizModelFilterInput | null > | null,
  or?: Array< ModelQuizModelFilterInput | null > | null,
  not?: ModelQuizModelFilterInput | null,
};

export type SearchableQuizModelFilterInput = {
  id?: SearchableIDFilterInput | null,
  question?: SearchableStringFilterInput | null,
  answer?: SearchableStringFilterInput | null,
  seq?: SearchableIntFilterInput | null,
  videomodelID?: SearchableIDFilterInput | null,
  source_name?: SearchableStringFilterInput | null,
  source_url?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  and?: Array< SearchableQuizModelFilterInput | null > | null,
  or?: Array< SearchableQuizModelFilterInput | null > | null,
  not?: SearchableQuizModelFilterInput | null,
};

export type SearchableQuizModelSortInput = {
  field?: SearchableQuizModelSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableQuizModelSortableFields {
  id = "id",
  question = "question",
  answer = "answer",
  seq = "seq",
  videomodelID = "videomodelID",
  source_name = "source_name",
  source_url = "source_url",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableQuizModelAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchableQuizModelAggregateField,
};

export enum SearchableQuizModelAggregateField {
  id = "id",
  question = "question",
  answer = "answer",
  seq = "seq",
  videomodelID = "videomodelID",
  source_name = "source_name",
  source_url = "source_url",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableQuizModelConnection = {
  __typename: "SearchableQuizModelConnection",
  items:  Array<QuizModel | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type ModelVideoModelFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  author?: ModelStringInput | null,
  url?: ModelStringInput | null,
  tag?: ModelStringInput | null,
  partnermodelID?: ModelIDInput | null,
  retitle?: ModelStringInput | null,
  and?: Array< ModelVideoModelFilterInput | null > | null,
  or?: Array< ModelVideoModelFilterInput | null > | null,
  not?: ModelVideoModelFilterInput | null,
};

export type SearchableVideoModelFilterInput = {
  id?: SearchableIDFilterInput | null,
  title?: SearchableStringFilterInput | null,
  author?: SearchableStringFilterInput | null,
  url?: SearchableStringFilterInput | null,
  tag?: SearchableStringFilterInput | null,
  partnermodelID?: SearchableIDFilterInput | null,
  retitle?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  and?: Array< SearchableVideoModelFilterInput | null > | null,
  or?: Array< SearchableVideoModelFilterInput | null > | null,
  not?: SearchableVideoModelFilterInput | null,
};

export type SearchableVideoModelSortInput = {
  field?: SearchableVideoModelSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableVideoModelSortableFields {
  id = "id",
  title = "title",
  author = "author",
  url = "url",
  tag = "tag",
  partnermodelID = "partnermodelID",
  retitle = "retitle",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableVideoModelAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchableVideoModelAggregateField,
};

export enum SearchableVideoModelAggregateField {
  id = "id",
  title = "title",
  author = "author",
  url = "url",
  tag = "tag",
  partnermodelID = "partnermodelID",
  retitle = "retitle",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableVideoModelConnection = {
  __typename: "SearchableVideoModelConnection",
  items:  Array<VideoModel | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type ModelPatientModelFilterInput = {
  id?: ModelIDInput | null,
  username?: ModelStringInput | null,
  name?: ModelStringInput | null,
  birthdate?: ModelStringInput | null,
  gender?: ModelGenderInput | null,
  partner_ids?: ModelStringInput | null,
  medication_ids?: ModelStringInput | null,
  pointed_video_ids?: ModelStringInput | null,
  watched_video_ids?: ModelStringInput | null,
  diagnosis_ids?: ModelStringInput | null,
  visited_history?: ModelStringInput | null,
  picture?: ModelStringInput | null,
  and?: Array< ModelPatientModelFilterInput | null > | null,
  or?: Array< ModelPatientModelFilterInput | null > | null,
  not?: ModelPatientModelFilterInput | null,
};

export type ModelPatientModelConnection = {
  __typename: "ModelPatientModelConnection",
  items:  Array<PatientModel | null >,
  nextToken?: string | null,
};

export type SearchablePatientModelFilterInput = {
  id?: SearchableIDFilterInput | null,
  username?: SearchableStringFilterInput | null,
  name?: SearchableStringFilterInput | null,
  birthdate?: SearchableStringFilterInput | null,
  partner_ids?: SearchableStringFilterInput | null,
  medication_ids?: SearchableStringFilterInput | null,
  pointed_video_ids?: SearchableStringFilterInput | null,
  watched_video_ids?: SearchableStringFilterInput | null,
  diagnosis_ids?: SearchableStringFilterInput | null,
  visited_history?: SearchableStringFilterInput | null,
  picture?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  gender?: SearchableStringFilterInput | null,
  and?: Array< SearchablePatientModelFilterInput | null > | null,
  or?: Array< SearchablePatientModelFilterInput | null > | null,
  not?: SearchablePatientModelFilterInput | null,
};

export type SearchablePatientModelSortInput = {
  field?: SearchablePatientModelSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchablePatientModelSortableFields {
  id = "id",
  username = "username",
  name = "name",
  birthdate = "birthdate",
  partner_ids = "partner_ids",
  medication_ids = "medication_ids",
  pointed_video_ids = "pointed_video_ids",
  watched_video_ids = "watched_video_ids",
  diagnosis_ids = "diagnosis_ids",
  visited_history = "visited_history",
  picture = "picture",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchablePatientModelAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchablePatientModelAggregateField,
};

export enum SearchablePatientModelAggregateField {
  id = "id",
  username = "username",
  name = "name",
  birthdate = "birthdate",
  gender = "gender",
  partner_ids = "partner_ids",
  medication_ids = "medication_ids",
  pointed_video_ids = "pointed_video_ids",
  watched_video_ids = "watched_video_ids",
  diagnosis_ids = "diagnosis_ids",
  visited_history = "visited_history",
  picture = "picture",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchablePatientModelConnection = {
  __typename: "SearchablePatientModelConnection",
  items:  Array<PatientModel | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type ModelMedicationModelFilterInput = {
  id?: ModelIDInput | null,
  rank?: ModelIntInput | null,
  name_eng?: ModelStringInput | null,
  name_kor?: ModelStringInput | null,
  and?: Array< ModelMedicationModelFilterInput | null > | null,
  or?: Array< ModelMedicationModelFilterInput | null > | null,
  not?: ModelMedicationModelFilterInput | null,
};

export type ModelMedicationModelConnection = {
  __typename: "ModelMedicationModelConnection",
  items:  Array<MedicationModel | null >,
  nextToken?: string | null,
};

export type SearchableMedicationModelFilterInput = {
  id?: SearchableIDFilterInput | null,
  rank?: SearchableIntFilterInput | null,
  name_eng?: SearchableStringFilterInput | null,
  name_kor?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  and?: Array< SearchableMedicationModelFilterInput | null > | null,
  or?: Array< SearchableMedicationModelFilterInput | null > | null,
  not?: SearchableMedicationModelFilterInput | null,
};

export type SearchableMedicationModelSortInput = {
  field?: SearchableMedicationModelSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableMedicationModelSortableFields {
  id = "id",
  rank = "rank",
  name_eng = "name_eng",
  name_kor = "name_kor",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableMedicationModelAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchableMedicationModelAggregateField,
};

export enum SearchableMedicationModelAggregateField {
  id = "id",
  rank = "rank",
  name_eng = "name_eng",
  name_kor = "name_kor",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableMedicationModelConnection = {
  __typename: "SearchableMedicationModelConnection",
  items:  Array<MedicationModel | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type ModelDiagnosisModelFilterInput = {
  id?: ModelIDInput | null,
  rank?: ModelIntInput | null,
  code?: ModelStringInput | null,
  name_eng?: ModelStringInput | null,
  name_kor?: ModelStringInput | null,
  and?: Array< ModelDiagnosisModelFilterInput | null > | null,
  or?: Array< ModelDiagnosisModelFilterInput | null > | null,
  not?: ModelDiagnosisModelFilterInput | null,
};

export type ModelDiagnosisModelConnection = {
  __typename: "ModelDiagnosisModelConnection",
  items:  Array<DiagnosisModel | null >,
  nextToken?: string | null,
};

export type SearchableDiagnosisModelFilterInput = {
  id?: SearchableIDFilterInput | null,
  rank?: SearchableIntFilterInput | null,
  code?: SearchableStringFilterInput | null,
  name_eng?: SearchableStringFilterInput | null,
  name_kor?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  and?: Array< SearchableDiagnosisModelFilterInput | null > | null,
  or?: Array< SearchableDiagnosisModelFilterInput | null > | null,
  not?: SearchableDiagnosisModelFilterInput | null,
};

export type SearchableDiagnosisModelSortInput = {
  field?: SearchableDiagnosisModelSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableDiagnosisModelSortableFields {
  id = "id",
  rank = "rank",
  code = "code",
  name_eng = "name_eng",
  name_kor = "name_kor",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableDiagnosisModelAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchableDiagnosisModelAggregateField,
};

export enum SearchableDiagnosisModelAggregateField {
  id = "id",
  rank = "rank",
  code = "code",
  name_eng = "name_eng",
  name_kor = "name_kor",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}


export type SearchableDiagnosisModelConnection = {
  __typename: "SearchableDiagnosisModelConnection",
  items:  Array<DiagnosisModel | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type ModelSubscriptionTestModelFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  rank?: ModelSubscriptionIntInput | null,
  name_kor?: ModelSubscriptionStringInput | null,
  name_eng?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionTestModelFilterInput | null > | null,
  or?: Array< ModelSubscriptionTestModelFilterInput | null > | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionNoticeModelFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  title?: ModelSubscriptionStringInput | null,
  content?: ModelSubscriptionStringInput | null,
  isDisplayed?: ModelSubscriptionBooleanInput | null,
  and?: Array< ModelSubscriptionNoticeModelFilterInput | null > | null,
  or?: Array< ModelSubscriptionNoticeModelFilterInput | null > | null,
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type ModelSubscriptionDepartmentModelFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  hospital?: ModelSubscriptionStringInput | null,
  dept?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionDepartmentModelFilterInput | null > | null,
  or?: Array< ModelSubscriptionDepartmentModelFilterInput | null > | null,
};

export type ModelSubscriptionPartnerModelFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  username?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  point?: ModelSubscriptionIntInput | null,
  departmentmodelID?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionPartnerModelFilterInput | null > | null,
  or?: Array< ModelSubscriptionPartnerModelFilterInput | null > | null,
};

export type ModelSubscriptionQuizModelFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  question?: ModelSubscriptionStringInput | null,
  answer?: ModelSubscriptionStringInput | null,
  seq?: ModelSubscriptionIntInput | null,
  videomodelID?: ModelSubscriptionIDInput | null,
  source_name?: ModelSubscriptionStringInput | null,
  source_url?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionQuizModelFilterInput | null > | null,
  or?: Array< ModelSubscriptionQuizModelFilterInput | null > | null,
};

export type ModelSubscriptionVideoModelFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  title?: ModelSubscriptionStringInput | null,
  author?: ModelSubscriptionStringInput | null,
  url?: ModelSubscriptionStringInput | null,
  tag?: ModelSubscriptionStringInput | null,
  partnermodelID?: ModelSubscriptionIDInput | null,
  retitle?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionVideoModelFilterInput | null > | null,
  or?: Array< ModelSubscriptionVideoModelFilterInput | null > | null,
};

export type ModelSubscriptionPatientModelFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  username?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  birthdate?: ModelSubscriptionStringInput | null,
  gender?: ModelSubscriptionStringInput | null,
  partner_ids?: ModelSubscriptionStringInput | null,
  medication_ids?: ModelSubscriptionStringInput | null,
  pointed_video_ids?: ModelSubscriptionStringInput | null,
  watched_video_ids?: ModelSubscriptionStringInput | null,
  diagnosis_ids?: ModelSubscriptionStringInput | null,
  visited_history?: ModelSubscriptionStringInput | null,
  picture?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionPatientModelFilterInput | null > | null,
  or?: Array< ModelSubscriptionPatientModelFilterInput | null > | null,
};

export type ModelSubscriptionMedicationModelFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  rank?: ModelSubscriptionIntInput | null,
  name_eng?: ModelSubscriptionStringInput | null,
  name_kor?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionMedicationModelFilterInput | null > | null,
  or?: Array< ModelSubscriptionMedicationModelFilterInput | null > | null,
};

export type ModelSubscriptionDiagnosisModelFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  rank?: ModelSubscriptionIntInput | null,
  code?: ModelSubscriptionStringInput | null,
  name_eng?: ModelSubscriptionStringInput | null,
  name_kor?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionDiagnosisModelFilterInput | null > | null,
  or?: Array< ModelSubscriptionDiagnosisModelFilterInput | null > | null,
};

export type CreateTestModelMutationVariables = {
  input: CreateTestModelInput,
  condition?: ModelTestModelConditionInput | null,
};

export type CreateTestModelMutation = {
  createTestModel?:  {
    __typename: "TestModel",
    id: string,
    rank: number,
    name_kor: string,
    name_eng: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateTestModelMutationVariables = {
  input: UpdateTestModelInput,
  condition?: ModelTestModelConditionInput | null,
};

export type UpdateTestModelMutation = {
  updateTestModel?:  {
    __typename: "TestModel",
    id: string,
    rank: number,
    name_kor: string,
    name_eng: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteTestModelMutationVariables = {
  input: DeleteTestModelInput,
  condition?: ModelTestModelConditionInput | null,
};

export type DeleteTestModelMutation = {
  deleteTestModel?:  {
    __typename: "TestModel",
    id: string,
    rank: number,
    name_kor: string,
    name_eng: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateNoticeModelMutationVariables = {
  input: CreateNoticeModelInput,
  condition?: ModelNoticeModelConditionInput | null,
};

export type CreateNoticeModelMutation = {
  createNoticeModel?:  {
    __typename: "NoticeModel",
    id: string,
    title: string,
    content: string,
    isDisplayed: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateNoticeModelMutationVariables = {
  input: UpdateNoticeModelInput,
  condition?: ModelNoticeModelConditionInput | null,
};

export type UpdateNoticeModelMutation = {
  updateNoticeModel?:  {
    __typename: "NoticeModel",
    id: string,
    title: string,
    content: string,
    isDisplayed: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteNoticeModelMutationVariables = {
  input: DeleteNoticeModelInput,
  condition?: ModelNoticeModelConditionInput | null,
};

export type DeleteNoticeModelMutation = {
  deleteNoticeModel?:  {
    __typename: "NoticeModel",
    id: string,
    title: string,
    content: string,
    isDisplayed: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateDepartmentModelMutationVariables = {
  input: CreateDepartmentModelInput,
  condition?: ModelDepartmentModelConditionInput | null,
};

export type CreateDepartmentModelMutation = {
  createDepartmentModel?:  {
    __typename: "DepartmentModel",
    id: string,
    hospital: string,
    dept: string,
    PartnerModels?:  {
      __typename: "ModelPartnerModelConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateDepartmentModelMutationVariables = {
  input: UpdateDepartmentModelInput,
  condition?: ModelDepartmentModelConditionInput | null,
};

export type UpdateDepartmentModelMutation = {
  updateDepartmentModel?:  {
    __typename: "DepartmentModel",
    id: string,
    hospital: string,
    dept: string,
    PartnerModels?:  {
      __typename: "ModelPartnerModelConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteDepartmentModelMutationVariables = {
  input: DeleteDepartmentModelInput,
  condition?: ModelDepartmentModelConditionInput | null,
};

export type DeleteDepartmentModelMutation = {
  deleteDepartmentModel?:  {
    __typename: "DepartmentModel",
    id: string,
    hospital: string,
    dept: string,
    PartnerModels?:  {
      __typename: "ModelPartnerModelConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreatePartnerModelMutationVariables = {
  input: CreatePartnerModelInput,
  condition?: ModelPartnerModelConditionInput | null,
};

export type CreatePartnerModelMutation = {
  createPartnerModel?:  {
    __typename: "PartnerModel",
    id: string,
    username: string,
    name: string,
    point: number,
    departmentmodelID: string,
    VideoModels?:  {
      __typename: "ModelVideoModelConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdatePartnerModelMutationVariables = {
  input: UpdatePartnerModelInput,
  condition?: ModelPartnerModelConditionInput | null,
};

export type UpdatePartnerModelMutation = {
  updatePartnerModel?:  {
    __typename: "PartnerModel",
    id: string,
    username: string,
    name: string,
    point: number,
    departmentmodelID: string,
    VideoModels?:  {
      __typename: "ModelVideoModelConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeletePartnerModelMutationVariables = {
  input: DeletePartnerModelInput,
  condition?: ModelPartnerModelConditionInput | null,
};

export type DeletePartnerModelMutation = {
  deletePartnerModel?:  {
    __typename: "PartnerModel",
    id: string,
    username: string,
    name: string,
    point: number,
    departmentmodelID: string,
    VideoModels?:  {
      __typename: "ModelVideoModelConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateQuizModelMutationVariables = {
  input: CreateQuizModelInput,
  condition?: ModelQuizModelConditionInput | null,
};

export type CreateQuizModelMutation = {
  createQuizModel?:  {
    __typename: "QuizModel",
    id: string,
    question: string,
    answer: string,
    seq: number,
    videomodelID: string,
    source_name?: string | null,
    source_url?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateQuizModelMutationVariables = {
  input: UpdateQuizModelInput,
  condition?: ModelQuizModelConditionInput | null,
};

export type UpdateQuizModelMutation = {
  updateQuizModel?:  {
    __typename: "QuizModel",
    id: string,
    question: string,
    answer: string,
    seq: number,
    videomodelID: string,
    source_name?: string | null,
    source_url?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteQuizModelMutationVariables = {
  input: DeleteQuizModelInput,
  condition?: ModelQuizModelConditionInput | null,
};

export type DeleteQuizModelMutation = {
  deleteQuizModel?:  {
    __typename: "QuizModel",
    id: string,
    question: string,
    answer: string,
    seq: number,
    videomodelID: string,
    source_name?: string | null,
    source_url?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateVideoModelMutationVariables = {
  input: CreateVideoModelInput,
  condition?: ModelVideoModelConditionInput | null,
};

export type CreateVideoModelMutation = {
  createVideoModel?:  {
    __typename: "VideoModel",
    id: string,
    title: string,
    author: string,
    url: string,
    QuizModels?:  {
      __typename: "ModelQuizModelConnection",
      nextToken?: string | null,
    } | null,
    tag?: string | null,
    partnermodelID?: string | null,
    retitle?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateVideoModelMutationVariables = {
  input: UpdateVideoModelInput,
  condition?: ModelVideoModelConditionInput | null,
};

export type UpdateVideoModelMutation = {
  updateVideoModel?:  {
    __typename: "VideoModel",
    id: string,
    title: string,
    author: string,
    url: string,
    QuizModels?:  {
      __typename: "ModelQuizModelConnection",
      nextToken?: string | null,
    } | null,
    tag?: string | null,
    partnermodelID?: string | null,
    retitle?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteVideoModelMutationVariables = {
  input: DeleteVideoModelInput,
  condition?: ModelVideoModelConditionInput | null,
};

export type DeleteVideoModelMutation = {
  deleteVideoModel?:  {
    __typename: "VideoModel",
    id: string,
    title: string,
    author: string,
    url: string,
    QuizModels?:  {
      __typename: "ModelQuizModelConnection",
      nextToken?: string | null,
    } | null,
    tag?: string | null,
    partnermodelID?: string | null,
    retitle?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreatePatientModelMutationVariables = {
  input: CreatePatientModelInput,
  condition?: ModelPatientModelConditionInput | null,
};

export type CreatePatientModelMutation = {
  createPatientModel?:  {
    __typename: "PatientModel",
    id: string,
    username: string,
    name: string,
    birthdate: string,
    gender: Gender,
    partner_ids?: string | null,
    medication_ids?: string | null,
    pointed_video_ids?: string | null,
    watched_video_ids?: string | null,
    diagnosis_ids?: string | null,
    visited_history?: string | null,
    picture?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdatePatientModelMutationVariables = {
  input: UpdatePatientModelInput,
  condition?: ModelPatientModelConditionInput | null,
};

export type UpdatePatientModelMutation = {
  updatePatientModel?:  {
    __typename: "PatientModel",
    id: string,
    username: string,
    name: string,
    birthdate: string,
    gender: Gender,
    partner_ids?: string | null,
    medication_ids?: string | null,
    pointed_video_ids?: string | null,
    watched_video_ids?: string | null,
    diagnosis_ids?: string | null,
    visited_history?: string | null,
    picture?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeletePatientModelMutationVariables = {
  input: DeletePatientModelInput,
  condition?: ModelPatientModelConditionInput | null,
};

export type DeletePatientModelMutation = {
  deletePatientModel?:  {
    __typename: "PatientModel",
    id: string,
    username: string,
    name: string,
    birthdate: string,
    gender: Gender,
    partner_ids?: string | null,
    medication_ids?: string | null,
    pointed_video_ids?: string | null,
    watched_video_ids?: string | null,
    diagnosis_ids?: string | null,
    visited_history?: string | null,
    picture?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateMedicationModelMutationVariables = {
  input: CreateMedicationModelInput,
  condition?: ModelMedicationModelConditionInput | null,
};

export type CreateMedicationModelMutation = {
  createMedicationModel?:  {
    __typename: "MedicationModel",
    id: string,
    rank: number,
    name_eng: string,
    name_kor: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateMedicationModelMutationVariables = {
  input: UpdateMedicationModelInput,
  condition?: ModelMedicationModelConditionInput | null,
};

export type UpdateMedicationModelMutation = {
  updateMedicationModel?:  {
    __typename: "MedicationModel",
    id: string,
    rank: number,
    name_eng: string,
    name_kor: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteMedicationModelMutationVariables = {
  input: DeleteMedicationModelInput,
  condition?: ModelMedicationModelConditionInput | null,
};

export type DeleteMedicationModelMutation = {
  deleteMedicationModel?:  {
    __typename: "MedicationModel",
    id: string,
    rank: number,
    name_eng: string,
    name_kor: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateDiagnosisModelMutationVariables = {
  input: CreateDiagnosisModelInput,
  condition?: ModelDiagnosisModelConditionInput | null,
};

export type CreateDiagnosisModelMutation = {
  createDiagnosisModel?:  {
    __typename: "DiagnosisModel",
    id: string,
    rank: number,
    code: string,
    name_eng: string,
    name_kor: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateDiagnosisModelMutationVariables = {
  input: UpdateDiagnosisModelInput,
  condition?: ModelDiagnosisModelConditionInput | null,
};

export type UpdateDiagnosisModelMutation = {
  updateDiagnosisModel?:  {
    __typename: "DiagnosisModel",
    id: string,
    rank: number,
    code: string,
    name_eng: string,
    name_kor: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteDiagnosisModelMutationVariables = {
  input: DeleteDiagnosisModelInput,
  condition?: ModelDiagnosisModelConditionInput | null,
};

export type DeleteDiagnosisModelMutation = {
  deleteDiagnosisModel?:  {
    __typename: "DiagnosisModel",
    id: string,
    rank: number,
    code: string,
    name_eng: string,
    name_kor: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetTestModelQueryVariables = {
  id: string,
};

export type GetTestModelQuery = {
  getTestModel?:  {
    __typename: "TestModel",
    id: string,
    rank: number,
    name_kor: string,
    name_eng: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListTestModelsQueryVariables = {
  filter?: ModelTestModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTestModelsQuery = {
  listTestModels?:  {
    __typename: "ModelTestModelConnection",
    items:  Array< {
      __typename: "TestModel",
      id: string,
      rank: number,
      name_kor: string,
      name_eng: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchTestModelsQueryVariables = {
  filter?: SearchableTestModelFilterInput | null,
  sort?: Array< SearchableTestModelSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableTestModelAggregationInput | null > | null,
};

export type SearchTestModelsQuery = {
  searchTestModels?:  {
    __typename: "SearchableTestModelConnection",
    items:  Array< {
      __typename: "TestModel",
      id: string,
      rank: number,
      name_kor: string,
      name_eng: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetNoticeModelQueryVariables = {
  id: string,
};

export type GetNoticeModelQuery = {
  getNoticeModel?:  {
    __typename: "NoticeModel",
    id: string,
    title: string,
    content: string,
    isDisplayed: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListNoticeModelsQueryVariables = {
  filter?: ModelNoticeModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListNoticeModelsQuery = {
  listNoticeModels?:  {
    __typename: "ModelNoticeModelConnection",
    items:  Array< {
      __typename: "NoticeModel",
      id: string,
      title: string,
      content: string,
      isDisplayed: boolean,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchNoticeModelsQueryVariables = {
  filter?: SearchableNoticeModelFilterInput | null,
  sort?: Array< SearchableNoticeModelSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableNoticeModelAggregationInput | null > | null,
};

export type SearchNoticeModelsQuery = {
  searchNoticeModels?:  {
    __typename: "SearchableNoticeModelConnection",
    items:  Array< {
      __typename: "NoticeModel",
      id: string,
      title: string,
      content: string,
      isDisplayed: boolean,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetDepartmentModelQueryVariables = {
  id: string,
};

export type GetDepartmentModelQuery = {
  getDepartmentModel?:  {
    __typename: "DepartmentModel",
    id: string,
    hospital: string,
    dept: string,
    PartnerModels?:  {
      __typename: "ModelPartnerModelConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListDepartmentModelsQueryVariables = {
  filter?: ModelDepartmentModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDepartmentModelsQuery = {
  listDepartmentModels?:  {
    __typename: "ModelDepartmentModelConnection",
    items:  Array< {
      __typename: "DepartmentModel",
      id: string,
      hospital: string,
      dept: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchDepartmentModelsQueryVariables = {
  filter?: SearchableDepartmentModelFilterInput | null,
  sort?: Array< SearchableDepartmentModelSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableDepartmentModelAggregationInput | null > | null,
};

export type SearchDepartmentModelsQuery = {
  searchDepartmentModels?:  {
    __typename: "SearchableDepartmentModelConnection",
    items:  Array< {
      __typename: "DepartmentModel",
      id: string,
      hospital: string,
      dept: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetPartnerModelQueryVariables = {
  id: string,
};

export type GetPartnerModelQuery = {
  getPartnerModel?:  {
    __typename: "PartnerModel",
    id: string,
    username: string,
    name: string,
    point: number,
    departmentmodelID: string,
    VideoModels?:  {
      __typename: "ModelVideoModelConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListPartnerModelsQueryVariables = {
  filter?: ModelPartnerModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPartnerModelsQuery = {
  listPartnerModels?:  {
    __typename: "ModelPartnerModelConnection",
    items:  Array< {
      __typename: "PartnerModel",
      id: string,
      username: string,
      name: string,
      point: number,
      departmentmodelID: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PartnerModelsByDepartmentmodelIDQueryVariables = {
  departmentmodelID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPartnerModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PartnerModelsByDepartmentmodelIDQuery = {
  partnerModelsByDepartmentmodelID?:  {
    __typename: "ModelPartnerModelConnection",
    items:  Array< {
      __typename: "PartnerModel",
      id: string,
      username: string,
      name: string,
      point: number,
      departmentmodelID: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchPartnerModelsQueryVariables = {
  filter?: SearchablePartnerModelFilterInput | null,
  sort?: Array< SearchablePartnerModelSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchablePartnerModelAggregationInput | null > | null,
};

export type SearchPartnerModelsQuery = {
  searchPartnerModels?:  {
    __typename: "SearchablePartnerModelConnection",
    items:  Array< {
      __typename: "PartnerModel",
      id: string,
      username: string,
      name: string,
      point: number,
      departmentmodelID: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetQuizModelQueryVariables = {
  id: string,
};

export type GetQuizModelQuery = {
  getQuizModel?:  {
    __typename: "QuizModel",
    id: string,
    question: string,
    answer: string,
    seq: number,
    videomodelID: string,
    source_name?: string | null,
    source_url?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListQuizModelsQueryVariables = {
  filter?: ModelQuizModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListQuizModelsQuery = {
  listQuizModels?:  {
    __typename: "ModelQuizModelConnection",
    items:  Array< {
      __typename: "QuizModel",
      id: string,
      question: string,
      answer: string,
      seq: number,
      videomodelID: string,
      source_name?: string | null,
      source_url?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type QuizModelsByVideomodelIDQueryVariables = {
  videomodelID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelQuizModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type QuizModelsByVideomodelIDQuery = {
  quizModelsByVideomodelID?:  {
    __typename: "ModelQuizModelConnection",
    items:  Array< {
      __typename: "QuizModel",
      id: string,
      question: string,
      answer: string,
      seq: number,
      videomodelID: string,
      source_name?: string | null,
      source_url?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchQuizModelsQueryVariables = {
  filter?: SearchableQuizModelFilterInput | null,
  sort?: Array< SearchableQuizModelSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableQuizModelAggregationInput | null > | null,
};

export type SearchQuizModelsQuery = {
  searchQuizModels?:  {
    __typename: "SearchableQuizModelConnection",
    items:  Array< {
      __typename: "QuizModel",
      id: string,
      question: string,
      answer: string,
      seq: number,
      videomodelID: string,
      source_name?: string | null,
      source_url?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetVideoModelQueryVariables = {
  id: string,
};

export type GetVideoModelQuery = {
  getVideoModel?:  {
    __typename: "VideoModel",
    id: string,
    title: string,
    author: string,
    url: string,
    QuizModels?:  {
      __typename: "ModelQuizModelConnection",
      nextToken?: string | null,
    } | null,
    tag?: string | null,
    partnermodelID?: string | null,
    retitle?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListVideoModelsQueryVariables = {
  filter?: ModelVideoModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListVideoModelsQuery = {
  listVideoModels?:  {
    __typename: "ModelVideoModelConnection",
    items:  Array< {
      __typename: "VideoModel",
      id: string,
      title: string,
      author: string,
      url: string,
      tag?: string | null,
      partnermodelID?: string | null,
      retitle?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type VideoModelsByPartnermodelIDQueryVariables = {
  partnermodelID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelVideoModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type VideoModelsByPartnermodelIDQuery = {
  videoModelsByPartnermodelID?:  {
    __typename: "ModelVideoModelConnection",
    items:  Array< {
      __typename: "VideoModel",
      id: string,
      title: string,
      author: string,
      url: string,
      tag?: string | null,
      partnermodelID?: string | null,
      retitle?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchVideoModelsQueryVariables = {
  filter?: SearchableVideoModelFilterInput | null,
  sort?: Array< SearchableVideoModelSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableVideoModelAggregationInput | null > | null,
};

export type SearchVideoModelsQuery = {
  searchVideoModels?:  {
    __typename: "SearchableVideoModelConnection",
    items:  Array< {
      __typename: "VideoModel",
      id: string,
      title: string,
      author: string,
      url: string,
      tag?: string | null,
      partnermodelID?: string | null,
      retitle?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetPatientModelQueryVariables = {
  id: string,
};

export type GetPatientModelQuery = {
  getPatientModel?:  {
    __typename: "PatientModel",
    id: string,
    username: string,
    name: string,
    birthdate: string,
    gender: Gender,
    partner_ids?: string | null,
    medication_ids?: string | null,
    pointed_video_ids?: string | null,
    watched_video_ids?: string | null,
    diagnosis_ids?: string | null,
    visited_history?: string | null,
    picture?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListPatientModelsQueryVariables = {
  filter?: ModelPatientModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPatientModelsQuery = {
  listPatientModels?:  {
    __typename: "ModelPatientModelConnection",
    items:  Array< {
      __typename: "PatientModel",
      id: string,
      username: string,
      name: string,
      birthdate: string,
      gender: Gender,
      partner_ids?: string | null,
      medication_ids?: string | null,
      pointed_video_ids?: string | null,
      watched_video_ids?: string | null,
      diagnosis_ids?: string | null,
      visited_history?: string | null,
      picture?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchPatientModelsQueryVariables = {
  filter?: SearchablePatientModelFilterInput | null,
  sort?: Array< SearchablePatientModelSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchablePatientModelAggregationInput | null > | null,
};

export type SearchPatientModelsQuery = {
  searchPatientModels?:  {
    __typename: "SearchablePatientModelConnection",
    items:  Array< {
      __typename: "PatientModel",
      id: string,
      username: string,
      name: string,
      birthdate: string,
      gender: Gender,
      partner_ids?: string | null,
      medication_ids?: string | null,
      pointed_video_ids?: string | null,
      watched_video_ids?: string | null,
      diagnosis_ids?: string | null,
      visited_history?: string | null,
      picture?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetMedicationModelQueryVariables = {
  id: string,
};

export type GetMedicationModelQuery = {
  getMedicationModel?:  {
    __typename: "MedicationModel",
    id: string,
    rank: number,
    name_eng: string,
    name_kor: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListMedicationModelsQueryVariables = {
  filter?: ModelMedicationModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMedicationModelsQuery = {
  listMedicationModels?:  {
    __typename: "ModelMedicationModelConnection",
    items:  Array< {
      __typename: "MedicationModel",
      id: string,
      rank: number,
      name_eng: string,
      name_kor: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchMedicationModelsQueryVariables = {
  filter?: SearchableMedicationModelFilterInput | null,
  sort?: Array< SearchableMedicationModelSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableMedicationModelAggregationInput | null > | null,
};

export type SearchMedicationModelsQuery = {
  searchMedicationModels?:  {
    __typename: "SearchableMedicationModelConnection",
    items:  Array< {
      __typename: "MedicationModel",
      id: string,
      rank: number,
      name_eng: string,
      name_kor: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetDiagnosisModelQueryVariables = {
  id: string,
};

export type GetDiagnosisModelQuery = {
  getDiagnosisModel?:  {
    __typename: "DiagnosisModel",
    id: string,
    rank: number,
    code: string,
    name_eng: string,
    name_kor: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListDiagnosisModelsQueryVariables = {
  filter?: ModelDiagnosisModelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDiagnosisModelsQuery = {
  listDiagnosisModels?:  {
    __typename: "ModelDiagnosisModelConnection",
    items:  Array< {
      __typename: "DiagnosisModel",
      id: string,
      rank: number,
      code: string,
      name_eng: string,
      name_kor: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchDiagnosisModelsQueryVariables = {
  filter?: SearchableDiagnosisModelFilterInput | null,
  sort?: Array< SearchableDiagnosisModelSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableDiagnosisModelAggregationInput | null > | null,
};

export type SearchDiagnosisModelsQuery = {
  searchDiagnosisModels?:  {
    __typename: "SearchableDiagnosisModelConnection",
    items:  Array< {
      __typename: "DiagnosisModel",
      id: string,
      rank: number,
      code: string,
      name_eng: string,
      name_kor: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type OnCreateTestModelSubscriptionVariables = {
  filter?: ModelSubscriptionTestModelFilterInput | null,
};

export type OnCreateTestModelSubscription = {
  onCreateTestModel?:  {
    __typename: "TestModel",
    id: string,
    rank: number,
    name_kor: string,
    name_eng: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateTestModelSubscriptionVariables = {
  filter?: ModelSubscriptionTestModelFilterInput | null,
};

export type OnUpdateTestModelSubscription = {
  onUpdateTestModel?:  {
    __typename: "TestModel",
    id: string,
    rank: number,
    name_kor: string,
    name_eng: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteTestModelSubscriptionVariables = {
  filter?: ModelSubscriptionTestModelFilterInput | null,
};

export type OnDeleteTestModelSubscription = {
  onDeleteTestModel?:  {
    __typename: "TestModel",
    id: string,
    rank: number,
    name_kor: string,
    name_eng: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateNoticeModelSubscriptionVariables = {
  filter?: ModelSubscriptionNoticeModelFilterInput | null,
};

export type OnCreateNoticeModelSubscription = {
  onCreateNoticeModel?:  {
    __typename: "NoticeModel",
    id: string,
    title: string,
    content: string,
    isDisplayed: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateNoticeModelSubscriptionVariables = {
  filter?: ModelSubscriptionNoticeModelFilterInput | null,
};

export type OnUpdateNoticeModelSubscription = {
  onUpdateNoticeModel?:  {
    __typename: "NoticeModel",
    id: string,
    title: string,
    content: string,
    isDisplayed: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteNoticeModelSubscriptionVariables = {
  filter?: ModelSubscriptionNoticeModelFilterInput | null,
};

export type OnDeleteNoticeModelSubscription = {
  onDeleteNoticeModel?:  {
    __typename: "NoticeModel",
    id: string,
    title: string,
    content: string,
    isDisplayed: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateDepartmentModelSubscriptionVariables = {
  filter?: ModelSubscriptionDepartmentModelFilterInput | null,
};

export type OnCreateDepartmentModelSubscription = {
  onCreateDepartmentModel?:  {
    __typename: "DepartmentModel",
    id: string,
    hospital: string,
    dept: string,
    PartnerModels?:  {
      __typename: "ModelPartnerModelConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateDepartmentModelSubscriptionVariables = {
  filter?: ModelSubscriptionDepartmentModelFilterInput | null,
};

export type OnUpdateDepartmentModelSubscription = {
  onUpdateDepartmentModel?:  {
    __typename: "DepartmentModel",
    id: string,
    hospital: string,
    dept: string,
    PartnerModels?:  {
      __typename: "ModelPartnerModelConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteDepartmentModelSubscriptionVariables = {
  filter?: ModelSubscriptionDepartmentModelFilterInput | null,
};

export type OnDeleteDepartmentModelSubscription = {
  onDeleteDepartmentModel?:  {
    __typename: "DepartmentModel",
    id: string,
    hospital: string,
    dept: string,
    PartnerModels?:  {
      __typename: "ModelPartnerModelConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreatePartnerModelSubscriptionVariables = {
  filter?: ModelSubscriptionPartnerModelFilterInput | null,
};

export type OnCreatePartnerModelSubscription = {
  onCreatePartnerModel?:  {
    __typename: "PartnerModel",
    id: string,
    username: string,
    name: string,
    point: number,
    departmentmodelID: string,
    VideoModels?:  {
      __typename: "ModelVideoModelConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdatePartnerModelSubscriptionVariables = {
  filter?: ModelSubscriptionPartnerModelFilterInput | null,
};

export type OnUpdatePartnerModelSubscription = {
  onUpdatePartnerModel?:  {
    __typename: "PartnerModel",
    id: string,
    username: string,
    name: string,
    point: number,
    departmentmodelID: string,
    VideoModels?:  {
      __typename: "ModelVideoModelConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeletePartnerModelSubscriptionVariables = {
  filter?: ModelSubscriptionPartnerModelFilterInput | null,
};

export type OnDeletePartnerModelSubscription = {
  onDeletePartnerModel?:  {
    __typename: "PartnerModel",
    id: string,
    username: string,
    name: string,
    point: number,
    departmentmodelID: string,
    VideoModels?:  {
      __typename: "ModelVideoModelConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateQuizModelSubscriptionVariables = {
  filter?: ModelSubscriptionQuizModelFilterInput | null,
};

export type OnCreateQuizModelSubscription = {
  onCreateQuizModel?:  {
    __typename: "QuizModel",
    id: string,
    question: string,
    answer: string,
    seq: number,
    videomodelID: string,
    source_name?: string | null,
    source_url?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateQuizModelSubscriptionVariables = {
  filter?: ModelSubscriptionQuizModelFilterInput | null,
};

export type OnUpdateQuizModelSubscription = {
  onUpdateQuizModel?:  {
    __typename: "QuizModel",
    id: string,
    question: string,
    answer: string,
    seq: number,
    videomodelID: string,
    source_name?: string | null,
    source_url?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteQuizModelSubscriptionVariables = {
  filter?: ModelSubscriptionQuizModelFilterInput | null,
};

export type OnDeleteQuizModelSubscription = {
  onDeleteQuizModel?:  {
    __typename: "QuizModel",
    id: string,
    question: string,
    answer: string,
    seq: number,
    videomodelID: string,
    source_name?: string | null,
    source_url?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateVideoModelSubscriptionVariables = {
  filter?: ModelSubscriptionVideoModelFilterInput | null,
};

export type OnCreateVideoModelSubscription = {
  onCreateVideoModel?:  {
    __typename: "VideoModel",
    id: string,
    title: string,
    author: string,
    url: string,
    QuizModels?:  {
      __typename: "ModelQuizModelConnection",
      nextToken?: string | null,
    } | null,
    tag?: string | null,
    partnermodelID?: string | null,
    retitle?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateVideoModelSubscriptionVariables = {
  filter?: ModelSubscriptionVideoModelFilterInput | null,
};

export type OnUpdateVideoModelSubscription = {
  onUpdateVideoModel?:  {
    __typename: "VideoModel",
    id: string,
    title: string,
    author: string,
    url: string,
    QuizModels?:  {
      __typename: "ModelQuizModelConnection",
      nextToken?: string | null,
    } | null,
    tag?: string | null,
    partnermodelID?: string | null,
    retitle?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteVideoModelSubscriptionVariables = {
  filter?: ModelSubscriptionVideoModelFilterInput | null,
};

export type OnDeleteVideoModelSubscription = {
  onDeleteVideoModel?:  {
    __typename: "VideoModel",
    id: string,
    title: string,
    author: string,
    url: string,
    QuizModels?:  {
      __typename: "ModelQuizModelConnection",
      nextToken?: string | null,
    } | null,
    tag?: string | null,
    partnermodelID?: string | null,
    retitle?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreatePatientModelSubscriptionVariables = {
  filter?: ModelSubscriptionPatientModelFilterInput | null,
};

export type OnCreatePatientModelSubscription = {
  onCreatePatientModel?:  {
    __typename: "PatientModel",
    id: string,
    username: string,
    name: string,
    birthdate: string,
    gender: Gender,
    partner_ids?: string | null,
    medication_ids?: string | null,
    pointed_video_ids?: string | null,
    watched_video_ids?: string | null,
    diagnosis_ids?: string | null,
    visited_history?: string | null,
    picture?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdatePatientModelSubscriptionVariables = {
  filter?: ModelSubscriptionPatientModelFilterInput | null,
};

export type OnUpdatePatientModelSubscription = {
  onUpdatePatientModel?:  {
    __typename: "PatientModel",
    id: string,
    username: string,
    name: string,
    birthdate: string,
    gender: Gender,
    partner_ids?: string | null,
    medication_ids?: string | null,
    pointed_video_ids?: string | null,
    watched_video_ids?: string | null,
    diagnosis_ids?: string | null,
    visited_history?: string | null,
    picture?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeletePatientModelSubscriptionVariables = {
  filter?: ModelSubscriptionPatientModelFilterInput | null,
};

export type OnDeletePatientModelSubscription = {
  onDeletePatientModel?:  {
    __typename: "PatientModel",
    id: string,
    username: string,
    name: string,
    birthdate: string,
    gender: Gender,
    partner_ids?: string | null,
    medication_ids?: string | null,
    pointed_video_ids?: string | null,
    watched_video_ids?: string | null,
    diagnosis_ids?: string | null,
    visited_history?: string | null,
    picture?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateMedicationModelSubscriptionVariables = {
  filter?: ModelSubscriptionMedicationModelFilterInput | null,
};

export type OnCreateMedicationModelSubscription = {
  onCreateMedicationModel?:  {
    __typename: "MedicationModel",
    id: string,
    rank: number,
    name_eng: string,
    name_kor: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateMedicationModelSubscriptionVariables = {
  filter?: ModelSubscriptionMedicationModelFilterInput | null,
};

export type OnUpdateMedicationModelSubscription = {
  onUpdateMedicationModel?:  {
    __typename: "MedicationModel",
    id: string,
    rank: number,
    name_eng: string,
    name_kor: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteMedicationModelSubscriptionVariables = {
  filter?: ModelSubscriptionMedicationModelFilterInput | null,
};

export type OnDeleteMedicationModelSubscription = {
  onDeleteMedicationModel?:  {
    __typename: "MedicationModel",
    id: string,
    rank: number,
    name_eng: string,
    name_kor: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateDiagnosisModelSubscriptionVariables = {
  filter?: ModelSubscriptionDiagnosisModelFilterInput | null,
};

export type OnCreateDiagnosisModelSubscription = {
  onCreateDiagnosisModel?:  {
    __typename: "DiagnosisModel",
    id: string,
    rank: number,
    code: string,
    name_eng: string,
    name_kor: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateDiagnosisModelSubscriptionVariables = {
  filter?: ModelSubscriptionDiagnosisModelFilterInput | null,
};

export type OnUpdateDiagnosisModelSubscription = {
  onUpdateDiagnosisModel?:  {
    __typename: "DiagnosisModel",
    id: string,
    rank: number,
    code: string,
    name_eng: string,
    name_kor: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteDiagnosisModelSubscriptionVariables = {
  filter?: ModelSubscriptionDiagnosisModelFilterInput | null,
};

export type OnDeleteDiagnosisModelSubscription = {
  onDeleteDiagnosisModel?:  {
    __typename: "DiagnosisModel",
    id: string,
    rank: number,
    code: string,
    name_eng: string,
    name_kor: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};
