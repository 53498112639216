import {
  Box,
  Button,
  Center,
  Flex,
  Input,
  LoadingOverlay,
  Pagination,
  Space,
  Title,
  UnstyledButton,
  createStyles,
  rem,
} from '@mantine/core';
import { randomId } from '@mantine/hooks';
import { IconEdit, IconListSearch } from '@tabler/icons-react';

import { Fragment, memo, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { client } from 'src';
import {
  PatientModel,
  SearchablePatientModelSortableFields,
  SearchableSortDirection,
} from 'src/API';
import { listPartnerModels, searchPatientModels } from 'src/graphql/queries';

import { DataTable, Layout, TableHeader, TableRow } from 'src/components';
import { TableHeaderType } from 'src/types/TableHeaderParams';
import { Toggle, onCheckUser } from 'src/utils';
import { retrievePageNumber, savePageNumber } from 'src/utils/localStoragePage';
import { LIMIT_PAGE } from 'src/constants';

const headerData: TableHeaderType[] = [
  { key: randomId(), label: '등록일' },
  { key: randomId(), label: '생년월일' },
  { key: randomId(), label: '이름' },
  { key: randomId(), label: '전화번호' },
  { key: randomId(), label: '성별' },
  { key: randomId(), label: '주치의' },
  { key: randomId(), label: '수정' },
  { key: randomId(), label: '상세보기' },
];

const Patient = () => {
  const { classes, cx } = useStyles();
  const { toggleAll, toggleRow } = Toggle();

  const navigate = useNavigate();

  const [partners, setPartners] = useState<{ label: string; value: string }[]>(
    []
  );

  const [pagePatient, setPagePatient] = useState<PatientModel[]>([]);
  const [selection, setSelection] = useState<string[]>([]);

  const [search, setSearch] = useState('');
  const [isSearch, setIsSearch] = useState(false);
  const [activePage, setActivePage] = useState(retrievePageNumber());
  const [totalCount, setTotalCount] = useState(0);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const userCheck = onCheckUser();
    if (!userCheck) {
      navigate('/login');
    }

    setActivePage(retrievePageNumber());
    const onFetch = async () => {
      await onGetPartners();
      await onGetPatient();
    };

    onFetch();
  }, []);

  useEffect(() => {
    if (isSearch) {
      onSearch();
    } else {
      onGetPatient();
    }
  }, [activePage]);

  const onGetPatient = async () => {
    const result = await client.graphql({
      query: searchPatientModels,
      variables: {
        limit: LIMIT_PAGE,
        from: LIMIT_PAGE * (activePage - 1),
        sort: [
          {
            field: SearchablePatientModelSortableFields.createdAt,
            direction: SearchableSortDirection.asc,
          },
        ],
      },
    });

    setTotalCount((result.data.searchPatientModels as any).total);

    const { items } = result.data.searchPatientModels;

    if (result.errors && result.errors.length > 0) {
      alert('데이터를 불러오는데 실패했습니다. 다시 시도해 주세요.');
      return;
    }
    setPagePatient(items);
    setIsLoading(false);
  };

  const onGetPartners = async () => {
    setIsLoading(true);
    const result = await client.graphql({ query: listPartnerModels });
    const list = result.data.listPartnerModels.items;

    const selectList = list.map((item) => ({
      label: item.name,
      value: item.id,
    }));

    setPartners(selectList);
  };

  const setActivePageAndSave = (page: number) => {
    setActivePage(page);
    savePageNumber(page);
  };

  const onSearch = useCallback(async () => {
    try {
      if (search.length === 0) {
        setIsSearch(false);
      } else {
        setIsSearch(true);
      }

      const result = await client.graphql({
        query: searchPatientModels,
        variables: {
          limit: LIMIT_PAGE,
          from: LIMIT_PAGE * (activePage - 1),
          filter: {
            or: [{ name: { wildcard: `*${search}*` } }],
          },
          sort: [
            {
              field: SearchablePatientModelSortableFields.createdAt,
              direction: SearchableSortDirection.asc,
            },
          ],
        },
      });

      setTotalCount((result.data.searchPatientModels as any).total);

      if (result.errors && result.errors.length > 0) {
        alert('데이터를 불러오는데 실패했습니다. 다시 시도해 주세요.');
        return;
      }

      const { items } = result.data.searchPatientModels;

      setPagePatient(items);
    } catch (error) {
      console.log(error);
    }
  }, [activePage, search, setTotalCount, setPagePatient, setIsSearch]);

  const partnerHeader = TableHeader({
    headers: headerData,
    data: pagePatient,
    selection,
    setSelection,
    toggleAll,
    dynamicKey: 'id',
    checkbox: true,
  });

  const partnerRow = TableRow({
    toggleRow,
    data: pagePatient,
    selection,
    setSelection,
    dynamicKey: 'id',
    checkbox: true,
    tableRows: (row) => (
      <Fragment key={randomId()}>
        <td>{new Date(row.createdAt).toLocaleString()}</td>
        <td>{row.birthdate}</td>
        <td>{row.name}</td>
        <td>{row.username}</td>
        <td>{row.gender}</td>
        <td>
          {partners.length !== 0
            ? partners.map(
                (partner) =>
                  row.partner_ids?.includes(partner.value) &&
                  `${partner.label} / `
              )
            : '-'}
        </td>
        <td>
          <UnstyledButton
            variant="default"
            onClick={() => navigate(`/admin/patient/edit/${row.id}`)}
          >
            <IconEdit color="gray" />
          </UnstyledButton>
        </td>
        <td>
          <UnstyledButton
            variant="default"
            onClick={() => navigate(`/admin/patient/detail/${row.id}`)}
          >
            <IconListSearch color="gray" />
          </UnstyledButton>
        </td>
      </Fragment>
    ),
  });

  const MemoizedDataTable = memo(DataTable);
  const MemoizedPagination = memo(Pagination);
  const MemoizedLoadingOverlay = memo(LoadingOverlay);

  const onInputChange = useCallback(
    (event: any) => {
      setSearch(event.target.value);
    },
    [setSearch]
  );

  const onSearchButtonClick = useCallback(() => {
    setActivePage(1);
    onSearch();
  }, [onSearch, setActivePage]);

  return (
    <Layout>
      <MemoizedLoadingOverlay
        style={{ position: 'fixed' }}
        visible={isLoading}
      />
      <Box>
        <Title order={4}>
          <span>환자 목록</span>
        </Title>
        <Flex align={'center'}>
          <Flex gap={rem(10)}>
            <Input
              value={search}
              onChange={onInputChange}
              rightSection={
                <UnstyledButton
                  onClick={() => {
                    setSearch('');
                  }}
                >
                  x
                </UnstyledButton>
              }
            />
            <Button onClick={onSearchButtonClick}>검색</Button>
          </Flex>
        </Flex>
        <MemoizedDataTable
          tableHeader={partnerHeader}
          tableRow={partnerRow}
          txt="데이터가 존재하지 않습니다."
        />
        <Space w={1} h={1} mb={rem(25)} />
        <Center>
          <MemoizedPagination
            total={Math.ceil(totalCount / LIMIT_PAGE)}
            value={activePage}
            onChange={setActivePageAndSave}
          />
        </Center>
        <Space w={1} h={1} mb={rem(200)} />
      </Box>
    </Layout>
  );
};

const useStyles = createStyles(() => ({
  buttonList: {
    paddingBlock: rem(10),
  },
}));

export default Patient;
