import { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Center,
  Flex,
  Input,
  LoadingOverlay,
  MultiSelect,
  Select,
  Space,
  Stack,
  Title,
  UnstyledButton,
  createStyles,
  rem,
} from '@mantine/core';
import { useNavigate, useParams } from 'react-router-dom';
import { IconArrowLeft } from '@tabler/icons-react';
import { Layout } from 'src/components';
import { client } from 'src';
import {
  getPatientModel,
  listDiagnosisModels,
  listMedicationModels,
  listPartnerModels,
  listVideoModels,
} from 'src/graphql/queries';
import { updatePatientModel } from 'src/graphql/mutations';
import { onCheckUser } from 'src/utils';
import { Gender } from 'src/API';
import { LIMIT_PAGE } from 'src/constants';

const initialGender = [
  { label: 'F', value: 'F' },
  { label: 'M', value: 'M' },
];

interface PatientType {
  username: string;
  name: string;
  birthdate: string;
  gender: string;
}

const PatientEdit = () => {
  const { id } = useParams();
  const { classes } = useStyles();

  const navigate = useNavigate();

  const [patientName, setPatientName] = useState('');

  const [patient, setPatient] = useState<PatientType>({
    username: '',
    name: '',
    birthdate: '',
    gender: '',
  });

  const [partners, setPartners] = useState<{ label: string; value: string }[]>(
    []
  );
  const [parsePartner, setParsePartner] = useState<string[]>([]);

  const [medi, setMedi] = useState<{ label: string; value: string }[]>([]);
  const [parseMedi, setParseMedi] = useState<string[]>([]);

  const [diag, setDiag] = useState<{ label: string; value: string }[]>([]);
  const [parseDiag, setParseDiag] = useState<string[]>([]);

  const [pointedVideo, setPointedVideo] = useState<
    { label: string; value: string }[]
  >([]);
  const [parsePointedVideo, setParsePointedVideo] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const userCheck = onCheckUser();
    if (!userCheck) {
      navigate('/login');
    }

    const onFetch = async () => {
      await onGetPatient();
      await onGetPartnerList();
      await onGetMediList();
      await onGetDiagList();
      await onGetPointedVideoList();
    };
    onFetch();
  }, []);

  const onGetPatient = async () => {
    try {
      const result = await client.graphql({
        query: getPatientModel,
        variables: {
          id: String(id),
        },
      });

      if (result.errors && result.errors.length > 0) {
        alert('데이터를 불러오는데 실패했습니다. 다시 시도해 주세요.');
        return;
      }

      const item = result.data.getPatientModel;
      if (item) {
        setPatient({
          ...patient,
          username: item.username,
          name: item.name,
          birthdate: item.birthdate,
          gender: item.gender,
        });
        setPatientName(item.name);
        setParsePartner(JSON.parse(item.partner_ids || ''));
        setParseMedi(JSON.parse(item.medication_ids || ''));
        setParseDiag(JSON.parse(item.diagnosis_ids || ''));
        setParsePointedVideo(JSON.parse(item.pointed_video_ids || ''));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onGetPartnerList = async () => {
    let allItems: any[] = [];
    let newNextToken = null;

    do {
      const result = await client.graphql({
        query: listPartnerModels,
        variables: {
          limit: LIMIT_PAGE,
          nextToken: newNextToken,
        },
      });

      const { items, nextToken }: any = result.data.listPartnerModels;
      allItems = [...allItems, ...items];
      newNextToken = nextToken;
    } while (newNextToken);

    const selectList = allItems.map((data) => {
      const { id, name } = data;
      return {
        label: name,
        value: id,
      };
    });
    setPartners(selectList);
  };

  const onGetMediList = async () => {
    let allItems: any[] = [];
    let newNextToken = null;

    do {
      const result = await client.graphql({
        query: listMedicationModels,
        variables: {
          limit: LIMIT_PAGE,
          nextToken: newNextToken,
        },
      });

      const { items, nextToken }: any = result.data.listMedicationModels;
      allItems = [...allItems, ...items];
      newNextToken = nextToken;
    } while (newNextToken);

    const selectList = allItems.map((data) => {
      const { id, name_eng } = data;
      return {
        label: name_eng,
        value: id,
      };
    });

    setMedi(selectList);
  };

  const onGetDiagList = async () => {
    let allItems: any[] = [];
    let newNextToken = null;

    do {
      const result = await client.graphql({
        query: listDiagnosisModels,
        variables: {
          limit: LIMIT_PAGE,
          nextToken: newNextToken,
        },
      });

      const { items, nextToken }: any = result.data.listDiagnosisModels;
      allItems = [...allItems, ...items];
      newNextToken = nextToken;
    } while (newNextToken);

    const selectList = allItems.map((data) => {
      const { id, name_eng } = data;
      return {
        label: name_eng,
        value: id,
      };
    });

    setDiag(selectList);
  };

  const onGetPointedVideoList = async () => {
    let allItems: any[] = [];
    let newNextToken = null;

    do {
      const result = await client.graphql({
        query: listVideoModels,
        variables: {
          limit: LIMIT_PAGE,
          nextToken: newNextToken,
        },
      });

      const { items, nextToken }: any = result.data.listVideoModels;
      allItems = [...allItems, ...items];
      newNextToken = nextToken;
    } while (newNextToken);

    const selectList = allItems.map((data) => {
      const { id, title } = data;
      return {
        label: title,
        value: id,
      };
    });

    setPointedVideo(selectList);
  };

  const onEditPatient = async () => {
    const { username, name, birthdate, gender } = patient;

    if (username.length == 0 || name.length == 0 || birthdate.length == 0) {
      alert('빈 항목이 있습니다. 모두 입력해 주세요.');
      return;
    }
    try {
      setIsLoading(true);
      const result = await client.graphql({
        query: updatePatientModel,
        variables: {
          input: {
            id: String(id),
            username,
            name,
            birthdate,
            gender: gender === 'F' ? Gender.F : Gender.M,
            medication_ids: JSON.stringify(parseMedi),
            partner_ids: JSON.stringify(parsePartner),
            diagnosis_ids: JSON.stringify(parseDiag),
            pointed_video_ids: JSON.stringify(parsePointedVideo),
          },
        },
      });

      if (result.errors && result.errors.length > 0) {
        alert('데이터 수정에 실패했습니다. 다시 시도해 주세요.');
        return;
      }

      setTimeout(() => {
        setIsLoading(false);
        navigate(-1);
      }, 1800);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Layout>
      <LoadingOverlay style={{ position: 'fixed' }} visible={isLoading} />
      <Space w={1} h={1} mb={rem(50)} />
      <Flex direction={'column'} justify={'center'} align={'center'} h={'100%'}>
        <Box className={classes.form}>
          <Flex justify={'space-between'}>
            <UnstyledButton onClick={() => navigate(-1)}>
              <IconArrowLeft />
            </UnstyledButton>
            <Center>
              <Title order={4}>
                <span>{patientName}님의 상세 정보 수정</span>
              </Title>
            </Center>
            <Button onClick={onEditPatient}>수정</Button>
          </Flex>
          <Stack>
            <Box>
              <Input.Wrapper label="이름" withAsterisk>
                <Input
                  value={patient.name}
                  onChange={(event) =>
                    setPatient((prev) => ({
                      ...prev,
                      name: event.target.value,
                    }))
                  }
                />
              </Input.Wrapper>
            </Box>
            <Box>
              <Input.Wrapper withAsterisk label="전화번호">
                <Input
                  placeholder="'-'를 제외하고 입력해 주세요."
                  value={patient.username}
                  onChange={(event) =>
                    setPatient((prev) => ({
                      ...prev,
                      username: event.target.value,
                    }))
                  }
                />
              </Input.Wrapper>
            </Box>
            <Box>
              <Input.Wrapper withAsterisk label="생년월일">
                <Input
                  placeholder="ex) 1994-12-03"
                  value={patient.birthdate}
                  onChange={(event) =>
                    setPatient((prev) => ({
                      ...prev,
                      birthdate: event.target.value,
                    }))
                  }
                />
              </Input.Wrapper>
            </Box>
            <Box>
              <Select
                label="성별"
                withAsterisk
                value={patient.gender}
                data={initialGender}
                onChange={(value) =>
                  setPatient((prev) => ({ ...prev, gender: value || '' }))
                }
              />
            </Box>
            <Box>
              <MultiSelect
                searchable
                label="주치의"
                value={parsePartner}
                data={partners}
                onChange={(value) => setParsePartner(value)}
              />
            </Box>
            <Box>
              <MultiSelect
                searchable
                label="약품"
                value={parseMedi}
                data={medi}
                onChange={(value) => setParseMedi(value)}
              />
            </Box>
            <Box>
              <MultiSelect
                searchable
                label="진단"
                value={parseDiag}
                data={diag}
                onChange={(value) => setParseDiag(value)}
              />
            </Box>
            <Box>
              <MultiSelect
                searchable
                label="추천영상"
                value={parsePointedVideo}
                data={pointedVideo}
                onChange={(value) => setParsePointedVideo(value)}
              />
            </Box>
          </Stack>
          <Space w={1} h={1} mb={rem(50)} />
        </Box>
      </Flex>
    </Layout>
  );
};

const useStyles = createStyles(() => ({
  form: {
    width: '80%',
    maxWidth: rem(500),
  },
}));

export default PatientEdit;
