interface ThProps {
  children: React.ReactNode;
  thWidth?: string;
  marginRight?: string;
  align?: TextAlign;
}
type TextAlign =
  | 'left'
  | 'right'
  | 'center'
  | 'justify'
  | 'initial'
  | 'inherit';

const Th = ({ children, thWidth, align, marginRight }: ThProps) => {
  return (
    <th style={{ width: thWidth }}>
      <div style={{ textAlign: align, marginRight: marginRight }}>
        {children}
      </div>
    </th>
  );
};

export default Th;
