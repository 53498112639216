import { Button, Input, Flex, rem, createStyles, Center } from '@mantine/core';
import sha256 from 'crypto-js/sha256';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { onCheckUser } from 'src/utils';
import { Layout } from 'src/components';

const Login = () => {
  const { classes } = useStyles();

  const navigate = useNavigate();

  const [loginInfo, setLoginInfo] = useState({
    id: '',
    password: '',
  });

  useEffect(() => {
    const userCheck = onCheckUser();
    if (userCheck) {
      navigate('/admin/patient');
    }
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  const onLogin = () => {
    const { id, password } = loginInfo;

    if (id.length === 0 || password.length === 0) {
      alert('아이디와 비밀번호는 필수로 입력해주세요.');
    }

    const userPasswordHash = sha256(password).toString();
    const adminPasswordHash = sha256(
      process.env.REACT_APP_ADMIN_PASSWORD || ''
    ).toString();
    const ADMIN_ID = process.env.REACT_APP_ADMIN_ID;

    if (id === ADMIN_ID && userPasswordHash === adminPasswordHash) {
      localStorage.setItem('login', JSON.stringify(true));
      navigate('/admin/patient');
    } else {
      alert('아이디 또는 비밀번호가 일치하지 않습니다.');
    }
  };

  return (
    <Layout tabs={false}>
      <Center className={classes.formCenter}>
        <form className={classes.form}>
          <Flex justify={'center'} direction={'column'} gap={rem(20)}>
            <span className={classes.loginTitle}>로그인</span>
            <Input.Wrapper label="아이디">
              <Input
                onChange={(event) =>
                  setLoginInfo({ ...loginInfo, id: event.currentTarget.value })
                }
              />
            </Input.Wrapper>
            <Input.Wrapper label="비밀번호">
              <Input
                type="password"
                onChange={(event) =>
                  setLoginInfo({
                    ...loginInfo,
                    password: event.currentTarget.value,
                  })
                }
              />
            </Input.Wrapper>
            <Button type="submit" onClick={onLogin}>
              로그인
            </Button>
          </Flex>
        </form>
      </Center>
    </Layout>
  );
};

const useStyles = createStyles(() => ({
  formCenter: {
    height: '100%',
  },
  form: {
    width: '70%',
    maxWidth: rem(500),
  },
  loginTitle: {
    fontSize: rem(24),
    fontWeight: 700,
  },
}));

export default Login;
