import {
  BackgroundImage,
  Box,
  Center,
  Flex,
  Grid,
  Image,
  Space,
  UnstyledButton,
  createStyles,
  rem,
} from '@mantine/core';
import { BrowserView, MobileView } from 'react-device-detect';
import { Layout } from 'src/components';
import AIVideoBanner from '../assets/section-banner.jpg';
import classnames from 'classnames';
import DocIcon from '../assets/doc.svg';
import Arrow from '../assets/arrow-right.svg';
import Procedure01 from '../assets/service_video_01.png';
import Procedure02 from '../assets/service_video_02.png';
import Procedure03 from '../assets/service_video_03.png';
import Procedure04 from '../assets/service_video_04.png';
import Procedure05 from '../assets/service_video_05.png';
import Procedure06 from '../assets/service_video_06.png';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

const AIVideoProduction = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  return (
    <Layout tabs={false}>
      <BrowserView>
        {/* 메인배너 */}
        <BackgroundImage
          src={AIVideoBanner}
          style={{
            zIndex: -2,
            position: 'relative',
          }}
        >
          <Flex
            direction={'column'}
            gap={rem(15)}
            justify={'center'}
            align={'center'}
            className={classes.mainContents01}
          >
            <span className={classes.title}>SERVICE</span>
            <span className={classes.pageDescription}>AI 영상 제작</span>
          </Flex>
        </BackgroundImage>
        <Space mb={rem(100)} />
        <Flex direction={'column'} align={'center'}>
          <Flex
            direction={'column'}
            gap={rem(20)}
            className={classes.introduceBox}
          >
            <span
              className={classnames(classes.colorEmphasis, classes.emphasis)}
            >
              영상 제작 과정 소개
            </span>
            <Box className={classes.responsiveGrid}>
              <Flex>
                <Flex className={classnames(classes.producingGrid, 'grid01')}>
                  <BackgroundImage src={Procedure01} w={'100%'} h={'100%'}>
                    <span className={classes.producingGridText}>제작 의뢰</span>
                  </BackgroundImage>
                </Flex>
              </Flex>

              <Image
                className={classes.responsArrow}
                src={Arrow}
                width={rem(50)}
                height={rem(50)}
              />

              <Flex className={classnames(classes.producingGrid, 'grid01')}>
                <BackgroundImage src={Procedure02} w={'100%'} h={'100%'}>
                  <span className={classes.producingGridText}>
                    사전 미팅 및 니즈 조사
                  </span>
                </BackgroundImage>
              </Flex>

              <Image
                className={classes.responsArrow}
                src={Arrow}
                width={rem(50)}
                height={rem(50)}
              />

              <Flex className={classnames(classes.producingGrid, 'grid01')}>
                <BackgroundImage src={Procedure03} w={'100%'} h={'100%'}>
                  <span className={classes.producingGridText}>
                    Script 및 자료 제작
                  </span>
                </BackgroundImage>
              </Flex>
            </Box>
            <Flex className={classes.responsiveGrid}>
              <Image
                className={classes.responsArrow}
                src={Arrow}
                width={rem(50)}
                height={rem(50)}
              />
              <Flex>
                <Flex className={classnames(classes.producingGrid, 'grid01')}>
                  <BackgroundImage src={Procedure04} w={'100%'} h={'100%'}>
                    <span className={classes.producingGridText}>
                      녹화 및 AI 딥페이크 제작
                    </span>
                  </BackgroundImage>
                </Flex>
              </Flex>

              <Image
                className={classes.responsArrow}
                src={Arrow}
                width={rem(50)}
                height={rem(50)}
              />

              <Flex className={classnames(classes.producingGrid, 'grid01')}>
                <BackgroundImage src={Procedure05} w={'100%'} h={'100%'}>
                  <span className={classes.producingGridText}>영상편집</span>
                </BackgroundImage>
              </Flex>

              <Image
                className={classes.responsArrow}
                src={Arrow}
                width={rem(50)}
                height={rem(50)}
              />

              <Flex className={classnames(classes.producingGrid, 'grid01')}>
                <BackgroundImage src={Procedure06} w={'100%'} h={'100%'}>
                  <span className={classes.producingGridText}>
                    검토 후 최종 완성
                  </span>
                </BackgroundImage>
              </Flex>
            </Flex>

            <Space mb={rem(30)} />
            <div className={classes.buttonContainer}>
              <UnstyledButton
                onClick={() => navigate('/pricing')}
                className={classes.button}
              >
                <span>영상 제작 기준표 자세히 보기</span>
              </UnstyledButton>
              <UnstyledButton
                onClick={() => navigate('/contact')}
                className={classes.fillButton}
              >
                <span>제작 의뢰</span>
              </UnstyledButton>
            </div>
          </Flex>
        </Flex>
        <Space mb={rem(80)} />
      </BrowserView>
      <MobileView>
        <Space w={4} h={5} mb={rem(42)} />
        {/* 메인배너 */}
        <BackgroundImage
          src={AIVideoBanner}
          miw={rem(390)}
          mih={rem(220)}
          style={{
            zIndex: -2,
            position: 'relative',
          }}
        >
          <Flex
            direction={'column'}
            gap={rem(15)}
            justify={'center'}
            align={'center'}
            className={classnames(
              classes.mainContents01,
              classes.m_mainContents1
            )}
          >
            <span className={classnames(classes.title, classes.m_title)}>
              SERVICE
            </span>
            <span className={classes.m_pageDescription}>AI 영상 제작</span>
          </Flex>
        </BackgroundImage>
        <Space mb={rem(34)} />
        <Flex direction={'column'} align={'center'}>
          <Flex
            direction={'column'}
            gap={rem(20)}
            className={classes.introduceBox}
          >
            <span
              className={classnames(classes.colorEmphasis, classes.emphasis)}
            >
              영상 제작 과정 소개
            </span>
            <Flex align={'center'} justify={'center'} direction={'column'}>
              <Flex>
                <Flex className={classnames(classes.producingGrid, 'grid01')}>
                  <BackgroundImage src={Procedure01} w={'100%'} h={'100%'}>
                    <span className={classes.producingGridText}>제작 의뢰</span>
                  </BackgroundImage>
                </Flex>
              </Flex>

              <Image
                className={classes.arrowDown}
                src={Arrow}
                width={rem(50)}
                height={rem(50)}
              />

              <Flex className={classnames(classes.producingGrid, 'grid01')}>
                <BackgroundImage src={Procedure02} w={'100%'} h={'100%'}>
                  <span className={classes.producingGridText}>
                    사전 미팅 및 니즈 조사
                  </span>
                </BackgroundImage>
              </Flex>

              <Image
                className={classes.arrowDown}
                src={Arrow}
                width={rem(50)}
                height={rem(50)}
              />

              <Flex className={classnames(classes.producingGrid, 'grid01')}>
                <BackgroundImage src={Procedure03} w={'100%'} h={'100%'}>
                  <span className={classes.producingGridText}>
                    Script 및 자료 제작
                  </span>
                </BackgroundImage>
              </Flex>

              <Image
                className={classes.arrowDown}
                src={Arrow}
                width={rem(50)}
                height={rem(50)}
              />
              <Flex>
                <Flex className={classnames(classes.producingGrid, 'grid01')}>
                  <BackgroundImage src={Procedure04} w={'100%'} h={'100%'}>
                    <span className={classes.producingGridText}>
                      녹화 및 AI 딥페이크 제작
                    </span>
                  </BackgroundImage>
                </Flex>
              </Flex>

              <Image
                className={classes.arrowDown}
                src={Arrow}
                width={rem(50)}
                height={rem(50)}
              />

              <Flex className={classnames(classes.producingGrid, 'grid01')}>
                <BackgroundImage src={Procedure05} w={'100%'} h={'100%'}>
                  <span className={classes.producingGridText}>영상편집</span>
                </BackgroundImage>
              </Flex>

              <Image
                className={classes.arrowDown}
                src={Arrow}
                width={rem(50)}
                height={rem(50)}
              />

              <Flex className={classnames(classes.producingGrid, 'grid01')}>
                <BackgroundImage src={Procedure03} w={'100%'} h={'100%'}>
                  <span className={classes.producingGridText}>
                    검토 후 최종 완성
                  </span>
                </BackgroundImage>
              </Flex>
            </Flex>

            <Space mb={rem(30)} />
            <div className={classes.buttonContainer}>
              <UnstyledButton
                onClick={() => navigate('/pricing')}
                className={classes.button}
              >
                <span>영상 제작 기준표 자세히 보기</span>
              </UnstyledButton>
              <UnstyledButton
                onClick={() => navigate('/contact')}
                className={classes.fillButton}
              >
                <span>제작 의뢰</span>
              </UnstyledButton>
            </div>
          </Flex>
        </Flex>
        <Space mb={rem(80)} />
      </MobileView>
    </Layout>
  );
};

const useStyles = createStyles(() => ({
  mainContents01: {
    paddingInline: rem(50),
    height: rem(400),
    color: 'white',
    ':after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: -1,
    },
  },
  m_mainContents1: {
    height: rem(220),
    paddingInline: rem(30),
    paddingBlock: rem(30),
  },
  title: {
    fontSize: rem(96),
    fontWeight: 700,
    lineHeight: rem(115),
  },
  m_title: {
    fontSize: rem(36),
    fontWeight: 700,
    lineHeight: rem(43),
  },
  pageDescription: {
    fontSize: rem(36),
    lineHeight: rem(43),
  },
  m_pageDescription: {
    fontSize: rem(16),
    lineHeight: rem(19),
  },
  aiVideoProductionTitle: {
    fontSize: rem(48),
    fontWeight: 800,
  },
  m_aiVideoProductionTitle: {
    fontSize: rem(32),
  },
  introduceBox: {
    maxWidth: rem(1280),
    paddingInline: rem(30),
    marginInline: 'auto',
    marginTop: rem(50),
  },
  m_introduceBox: {
    marginInline: 'auto',
  },
  emphasis: {
    fontWeight: 700,
    fontSize: rem(16),
  },
  colorEmphasis: {
    color: '#2C93AD',
  },

  responsiveGrid: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    gap: rem(30),

    [`@media (max-width: ${rem(1280)})`]: {
      flexDirection: 'column',
    },
    // [`@media (min-width: ${rem(1024)})`]: {
    //   fontSize: rem(20),
    // },
  },
  responsArrow: {
    [`@media (max-width: ${rem(1280)})`]: {
      transform: 'rotate(90deg)',
    },
  },
  producingGrid: {
    position: 'relative',
    border: '1px solid #CCCCCC',
    borderRadius: rem(16),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: rem(296),
    height: rem(250),
    overflow: 'hidden',
    // paddingInline: rem(60),
    // paddingBlock: rem(30),
  },
  producingGridText: {
    position: 'absolute',
    left: '50%',
    transform: `translateX(-50%)`,
    bottom: rem(15),
    height: rem(34),
    lineHeight: rem(34),
    padding: `${rem(0)} ${rem(30)}`,
    borderRadius: rem(16),
    textAlign: 'center',
    color: '#fff',
    backgroundColor: '#2C93AD',
    fontSize: rem(20),
    whiteSpace: 'nowrap',
    fontWeight: 600,
  },
  producingArrowGrid: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingInline: rem(55),
    paddingBlock: rem(30),
  },
  middleArrowBox: {
    padding: 0,
  },
  middleArrow: {
    width: rem(300),
    marginBlock: rem(15),
  },
  arrowDown: {
    transform: 'rotate(90deg)',
  },
  arrowLeft: {
    transform: 'rotate(180deg)',
  },
  priceGrid: {
    border: '1px solid #CCCCCC',
    borderRadius: rem(16),
    paddingInline: rem(20),
    paddingBlock: rem(26),
    minHeight: rem(343),
  },
  priceTitle: {
    fontWeight: 600,
    fontSize: rem(36),
    textAlign: 'center',
  },
  priceCondition: {
    flex: 1,
    fontSize: rem(18),
    fontWeight: 600,
    listStyle: 'inside',
  },
  priceBox: {
    minHeight: rem(73),
  },
  priceText: {
    display: 'block',
    fontWeight: 600,
  },
  originPrice: {
    fontSize: rem(18),
    color: '#CCCCCC',
    textDecoration: 'line-through',
    lineHeight: rem(25),
  },
  discountPrice: {
    fontSize: rem(32),
    lineHeight: rem(45),
  },
  addPriceBox: {
    border: '1px solid #CCCCCC',
    borderRadius: rem(16),
    marginInline: rem(80),
    paddingBlock: rem(50),
    paddingInline: rem(57),
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    [`@media (max-width: ${rem(1280)})`]: {
      flexDirection: 'column',
      justifyContent: 'center',
      gap: rem(30),
    },
  },
  button: {
    maxWidth: rem(400),
    minWidth: rem(330),
    height: rem(60),
    border: `${rem(1)} solid #2A93AD`,
    borderRadius: rem(16),
    backgroundColor: 'white',
    color: '#2D93AD',
    textAlign: 'center',
    paddingBlock: rem(14),
    fontSize: rem(18),
    fontWeight: 500,
    lineHeight: rem(25),
    ':hover': {
      backgroundColor: '#2D93AD',
      color: 'white',
    },
  },
  fillButton: {
    maxWidth: rem(400),
    minWidth: rem(330),
    height: rem(60),
    border: `${rem(1)} solid #2A93AD`,
    borderRadius: rem(16),
    backgroundColor: '#2D93AD',
    color: 'white',
    textAlign: 'center',
    paddingBlock: rem(14),
    fontSize: rem(18),
    fontWeight: 500,
    lineHeight: rem(25),
    ':hover': {
      backgroundColor: 'white',
      color: '#2D93AD',
    },
  },
  m_priceGrid: {
    paddingInline: rem(40),
  },
  m_priceTitle: {
    textAlign: 'start',
  },
  m_priceCondition: {
    fontSize: rem(16),
  },
  m_originPrice: {
    fontSize: rem(16),
  },
  m_addPriceBox: {
    border: '1px solid #CCCCCC',
    borderRadius: rem(16),
    paddingInline: rem(15),
    paddingBlock: rem(25),
  },
  addPriceInfoTitle: {
    fontSize: rem(24),
    fontWeight: 600,
    lineHeight: rem(25),
  },
  processContainer: {
    width: 'inherit',
    boxSizing: 'border-box',
  },
  processBox: {
    minWidth: rem(300),
    border: `${rem(1)} solid #CCCCCC`,
    borderRadius: rem(16),
    paddingBlock: rem(38),
    boxSizing: 'border-box',
  },
}));

export default AIVideoProduction;
