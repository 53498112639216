import {
  DataTable,
  DeleteModal,
  Layout,
  TableHeader,
  TableRow,
} from '../../components';
import {
  Box,
  Button,
  Center,
  Flex,
  Input,
  LoadingOverlay,
  Modal,
  Pagination,
  Space,
  Title,
  UnstyledButton,
  createStyles,
  rem,
} from '@mantine/core';
import { useEffect, useState, Fragment, memo } from 'react';
import Toggle from '../../utils/Toggle';
import { TableHeaderType } from 'src/types/TableHeaderParams';
import { randomId, useDisclosure } from '@mantine/hooks';
import { onCheckUser } from 'src/utils';
import { IconEdit } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import XLSX from 'xlsx';
import { client } from 'src';
import { searchMedicationModels } from 'src/graphql/queries';
import {
  createMedicationModel,
  deleteMedicationModel,
} from 'src/graphql/mutations';
import { onDeleteMedicationModel } from 'src/graphql/subscriptions';
import {
  SearchableMedicationModelSortableFields,
  SearchableSortDirection,
} from 'src/API';
import { retrievePageNumber, savePageNumber } from 'src/utils/localStoragePage';
import { LIMIT_PAGE } from 'src/constants';

const headerData: TableHeaderType[] = [
  { key: randomId(), label: '순위' },
  { key: randomId(), label: '이름' },
  { key: randomId(), label: '영문 이름' },
  { key: randomId(), label: '수정' },
];

const Medication = () => {
  const { classes, cx } = useStyles();
  const { toggleAll, toggleRow } = Toggle();

  const navigate = useNavigate();

  const [deleteOpened, deleteHandler] = useDisclosure(false);
  const [excelOpened, excelHandler] = useDisclosure(false);

  const [pageMedi, setPageMedi] = useState<any[]>([]);
  const [selection, setSelection] = useState<string[]>([]);

  const [files, setFiles] = useState<string[][]>([]);

  const [search, setSearch] = useState('');
  const [isSearch, setIsSearch] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [activePage, setActivePage] = useState(retrievePageNumber());
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    const userCheck = onCheckUser();
    if (!userCheck) {
      navigate('/login');
    }
    setActivePage(retrievePageNumber());

    const onFetch = async () => {
      await onGetMedi();
    };

    onFetch();

    const deleteSub = client
      .graphql({ query: onDeleteMedicationModel })
      .subscribe({
        next: ({ data }: any) => {
          console.log(data);
        },
        error: (error: any) => console.warn(error),
      });

    return () => {
      deleteSub.unsubscribe();
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (isSearch) {
        await onSearch();
      } else {
        await onGetMedi();
      }
    };

    fetchData();
  }, [activePage]);

  const setActivePageAndSave = (page: number) => {
    setActivePage(page);
    savePageNumber(page);
  };

  const onGetMedi = async () => {
    setIsLoading(true);
    await client
      .graphql({
        query: searchMedicationModels,
        variables: {
          limit: LIMIT_PAGE,
          from: LIMIT_PAGE * (activePage - 1),
          sort: [
            {
              field: SearchableMedicationModelSortableFields.rank,
              direction: SearchableSortDirection.asc,
            },
          ],
        },
      })
      .then((result) => {
        setTotalCount((result.data.searchMedicationModels as any).total);

        if (result.errors && result.errors.length > 0) {
          alert('데이터 불러오는데 실패했습니다. 다시 시도해 주세요.');
          return;
        }

        const { items } = result.data.searchMedicationModels;

        setPageMedi(items);
        setIsLoading(false);
      });
  };

  const onSearch = async () => {
    if (search.length === 0) {
      setIsSearch(false);
    } else {
      setIsSearch(true);
    }
    setIsLoading(true);

    await client
      .graphql({
        query: searchMedicationModels,
        variables: {
          limit: LIMIT_PAGE,
          from: LIMIT_PAGE * (activePage - 1),
          filter: {
            or: [
              { name_kor: { wildcard: `*${search}*` } },
              { name_eng: { wildcard: `*${search}*` } },
            ],
          },
          sort: [
            {
              field: SearchableMedicationModelSortableFields.rank,
              direction: SearchableSortDirection.asc,
            },
          ],
        },
      })
      .then((result) => {
        setTotalCount((result.data.searchMedicationModels as any).total);

        if (result.errors && result.errors.length > 0) {
          alert('데이터 검색을 실패했습니다. 다시 시도해 주세요.');
          setIsLoading(false);
          return;
        }

        const { items } = result.data.searchMedicationModels;

        setPageMedi(items);
        setIsLoading(false);
      });
  };

  const mediHeader = TableHeader({
    headers: headerData,
    data: pageMedi,
    selection,
    setSelection,
    toggleAll,
    dynamicKey: 'id',
    checkbox: true,
  });

  const mediRow = TableRow({
    toggleRow,
    data: pageMedi,
    selection,
    setSelection,
    dynamicKey: 'id',
    checkbox: true,
    tableRows: (row) => (
      <Fragment key={randomId()}>
        <td>{row.rank}</td>
        <td>{row.name_kor}</td>
        <td>{row.name_eng}</td>
        <td>
          <UnstyledButton
            onClick={() => navigate(`/admin/medication/edit/${row.id}`)}
            variant="default"
          >
            <IconEdit color="gray" />
          </UnstyledButton>
        </td>
      </Fragment>
    ),
  });

  const onDeleteMedi = async () => {
    if (selection.length === 0) {
      alert('선택된 약품이 없습니다.');
      return;
    }
    try {
      setIsLoading(true);
      await Promise.all(
        selection.map(async (medi) => {
          try {
            const result = await client.graphql({
              query: deleteMedicationModel,
              variables: {
                input: {
                  id: medi,
                },
              },
            });

            if (result.errors && result.errors.length > 0) {
              alert('데이터 삭제를 실패했습니다. 다시 시도해 주세요.');
              return;
            }
          } catch (error) {
            console.error(error);
          }
        })
      );
      deleteHandler.close();

      setTimeout(() => {
        setIsLoading(false);
        onGetMedi();
      }, 1800);
    } catch (error) {
      console.error(error);
    }
  };

  // ! 엑셀 기능
  const onGetExcel = (e: any) => {
    const inputElement = e.target as HTMLInputElement;
    const files = inputElement.files;

    if (files) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const reader = new FileReader();

        reader.onload = function (e: ProgressEvent<FileReader>) {
          const data = e.target?.result as ArrayBuffer;
          let binary = '';
          const bytes = new Uint8Array(data);
          const length = bytes.byteLength;

          for (let j = 0; j < length; j++) {
            binary += String.fromCharCode(bytes[j]);
          }

          const workbook = XLSX.read(binary, { type: 'binary' });

          workbook.SheetNames.forEach(async function (item, index, array) {
            const csvData = XLSX.utils.sheet_to_csv(workbook.Sheets[item], {
              FS: ';',
            });
            const csvArr = csvData.split('\n');
            csvArr.shift();

            const rowArr = csvArr.map((row) => row.split(';'));
            setFiles(rowArr);
          });
        };
        reader.readAsArrayBuffer(file);
      }
    }
  };

  const onUpload = async () => {
    setIsLoading(true);

    try {
      if (files.length === 0) {
        alert('파일을 선택해 주세요.');
        return;
      }
      files.forEach(async (row) => {
        const repeatResult = await client.graphql({
          query: searchMedicationModels,
          variables: {
            filter: { name_kor: { eq: row[2] } },
          },
        });

        if (repeatResult.data.searchMedicationModels.items.length > 0) {
          return;
        }

        const result = await client.graphql({
          query: createMedicationModel,
          variables: {
            input: {
              rank: +row[0],
              name_eng: row[1],
              name_kor: row[2],
            },
          },
        });

        if (result.errors && result.errors.length > 0) {
          alert('데이터 업로드를 실패했습니다. 다시 시도해 주세요.');
          return;
        }
      });
      setFiles([]);
      excelHandler.close();
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const MemoizedDataTable = memo(DataTable);
  const MemoizedDeleteModal = memo(DeleteModal);
  const MemoizedPagination = memo(Pagination);

  return (
    <Layout>
      <LoadingOverlay style={{ position: 'fixed' }} visible={isLoading} />
      <Box>
        <Title order={4}>
          <span>약품 목록</span>
        </Title>
        <Flex align={'center'} justify={'space-between'}>
          <Flex gap={rem(10)}>
            <Input
              value={search}
              onChange={(event) => setSearch(event.target.value)}
              rightSection={
                <UnstyledButton
                  onClick={() => {
                    setSearch('');
                  }}
                >
                  x
                </UnstyledButton>
              }
            />
            <Button
              onClick={() => {
                setActivePage(1);
                onSearch();
              }}
            >
              검색
            </Button>
          </Flex>
          <Flex gap={rem(10)} className={classes.buttonList}>
            <Button variant="default" onClick={excelHandler.open}>
              엑셀로 추가
            </Button>
            <Button
              variant="default"
              onClick={() => navigate('/admin/medication/add')}
            >
              추가
            </Button>
            <Button
              variant="default"
              onClick={() => {
                if (selection.length === 0) {
                  alert('선택된 약품이 없습니다.');
                  return;
                }
                deleteHandler.open();
              }}
            >
              삭제
            </Button>
          </Flex>
        </Flex>
        <MemoizedDataTable
          tableHeader={mediHeader}
          tableRow={mediRow}
          txt="데이터가 존재하지 않습니다."
        />
        <Space w={1} h={1} mb={rem(25)} />
        <Center>
          <MemoizedPagination
            total={Math.ceil(totalCount / LIMIT_PAGE)}
            value={activePage}
            onChange={setActivePageAndSave}
          />
        </Center>
        <Space w={1} h={1} mb={rem(200)} />
      </Box>
      <Modal
        centered
        title="엑셀 파일을 추가해 주세요."
        opened={excelOpened}
        onClose={excelHandler.close}
      >
        <span className={classes.cautionText}>
          🚨꼭 순위, 진단명(영문), 진단명(한글) 순으로 입력된 엑셀 파일을 업로드
          해주세요.
        </span>
        <Space w={1} h={1} mb={rem(10)} />
        <input type="file" onChange={onGetExcel} />
        <Space w={1} h={1} mb={rem(10)} />
        <Center>
          <Button onClick={onUpload}>데이터 추가하기</Button>
        </Center>
      </Modal>
      <MemoizedDeleteModal
        opened={deleteOpened}
        onClose={deleteHandler.close}
        onDelete={onDeleteMedi}
      />
    </Layout>
  );
};

const useStyles = createStyles(() => ({
  buttonList: {
    paddingBlock: rem(10),
  },
  cautionText: {
    color: 'red',
    fontSize: rem(12),
  },
}));

export default Medication;
