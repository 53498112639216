import {
  BackgroundImage,
  Flex,
  Image,
  Space,
  UnstyledButton,
  createStyles,
  rem,
} from '@mantine/core';

import MainBanner from '../assets/main-banner.jpg';
import SectionBanner from '../assets/section-banner.jpg';
import ApplicationBanner from '../assets/application-app.jpg';
import AppUI from '../assets/app-ui.jpg';

import { Layout } from 'src/components';
import classnames from 'classnames';
import { BrowserView, MobileView } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';

const Home = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();

  return (
    <Layout tabs={false}>
      <BrowserView>
        {/* 메인 배너 */}
        <BackgroundImage
          miw={rem(390)}
          mih={rem(670)}
          src={MainBanner}
          style={{
            zIndex: -2,
            position: 'relative',
          }}
        >
          <Flex
            justify={'center'}
            align={'center'}
            className={classes.mainContents1}
          >
            <Flex direction={'column'} gap={rem(50)}>
              <Flex justify={'center'} direction={'column'} gap={rem(15)}>
                <span className={classes.mainContentsTitle}>BENCH STUDIO</span>
                <span className={classes.mainContentsSub1}>
                  환자 맞춤형 의료 교육 영상 콘텐츠 전문 제작사
                </span>
              </Flex>
              <Flex
                justify={'center'}
                direction={'column'}
                className={classes.mainContentsSub2}
                gap={rem(30)}
              >
                <span className={classes.emphasis}>
                  영상 매체의 발달과 함께 의료 교육 콘텐츠가 많이 제작되고
                  있지만 검증된 내용의 신뢰할 수있는 영상은 제한적입니다.
                </span>
                <span>
                  대형 병원에서 다양한 임상 경험을 보유한 직원들로 구성된 벤치
                  스튜디오에서, <br />
                  검증된 의료 정보를 토대로{' '}
                  <strong className={classes.emphasis}>
                    진료 프로세스에 따른 양질의 환자 맞춤형 영상 콘텐츠
                  </strong>
                  를제작 및 제공하여
                  <br />
                  <strong className={classes.emphasis}>
                    신뢰에 기반한 효율적인 진료 문화 정착
                  </strong>
                  에 앞장서겠습니다!
                </span>
              </Flex>
            </Flex>
          </Flex>
        </BackgroundImage>
        <Space mb={rem(100)} />
        {/* Service */}
        {/* <Flex direction={'column'} className={classes.mainContents2}>
          <Flex direction={'column'} gap={rem(10)}>
            <span
              className={classnames(
                classes.emphasis,
                classes.appText,
                classes.colorEmphasis
              )}
            >
              Service
            </span>
            <span className={classes.appText}>벤치 스튜디오와 함께 하세요</span>
            <span className={classes.appTitle}>대표 서비스</span>
          </Flex>
          <Space mb={rem(30)} />
          <Flex direction={'column'} gap={rem(25)}>
            <span className={classes.appDescription}>
              진료 전 기다리는 동안 미리 만나는 주치의, 영상기반 인공지능 질환
              교육 플랫폼{' '}
              <strong className={classes.colorEmphasis}>닥터벤(Dr.BEN)</strong>.
            </span>
            <span className={classes.appDescription}>
              AI 알고리즘을 기반으로 환자의 질환정보, 검사단계, 처방내역,
              치료과정에 따라 환자 맞춤형 의료 교육영상을 제공하는
              플랫폼(어플리케이션) 입니다.
            </span>
          </Flex>
          <Space mb={rem(30)} />
          <Image
            src={AppUI}
            height={rem(620)}
            styles={{
              image: {
                maxWidth: rem(1280),
                minWidth: rem(390),
                borderRadius: rem(16),
              },
            }}
          />
        </Flex>
        <Space mb={rem(100)} /> */}
        <Flex align={'center'} className={classes.mainContents2}>
          <Flex direction={'column'} gap={rem(10)}>
            <span
              className={classnames(
                classes.emphasis,
                classes.appText,
                classes.colorEmphasis
              )}
            >
              Service
            </span>
            <span className={classes.appText}>벤치 스튜디오와 함께 하세요</span>
            <span className={classes.appTitle}>대표 서비스</span>
          </Flex>
        </Flex>
        <Space mb={rem(30)} />
        <BackgroundImage
          miw={rem(390)}
          mih={rem(170)}
          src={SectionBanner}
          style={{
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          className={classes.sectionBackground}
        >
          <Flex
            className={classes.sectionBox}
            justify={'space-between'}
            align={'center'}
            gap={rem(10)}
          >
            <span className={classes.aiText}>
              진료 전 환자가 미리 학습한 영상을 AI 딥페이크를 활용하여 제작해
              보세요
            </span>
            <UnstyledButton
              onClick={() => navigate('/service/ai-video-production')}
              className={classes.button}
            >
              <span>AI 영상 제작하기</span>
            </UnstyledButton>
          </Flex>
        </BackgroundImage>
        <Space mb={rem(50)} />
        <BackgroundImage
          mah={rem(215)}
          miw={rem(390)}
          mih={rem(170)}
          src={ApplicationBanner}
          style={{
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          className={classnames(
            classes.sectionBackground,
            classes.sectionBackgroundPosition
          )}
        >
          <Flex
            className={classes.sectionBox}
            justify={'space-between'}
            align={'center'}
            gap={rem(10)}
          >
            <span className={classes.aiText}>
              나만의 영상 아카이브, 닥터벤 앱과 관련된 모든 것
            </span>
            <UnstyledButton
              onClick={() => navigate('/service/app-introduction')}
              className={classes.button}
            >
              <span>닥터벤 설치 및 둘러보기</span>
            </UnstyledButton>
          </Flex>
        </BackgroundImage>

        <Space mb={rem(100)} />
      </BrowserView>
      <MobileView>
        <Space w={4} h={5} mb={rem(42)} />
        {/* 메인 배너 */}
        <BackgroundImage
          miw={rem(390)}
          mih={rem(510)}
          src={MainBanner}
          style={{
            zIndex: -2,
            position: 'relative',
          }}
          className={classes.m_mainBackgroundPosition}
        >
          <Flex
            justify={'center'}
            align={'center'}
            className={classnames(
              classes.mainContents1,
              classes.m_mainContents1
            )}
          >
            <Flex direction={'column'} gap={rem(20)}>
              <Flex justify={'center'} direction={'column'} gap={rem(15)}>
                <span
                  className={classnames(
                    classes.mainContentsTitle,
                    classes.m_mainContentsTitle
                  )}
                >
                  BENCH STUDIO
                </span>
                <span
                  className={classnames(
                    classes.mainContentsSub1,
                    classes.m_mainContentsSub1
                  )}
                >
                  환자 맞춤형 의료 교육 영상 콘텐츠 전문 제작사
                </span>
              </Flex>
              <Flex
                justify={'center'}
                direction={'column'}
                className={classnames(
                  classes.mainContentsSub2,
                  classes.m_mainContentsSub2
                )}
                gap={rem(30)}
              >
                {/* <span className={classes.emphasis}>
                  영상 매체의 발달과 함께 의료 교육 콘텐츠가 많이 제작되고
                  <br />
                  있지만 검증된 내용의 신뢰할 수있는 영상은 제한적입니다.
                </span>
                <span>
                  대형 병원에서 다양한 임상 경험을 보유한 직원들로 구성된
                  <br /> 벤치 스튜디오에서, 검증된 의료 정보를 토대로
                  <br />
                  <strong className={classes.emphasis}>
                    진료 프로세스에 따른 양질의 환자 맞춤형 영상 콘텐츠
                  </strong>
                  를<br />
                  제작 및 제공하여{' '}
                  <strong className={classes.emphasis}>
                    신뢰에 기반한 효율적인 진료 문화 정착
                  </strong>
                  에<br /> 앞장서겠습니다!
                </span> */}

                <span className={classes.emphasis}>
                  영상 매체의 발달과 함께 의료 교육 콘텐츠가 많이
                  <br className={classes.br02} /> 제작되고
                  <br className={classes.br01} /> 있지만 검증된 내용의 신뢰할
                  수있는 영상은
                  <br className={classes.br02} />
                  제한적입니다.
                </span>
                <span>
                  대형 병원에서 다양한 임상 경험을 보유한
                  <br className={classes.br02} />
                  직원들로 구성된
                  <br className={classes.br01} /> 벤치 스튜디오에서,
                  <br className={classes.br02} />
                  검증된 의료 정보를 토대로
                  <br className={classes.br01} />
                  <strong className={classes.emphasis}>
                    <br className={classes.br02} />
                    양질의 환자 맞춤형 영상 콘텐츠
                  </strong>
                  를 <br className={classes.br01} /> 제작 및 제공하여 &nbsp;
                  <br className={classes.br02} />
                  <strong className={classes.emphasis}>
                    신뢰에 기반한 효율적인 진료 문화 정착
                  </strong>
                  에
                  <br /> 앞장서겠습니다!
                </span>
              </Flex>
            </Flex>
          </Flex>
        </BackgroundImage>
        <Space mb={rem(50)} />
        {/* Application App */}
        {/* <Flex
          direction={'column'}
          className={classnames(classes.mainContents2, classes.m_mainContents2)}
        >
          <Flex direction={'column'} gap={rem(10)}>
            <span
              className={classnames(
                classes.emphasis,
                classes.appText,
                classes.m_appText,
                classes.colorEmphasis
              )}
            >
              Application App
            </span>
            <span className={classnames(classes.appText, classes.m_appText)}>
              벤치에서 만나는 의사,
            </span>
            <span className={classnames(classes.appTitle, classes.m_appTitle)}>
              닥터벤 (Dr.BEN)
            </span>
          </Flex>
          <Space mb={rem(20)} />
          <Flex direction={'column'} gap={rem(25)}>
            <span
              className={classnames(
                classes.appDescription,
                classes.m_appDescription
              )}
            >
              진료 전 기다리는 동안 미리 만나는 주치의,
              <br /> 영상기반 인공지능 질환 교육 플랫폼{' '}
              <strong className={classes.colorEmphasis}>닥터벤(Dr.BEN)</strong>.
            </span>
            <span
              className={classnames(
                classes.appDescription,
                classes.m_appDescription
              )}
            >
              AI 알고리즘을 기반으로 환자의 질환정보, 검사단계,
              <br /> 처방내역, 치료과정에 따라 환자 맞춤형 의료 교육 영상을
              <br /> 제공하는 플랫폼(어플리케이션) 입니다.
            </span>
          </Flex>
          <Space mb={rem(30)} />
        </Flex>
        <Image
          src={AppUI}
          styles={{
            image: {
              minWidth: rem(390),
              minHeight: rem(250),
            },
          }}
        />
        <Space mb={rem(50)} /> */}

        {/* Service */}
        <Flex align={'center'} className={classes.m_mainContents2}>
          <Flex direction={'column'} gap={rem(10)}>
            <span
              className={classnames(
                classes.emphasis,
                classes.appText,
                classes.m_appText,
                classes.colorEmphasis
              )}
            >
              Service
            </span>
            <span className={classnames(classes.appText, classes.m_appText)}>
              벤치 스튜디오와 함께 하세요
            </span>
            <span className={classnames(classes.appTitle, classes.m_appTitle)}>
              대표 서비스
            </span>
          </Flex>
        </Flex>
        <Space mb={rem(30)} />
        <BackgroundImage
          miw={rem(390)}
          mih={rem(200)}
          src={SectionBanner}
          style={{
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          className={classes.sectionBackground}
        >
          <Flex
            className={classes.m_sectionBox}
            align={'space-between'}
            justify={'center'}
            direction={'column'}
            gap={rem(20)}
          >
            <span className={classnames(classes.aiText, classes.m_aiText)}>
              진료 전 환자가 미리 학습한 영상을
              <br /> AI 딥페이크를 활용하여 제작해 보세요
            </span>
            <UnstyledButton
              onClick={() => navigate('/service/ai-video-production')}
              className={classes.button}
            >
              <span>AI 영상 제작하기</span>
            </UnstyledButton>
          </Flex>
        </BackgroundImage>
        <Space mb={rem(50)} />
        <BackgroundImage
          miw={rem(390)}
          mih={rem(200)}
          src={ApplicationBanner}
          style={{
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          className={classnames(
            classes.sectionBackground,
            classes.sectionBackgroundPosition
          )}
        >
          <Flex
            className={classes.m_sectionBox}
            align={'space-between'}
            justify={'center'}
            direction={'column'}
            gap={rem(20)}
          >
            <span className={classnames(classes.aiText, classes.m_aiText)}>
              나만의 영상 아카이브,
              <br /> 닥터벤 앱과 관련된 모든 것
            </span>
            <UnstyledButton
              onClick={() => navigate('/service/app-introduction')}
              className={classes.button}
            >
              <span>닥터벤 설치 및 둘러보기</span>
            </UnstyledButton>
          </Flex>
        </BackgroundImage>
        <Space mb={rem(50)} />
      </MobileView>
    </Layout>
  );
};

const useStyles = createStyles(() => ({
  mainContents1: {
    paddingInline: rem(50),
    height: rem(670),
    ':after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(44, 147, 173, 0.5)',
      zIndex: -1,
    },
  },
  m_mainContents1: {
    height: rem(510),
    paddingInline: rem(30),
    paddingBlock: rem(30),
  },
  sectionBackground: {
    ':before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
  },
  m_mainBackgroundPosition: {
    backgroundSize: 'cover',
    backgroundPosition: '0 -20%',
  },
  sectionBackgroundPosition: {
    backgroundSize: 'cover',
    backgroundPosition: '0 58%',
  },
  sectionBox: {
    zIndex: 1,
    boxSizing: 'border-box',

    // 태블릿
    [`@media (max-width : ${rem(1024)})`]: {
      paddingInline: rem(20),
      paddingBlock: rem(20),
      flexDirection: 'column',
      gap: rem(20),
    },

    // PC
    [`@media (min-width : ${rem(1024)})`]: {
      paddingInline: rem(50),
      paddingBlock: rem(20),
      width: rem(1280),
    },
  },
  m_sectionBox: {
    zIndex: 1,
    boxSizing: 'border-box',
    paddingInline: rem(30),
  },
  aiText: {
    color: 'white',
    fontSize: rem(18),
    fontWeight: 600,
  },
  m_aiText: {
    fontSize: rem(16),
    lineHeight: rem(25),
    paddingLeft: 0,
  },
  button: {
    maxWidth: rem(400),
    minWidth: rem(330),
    height: rem(52),
    border: `${rem(1)} solid #2A93AD`,
    borderRadius: rem(16),
    backgroundColor: 'white',
    color: '#2C93AD',
    textAlign: 'center',
    paddingBlock: rem(14),
    fontSize: rem(18),
    fontWeight: 500,
    lineHeight: rem(25),

    ':hover': {
      backgroundColor: '#2D93AD',
      color: 'white',
    },
  },
  mainContents2: {
    maxWidth: rem(1280),
    marginInline: 'auto',
    paddingInline: rem(30),
  },
  m_mainContents2: {
    paddingInline: rem(30),
  },
  mainContentsTitle: {
    textAlign: 'center',
    fontWeight: 800,
    fontSize: rem(96),
    color: 'white',
  },
  m_mainContentsTitle: {
    fontSize: rem(36),
    color: 'white',
  },
  mainContentsSub1: {
    color: 'white',
    textAlign: 'center',
    fontSize: rem(36),
    wordBreak: 'keep-all',
  },
  m_mainContentsSub1: {
    fontSize: rem(16),
  },
  mainContentsSub2: {
    color: 'white',
    textAlign: 'center',
    fontSize: rem(18),
    lineHeight: rem(25),
    wordBreak: 'keep-all',
  },
  m_mainContentsSub2: {
    fontSize: rem(16),
    wordBreak: 'keep-all',
  },
  emphasis: {
    fontWeight: 700,
  },
  colorEmphasis: {
    color: '#2C93AD',
  },
  appText: {
    fontSize: rem(18),
    fontWeight: 600,
  },
  m_appText: {
    fontSize: rem(16),
  },
  appTitle: {
    fontSize: rem(36),
    fontWeight: 700,
  },
  m_appTitle: {
    fontSize: rem(32),
    fontWeight: 600,
  },
  appDescription: {
    fontSize: rem(18),
    fontWeight: 500,
  },
  m_appDescription: {
    fontSize: rem(16),
    lineHeight: rem(20),
  },
  introduceBox: {
    width: rem(1280),
    paddingInline: rem(30),
    marginInline: 'auto',
  },
  producingGrid: {
    border: '1px solid #CCCCCC',
    borderRadius: rem(16),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: rem(320),
    maxHeight: rem(164),
    paddingInline: rem(60),
    paddingBlock: rem(30),
  },
  producingGridText: {
    fontSize: rem(20),
    fontWeight: 600,
  },
  producingArrowGrid: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingInline: rem(55),
    paddingBlock: rem(30),
  },
  middleArrowBox: {
    padding: 0,
  },
  middleArrow: {
    width: rem(300),
    marginBlock: rem(15),
  },
  arrowDown: {
    transform: 'rotate(90deg)',
  },
  arrowLeft: {
    transform: 'rotate(180deg)',
  },
  priceGrid: {
    border: '1px solid #CCCCCC',
    borderRadius: rem(16),
    paddingInline: rem(20),
    paddingBlock: rem(26),
    minHeight: rem(517),
  },
  priceTitle: {
    fontWeight: 600,
    fontSize: rem(36),
    textAlign: 'center',
  },
  priceCondition: {
    fontSize: rem(18),
    fontWeight: 600,
    listStyle: 'inside',
  },
  priceBox: {
    minHeight: rem(73),
  },
  priceText: {
    display: 'block',
    fontWeight: 600,
  },
  originPrice: {
    fontSize: rem(18),
    color: '#CCCCCC',
    textDecoration: 'line-through',
    lineHeight: rem(25),
  },
  discountPrice: {
    fontSize: rem(32),
    lineHeight: rem(45),
  },
  addPriceBox: {
    border: '1px solid #CCCCCC',
    borderRadius: rem(16),
    marginInline: rem(80),
    paddingBlock: rem(50),
    paddingInline: rem(57),
  },
  br01: {
    display: 'block',
    [`@media (max-width: ${rem(450)})`]: {
      display: 'none',
    },
  },
  br02: {
    display: 'none',
    [`@media (max-width: ${rem(450)})`]: {
      display: 'block',
    },
  },
}));

export default Home;
