const Toggle = () => {
  const toggleRow = (id: string, setSelection: any) =>
    setSelection((current: any) =>
      current.includes(id)
        ? current.filter((item: any) => item !== id)
        : [...current, id]
    );

  const toggleAll = (data: any, setSelection: any, dynamicKey: string) =>
    setSelection((current: any) =>
      current.length === data.length
        ? []
        : data.map((item: any) => item[dynamicKey])
    );

  return { toggleRow, toggleAll };
};

export default Toggle;
