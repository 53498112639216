import {
  Box,
  Button,
  Flex,
  Input,
  LoadingOverlay,
  Select,
  Space,
  Stack,
  Title,
  UnstyledButton,
  createStyles,
  rem,
} from '@mantine/core';
import { useNavigate, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { IconArrowLeft } from '@tabler/icons-react';
import { Layout } from 'src/components';
import { client } from 'src';
import { getPartnerModel, listDepartmentModels } from 'src/graphql/queries';
import { updatePartnerModel } from 'src/graphql/mutations';

const PartnerEdit = () => {
  const { classes } = useStyles();
  const { id } = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [departments, setDepartments] = useState<
    { label: string; value: string }[]
  >([]);

  const [partner, setPartner] = useState({
    name: '',
    phone: '',
    dept: '',
  });

  useEffect(() => {
    const fetchData = async () => {
      await onGetPartner();
      await onGetDepartments();
    };
    fetchData();
  }, []);

  const onGetPartner = async () => {
    try {
      const findItem = await client.graphql({
        query: getPartnerModel,
        variables: { id: String(id) },
      });
      const item = findItem.data.getPartnerModel;

      if (!item) {
        alert('데이터를 불러오는데 실패했습니다. 다시 시도해 주세요.');
        return;
      }

      setPartner({
        name: item.name,
        phone: `0${item.username.slice(3, undefined)}`,
        dept: item.departmentmodelID,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const onGetDepartments = async () => {
    const result = await client.graphql({ query: listDepartmentModels });
    const list = result.data.listDepartmentModels.items;
    const selectList = list.map((data) => {
      const { id, hospital, dept } = data;
      return {
        label: `${hospital} - ${dept}`,
        value: id,
      };
    });
    setDepartments(selectList);
  };

  const onEditPartner = async () => {
    const { name, phone, dept } = partner;

    if (name.length == 0 || phone.length == 0 || dept.length == 0) {
      alert('필수 항목이 입력되지 않았습니다. 입력해 주세요.');
      return;
    }
    try {
      setIsLoading(true);
      const result = await client.graphql({
        query: updatePartnerModel,
        variables: {
          input: {
            id: String(id),
            username: `+82${phone.slice(1, undefined)}`,
            name: partner.name,
            departmentmodelID: partner.dept,
          },
        },
      });

      if (result.errors && result.errors.length > 0) {
        alert('데이터 수정에 실패했습니다.');
        return;
      }

      setTimeout(() => {
        setIsLoading(false);
        navigate('/admin/partner');
      }, 1800);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Layout>
      <LoadingOverlay style={{ position: 'fixed' }} visible={isLoading} />
      <Space w={1} h={1} mb={rem(50)} />
      <Flex direction={'column'} justify={'center'} align={'center'} h={'100%'}>
        <Box className={classes.form}>
          <Flex justify={'space-between'}>
            <UnstyledButton onClick={() => navigate('/admin/partner')}>
              <IconArrowLeft />
            </UnstyledButton>
            <Title order={4}>
              <span>파트너 정보 수정</span>
            </Title>
            <Button onClick={onEditPartner}>수정</Button>
          </Flex>
          <Stack>
            <Box>
              <Input.Wrapper label="이름" withAsterisk>
                <Input
                  value={partner.name}
                  onChange={(event) =>
                    setPartner({ ...partner, name: event.target.value })
                  }
                />
              </Input.Wrapper>
            </Box>
            <Box>
              <Input.Wrapper withAsterisk label="전화번호">
                <Input
                  placeholder="'-'를 제외하고 입력해 주세요."
                  value={partner.phone}
                  onChange={(event) =>
                    setPartner({ ...partner, phone: event.target.value })
                  }
                />
              </Input.Wrapper>
            </Box>
            <Box>
              <Select
                label="부서"
                withAsterisk
                value={partner.dept}
                data={departments}
                onChange={(value) =>
                  setPartner({ ...partner, dept: value || '' })
                }
              />
            </Box>
          </Stack>
        </Box>
      </Flex>
    </Layout>
  );
};

const useStyles = createStyles(() => ({
  form: {
    width: '80%',
    maxWidth: rem(500),
  },
}));

export default PartnerEdit;
