import {
  Box,
  Button,
  Center,
  Flex,
  Space,
  Title,
  UnstyledButton,
  createStyles,
  rem,
} from '@mantine/core';
import { randomId } from '@mantine/hooks';
import { IconArrowLeft } from '@tabler/icons-react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { client } from 'src';
import { PatientModel, VideoModel } from 'src/API';
import { DataTable, Layout, TableHeader, TableRow } from 'src/components';
import {
  getDiagnosisModel,
  getMedicationModel,
  getPartnerModel,
  getPatientModel,
  getVideoModel,
  listVideoModels,
} from 'src/graphql/queries';
import { TableHeaderType } from 'src/types/TableHeaderParams';
import { Toggle, onCheckUser } from 'src/utils';

const headerData: TableHeaderType[] = [
  { key: randomId(), label: '비디오 제목' },
  { key: randomId(), label: '시청 시간' },
  { key: randomId(), label: '총 시간' },
  { key: randomId(), label: '좋아요 누른 시간' },
  { key: randomId(), label: '시청 완료 여부' },
];

const PatientDetail = () => {
  const { id } = useParams();
  const { classes, cx } = useStyles();
  const { toggleAll, toggleRow } = Toggle();

  const navigate = useNavigate();

  const [partners, setPartners] = useState<string[]>([]);
  const [patient, setPatient] = useState<PatientModel>();
  const [medi, setMedi] = useState<string[]>([]);
  const [diag, setDiag] = useState<string[]>([]);

  const [watchedVideo, setWatchedVideo] = useState([]);
  const [totalVideo, setTotalVideo] = useState<VideoModel[]>([]);

  const [pointedVideo, setPointedVideo] = useState<string[]>([]);

  useEffect(() => {
    const userCheck = onCheckUser();
    if (!userCheck) {
      navigate('/login');
    }

    const onFetch = async () => {
      await onGetPatient();
    };
    onFetch();
  }, []);

  useEffect(() => {
    console.log(partners);
  }, [partners]);

  const onGetPatient = async () => {
    try {
      const result = await client.graphql({
        query: getPatientModel,
        variables: {
          id: String(id),
        },
      });
      const item = result.data.getPatientModel;
      if (item) {
        setPatient(item);
        setWatchedVideo(
          item.watched_video_ids ? JSON.parse(item.watched_video_ids || '') : []
        );

        await onGetPartners(
          item.partner_ids ? JSON.parse(item.partner_ids || '') : []
        );
        await onGetMedi(
          item.medication_ids ? JSON.parse(item.medication_ids || '') : []
        );
        await onGetDiag(
          item.diagnosis_ids ? JSON.parse(item.diagnosis_ids || '') : []
        );
        await onGetPointedVideo(
          item.pointed_video_ids ? JSON.parse(item.pointed_video_ids || '') : []
        );

        await onGetVideo();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onGetPartners = async (partnerList: string[]) => {
    partnerList.map(async (partner) => {
      const newArr: any[] = [];
      const result = await client.graphql({
        query: getPartnerModel,
        variables: {
          id: String(partner),
        },
      });
      const item = result.data.getPartnerModel?.name;
      if (item) {
        newArr.push(item);

        setPartners((prev) => [...prev, ...newArr]);
      }
    });
  };

  const onGetMedi = async (mediList: string[]) => {
    mediList.map(async (medi) => {
      const newArr: any[] = [];
      const result = await client.graphql({
        query: getMedicationModel,
        variables: {
          id: String(medi),
        },
      });
      const item = result.data.getMedicationModel?.name_eng;
      if (item) {
        newArr.push(item);

        setMedi((prev) => [...prev, ...newArr]);
      }
    });
  };

  const onGetDiag = async (diagList: string[]) => {
    diagList.map(async (diag) => {
      const newArr: any[] = [];
      const result = await client.graphql({
        query: getDiagnosisModel,
        variables: {
          id: String(diag),
        },
      });
      const item = result.data.getDiagnosisModel?.name_eng;
      if (item) {
        newArr.push(item);

        setDiag((prev) => [...prev, ...newArr]);
      }
    });
  };

  const onGetPointedVideo = async (pointedVideo: string[]) => {
    pointedVideo.map(async (video) => {
      const newArr: any[] = [];
      const result = await client.graphql({
        query: getVideoModel,
        variables: {
          id: String(video),
        },
      });
      const item = result.data.getVideoModel?.title;
      if (item) {
        newArr.push(item);

        setPointedVideo((prev) => [...prev, ...newArr]);
      }
    });
  };

  const onGetVideo = async () => {
    const result = await client.graphql({ query: listVideoModels });
    const list = result.data.listVideoModels.items;
    setTotalVideo(list);
  };

  const watchedHeader = TableHeader({
    headers: headerData,
    data: watchedVideo,
    toggleAll,
    dynamicKey: 'id',
    checkbox: false,
  });

  const watchedRow = TableRow({
    toggleRow,
    data: watchedVideo,
    dynamicKey: 'id',
    checkbox: false,
    tableRows: (row) => (
      <>
        <td>{totalVideo.map((video) => video.id === row.id && video.title)}</td>
        <td>{row.current}초</td>
        <td>{row.total}초</td>
        <td>{row.checked ? row.checked : '-'}</td>
        <td>
          {row.done ? '시청 완료' : row.current > 0 ? '시청중' : '미시청'}
        </td>
      </>
    ),
  });

  return (
    <Layout>
      <Space w={1} h={1} mb={rem(25)} />
      <Center>
        <Box style={{ width: '100%', maxWidth: rem(1190) }}>
          <Flex justify={'space-between'}>
            <UnstyledButton
              onClick={() => {
                navigate(-1);
              }}
            >
              <IconArrowLeft />
            </UnstyledButton>
            <Button onClick={() => navigate(`/admin/patient/edit/${id}`)}>
              수정
            </Button>
          </Flex>
          <Space w={1} h={1} mb={rem(25)} />
          <Title order={4}>
            <span>[ {patient?.name}님의 상세 정보 ]</span>
          </Title>
          <Space w={1} h={1} mb={rem(25)} />
          <Box>
            <span>주치의 : </span>
            <span>
              {partners.map((partner) => (
                <span key={partner}>{partner} / </span>
              ))}
            </span>
          </Box>
          <Space w={1} h={1} mb={rem(25)} />
          <Box>
            <span>약품 : </span>
            <span>
              {medi.length !== 0 ? (
                medi.map((item) => <span key={item}>{item} / </span>)
              ) : (
                <span>선택된 약품이 없습니다.</span>
              )}
            </span>
          </Box>
          <Space w={1} h={1} mb={rem(25)} />
          <Box>
            <span>진단 : </span>
            <span>
              {diag.length !== 0 ? (
                diag.map((item) => <span key={item}>{item} / </span>)
              ) : (
                <span>진단 내역이 없습니다.</span>
              )}
            </span>
          </Box>
          <Space w={1} h={1} mb={rem(25)} />
          <Title order={5}>
            <span>[ 환자에게 추천한 비디오 ]</span>
          </Title>
          <Space w={1} h={1} mb={rem(25)} />
          <Flex direction={'column'}>
            {pointedVideo.length !== 0 ? (
              pointedVideo.map((video) => <span key={video}>- {video} </span>)
            ) : (
              <span>추천된 비디오가 없습니다.</span>
            )}
          </Flex>
          <Space w={1} h={1} mb={rem(25)} />
          <Title order={5}>
            <span>[ 비디오 시청 기록 ]</span>
          </Title>
          <Space w={1} h={1} mb={rem(25)} />
          <DataTable
            tableHeader={watchedHeader}
            tableRow={watchedRow}
            txt="데이터가 존재하지 않습니다."
          />
          <Space w={1} h={1} mb={rem(200)} />
        </Box>
      </Center>
    </Layout>
  );
};

const useStyles = createStyles(() => ({
  buttonList: {
    paddingBlock: rem(10),
  },
}));

export default PatientDetail;
