import {
  BackgroundImage,
  Box,
  Checkbox,
  Container,
  Flex,
  Image,
  Space,
  TextInput,
  Textarea,
  UnstyledButton,
  createStyles,
  rem,
} from '@mantine/core';
import { BrowserView, MobileView } from 'react-device-detect';
import { Layout } from 'src/components';
import Logo from '../assets/black-logo.png';
import KakaoIcon from '../assets/kakkao_ch_icon.svg';
import { useEffect, useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import MainBanner from '../assets/contact-main-banner.jpg';

interface MsgInfoType {
  name: string;
  phone: string;
  email: string;
  message: string;
  isPersonalInfoCollected: boolean;
  isThirdPartyConsentGiven: boolean;
}

const initialMsgInfo = {
  name: '',
  phone: '',
  email: '',
  message: '',
  isPersonalInfoCollected: false,
  isThirdPartyConsentGiven: false,
};

const Contact = () => {
  const { classes } = useStyles();
  const formRef = useRef<HTMLFormElement>(null);

  const [msgInfo, setMsgInfo] = useState<MsgInfoType>(initialMsgInfo);

  const placeholder = `1) 파트너 계정 요청 시 - 성함 / 전화번호 / 근무지명을 작성하여 문의해주세요.   
  (예) 홍길동 / 010-1234-5678 / 계명대학교 동산병원 심장내과, 파트너 계정 등록 요청합니다.

  2) 영상 제작 의뢰 시 - 성함 / 근무지명 / 제작 의뢰 사항을 간략하게 작성하여 문의해주세요.  
  (예) 홍길동 / 홍길동 심장내과의원 / 우리 병원에서 환자들에게 자주 처방해주는 약제에 대한 설명 내용을 AI 영상으로 제작 의뢰합니다.
  
  3) 기타 문의사항은 자유롭게 문의해주세요.`;

  const onSendEmail = () => {
    const phoneReg = /^01([0|1|6|7|8|9])-([0-9]{3,4})-([0-9]{4})$/;
    const emailReg = /^[a-zA-Z0-9+-\\_.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

    const {
      name,
      phone,
      email,
      message,
      isPersonalInfoCollected,
      isThirdPartyConsentGiven,
    } = msgInfo;

    if (
      name.length === 0 ||
      phone.length === 0 ||
      email.length === 0 ||
      message.length === 0
    ) {
      alert('필수사항을 모두 입력해 주세요.');
      return;
    }

    if (!phoneReg.test(phone)) {
      alert('연락처를 올바르게 입력해 주세요.');
      return;
    }

    if (!emailReg.test(email)) {
      alert('이메일 형식에 맞게 입력해 주세요.');
      return;
    }
    if (!isPersonalInfoCollected || !isThirdPartyConsentGiven) {
      alert('필수 동의 항목에 동의하지 않았습니다.');
      return;
    }
    const SERVICE_ID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
    const TEMPLATE_ID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
    const PUBLIC_API_KEY = process.env.REACT_APP_EMAILJS_PUBLIC_API_KEY;

    if (formRef.current && SERVICE_ID && TEMPLATE_ID && PUBLIC_API_KEY) {
      emailjs
        .sendForm(SERVICE_ID, TEMPLATE_ID, formRef.current, {
          publicKey: PUBLIC_API_KEY,
        })
        .then(() => {
          console.log('전송 성공');
          setMsgInfo(initialMsgInfo);
          alert('전송되었습니다.');
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      console.log('실패');
    }
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  return (
    <Layout tabs={false}>
      <BrowserView>
        <BackgroundImage
          mah={rem(670)}
          miw={rem(390)}
          mih={rem(510)}
          src={MainBanner}
          style={{
            zIndex: -2,
            position: 'relative',
          }}
          className={classes.sectionBackgroundPosition}
        >
          <Flex
            className={classes.mainContents01}
            direction={'column'}
            justify={'center'}
            align={'center'}
          >
            <Image width={rem(1020)} height={rem(294)} src={Logo} />
            <span className={classes.contactText}>CONTACT</span>
            <span className={classes.contactSubText}>문의하기</span>
          </Flex>
        </BackgroundImage>

        <form ref={formRef}>
          <Flex className={classes.b_form} direction={'column'} gap={rem(50)}>
            <span className={classes.contactTitle}>문의하기</span>
            <Flex direction={'column'} gap={rem(20)}>
              <TextInput
                name="name"
                label="성함"
                required
                value={msgInfo.name}
                onChange={(event) =>
                  setMsgInfo((prev) => ({ ...prev, name: event.target.value }))
                }
              />
              <TextInput
                label="연락처"
                placeholder="'-'를 포함하여 입력해 주세요"
                required
                name="phone"
                value={msgInfo.phone}
                onChange={(event) =>
                  setMsgInfo((prev) => ({ ...prev, phone: event.target.value }))
                }
              />
              <TextInput
                label="이메일"
                name="email"
                required
                value={msgInfo.email}
                onChange={(event) =>
                  setMsgInfo((prev) => ({ ...prev, email: event.target.value }))
                }
              />
              <Textarea
                styles={{
                  input: {
                    height: rem(200),
                  },
                }}
                name="message"
                label="문의사항"
                required
                value={msgInfo.message}
                onChange={(event) =>
                  setMsgInfo((prev) => ({
                    ...prev,
                    message: event.target.value,
                  }))
                }
                placeholder={placeholder}
              />
              <Flex direction={'column'} gap={rem(10)}>
                <span className={classes.label}>
                  개인정보 수집 및 이용 동의{' '}
                  <strong className={classes.requiredLabel}>*</strong>
                </span>
                <Box className={classes.termsBox}></Box>
                <Checkbox
                  label="개인정보 수집 및 이용에 동의합니다."
                  checked={msgInfo.isPersonalInfoCollected}
                  onChange={(event) =>
                    setMsgInfo((prev) => ({
                      ...prev,
                      isPersonalInfoCollected: event.target.checked,
                    }))
                  }
                />
              </Flex>
              <Flex direction={'column'} gap={rem(10)}>
                <span className={classes.label}>
                  개인정보 제3자 제공 동의{' '}
                  <strong className={classes.requiredLabel}>*</strong>
                </span>
                <Box className={classes.termsBox}></Box>
                <Checkbox
                  label="개인정보 제3자 제공에 대해 동의합니다."
                  checked={msgInfo.isThirdPartyConsentGiven}
                  onChange={(event) =>
                    setMsgInfo((prev) => ({
                      ...prev,
                      isThirdPartyConsentGiven: event.target.checked,
                    }))
                  }
                />
              </Flex>
            </Flex>
            <UnstyledButton
              onClick={() => {
                if (!confirm('문의하시겠습니까?')) {
                  return;
                }
                onSendEmail();
              }}
              className={classes.businessSectorButton}
            >
              <span>문의하기</span>
            </UnstyledButton>
          </Flex>
        </form>

        <div className={classes.kakaoBanner}>
          <Container size={rem(924)}>
            <div className={classes.kakaoBannerInner}>
              <Flex align={'center'} gap={rem(20)}>
                <Image src={KakaoIcon} width={rem(47)} height={rem(50)} />
                <span className={classes.kakaoBannerTitle}>
                  카카오톡 채널 문의
                </span>
              </Flex>
              <span className={classes.kakaoBannerText}>
                카카오톡 채널에서 ‘닥터벤’을 검색하여 채팅 문의
              </span>
            </div>
          </Container>
        </div>
      </BrowserView>
      <MobileView>
        <Space mb={rem(50)} />
        <BackgroundImage
          miw={rem(390)}
          mih={rem(430)}
          src={MainBanner}
          style={{
            zIndex: -2,
            position: 'relative',
          }}
          className={classes.m_sectionBackgroundPosition}
        >
          <Flex
            className={classes.m_mainContents01}
            direction={'column'}
            justify={'center'}
            align={'center'}
          >
            <Image width={rem(370)} height={rem(164)} src={Logo} />
            <span className={classes.contactText}>CONTACT</span>
            <span className={classes.contactSubText}>문의하기</span>
          </Flex>
        </BackgroundImage>
        <form ref={formRef}>
          <Flex className={classes.m_form} direction={'column'} gap={rem(50)}>
            <span className={classes.m_contactTitle}>문의하기</span>
            <Flex direction={'column'} gap={rem(20)}>
              <TextInput
                name="name"
                label="성함"
                required
                value={msgInfo.name}
                onChange={(event) =>
                  setMsgInfo((prev) => ({ ...prev, name: event.target.value }))
                }
              />
              <TextInput
                name="phone"
                label="연락처"
                placeholder="'-'를 포함하여 입력해 주세요"
                required
                value={msgInfo.phone}
                onChange={(event) =>
                  setMsgInfo((prev) => ({ ...prev, phone: event.target.value }))
                }
              />
              <TextInput
                name="email"
                label="이메일"
                required
                value={msgInfo.email}
                onChange={(event) =>
                  setMsgInfo((prev) => ({ ...prev, email: event.target.value }))
                }
              />
              <Textarea
                name="message"
                placeholder={placeholder}
                styles={{
                  input: {
                    height: rem(200),
                  },
                }}
                label="문의사항"
                required
                value={msgInfo.message}
                onChange={(event) =>
                  setMsgInfo((prev) => ({
                    ...prev,
                    message: event.target.value,
                  }))
                }
              />
              <Flex direction={'column'} gap={rem(10)}>
                <span className={classes.label}>
                  개인정보 수집 및 이용 동의{' '}
                  <strong className={classes.requiredLabel}>*</strong>
                </span>
                <Box className={classes.m_termsBox}></Box>
                <Checkbox
                  label="개인정보 수집 및 이용에 동의합니다."
                  checked={msgInfo.isPersonalInfoCollected}
                  onChange={(event) =>
                    setMsgInfo((prev) => ({
                      ...prev,
                      isPersonalInfoCollected: event.target.checked,
                    }))
                  }
                />
              </Flex>
              <Flex direction={'column'} gap={rem(10)}>
                <span className={classes.label}>
                  개인정보 제3자 제공 동의{' '}
                  <strong className={classes.requiredLabel}>*</strong>
                </span>
                <Box className={classes.m_termsBox}></Box>
                <Checkbox
                  label="개인정보 제3자 제공에 대해 동의합니다."
                  checked={msgInfo.isThirdPartyConsentGiven}
                  onChange={(event) =>
                    setMsgInfo((prev) => ({
                      ...prev,
                      isThirdPartyConsentGiven: event.target.checked,
                    }))
                  }
                />
              </Flex>
            </Flex>
            <UnstyledButton
              onClick={() => {
                if (!confirm('문의하시겠습니까?')) {
                  return;
                }
                onSendEmail();
              }}
              className={classes.businessSectorButton}
            >
              문의하기
            </UnstyledButton>
          </Flex>
        </form>
        <div className={classes.m_kakaoBanner}>
          <Flex direction={'column'} align={'center'} gap={rem(20)}>
            <Image src={KakaoIcon} width={rem(47)} height={rem(50)} />
            <span className={classes.m_kakaoBannerTitle}>
              카카오톡 채널 문의
            </span>
            <span className={classes.m_kakaoBannerText}>
              카카오톡 채널에서 ‘닥터벤’을 검색하여 채팅 문의
            </span>
          </Flex>
        </div>
      </MobileView>
    </Layout>
  );
};

const useStyles = createStyles(() => ({
  mainContents01: {
    paddingTop: rem(84),
    paddingBottom: rem(103),
    paddingInline: rem(50),
    height: rem(600),
    boxSizing: 'border-box',
    color: '#2C93AD',
    ':after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(234, 244, 247, 0.7)',
      zIndex: -1,
    },
  },
  m_mainContents01: {
    padding: 0,
    height: rem(430),
    color: '#2C93AD',
    ':after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(234, 244, 247, 0.7)',
      zIndex: -1,
    },
  },
  sectionBackgroundPosition: {
    backgroundSize: 'cover',
    backgroundPosition: '0 45%',
  },
  m_sectionBackgroundPosition: {
    backgroundSize: 'cover',
    backgroundPosition: '50%T 75%',
  },
  contactText: {
    fontSize: rem(36),
    fontWeight: 700,
    marginTop: rem(35),
  },
  contactSubText: {
    marginTop: rem(20),
    fontSize: rem(18),
    fontWeight: 500,
  },
  b_form: {
    paddingBlock: `${rem(100)} ${rem(42)}`,
    width: rem(640),
    marginInline: 'auto',
  },
  m_form: {
    paddingInline: rem(30),
    paddingBlock: rem(60),
  },
  contactTitle: {
    fontSize: rem(48),
    fontWeight: 800,
    textAlign: 'center',
  },
  inquiryTypeBox: {
    border: `${rem(1)} solid #CCCCCC`,
    paddingBlock: rem(10),
    paddingInline: rem(10),
    borderRadius: rem(5),
  },
  m_contactTitle: {
    fontSize: rem(36),
    fontWeight: 800,
    textAlign: 'center',
  },
  businessSectorButton: {
    width: '100%',
    border: `${rem(1)} solid #2A93AD`,
    borderRadius: rem(16),
    color: '#2C93AD',
    textAlign: 'center',
    paddingBlock: rem(14),
    fontSize: rem(18),
    fontWeight: 500,
    lineHeight: rem(25),

    ':hover': {
      backgroundColor: '#2D93AD',
      color: 'white',
    },
  },
  label: {
    fontSize: rem(14),
    fontWeight: 500,
  },
  requiredLabel: {
    color: '#fa5252',
  },
  termsBox: {
    width: rem(640),
    height: rem(190),
    backgroundColor: '#d9d9d9',
  },
  m_termsBox: {
    width: 'inherit ',
    height: rem(190),
    backgroundColor: '#d9d9d9',
  },

  kakaoBanner: {
    paddingBlock: rem(50),
    backgroundColor: '#F2D549',
    marginBottom: rem(100),
  },
  kakaoBannerInner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [`@media (max-width: ${rem(850)})`]: {
      flexDirection: 'column',
      gap: rem(24),
    },
  },
  m_kakaoBanner: {
    paddingBlock: rem(63),
    backgroundColor: '#F2D549',
    marginBottom: rem(50),
  },
  kakaoBannerTitle: {
    fontSize: rem(40),
    fontWeight: 700,
    letterSpacing: rem(-2),
  },
  kakaoBannerText: {
    fontSize: rem(25),
    fontWeight: 500,
    letterSpacing: rem(-2),
  },
  m_kakaoBannerTitle: {
    fontSize: rem(24),
    fontWeight: 700,
    letterSpacing: rem(-2),
  },
  m_kakaoBannerText: {
    fontSize: rem(16),
    fontWeight: 500,
  },
}));

export default Contact;
