import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { MantineProvider } from '@mantine/core';
import {
  Terms,
  Home,
  Privacy,
  PartnerEdit,
  PartnerAdd,
  DepartmentAdd,
  DepartmentEdit,
  MedicationAdd,
  MedicationEdit,
  DiagnosisAdd,
  DiagnosisEdit,
  VideoAdd,
  VideoEdit,
  Login,
  PatientDetail,
  PatientEdit,
  Patient,
  Partner,
  Department,
  Medication,
  Diagnosis,
  Video,
  About,
  Contact,
  AboutDetail,
  Service,
  Pricing,
  AIVideoProduction,
  AppIntroduction,
} from './pages';

function App() {
  return (
    <div className="App">
      <MantineProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/about" element={<About />} />
            <Route path="/about/detail" element={<AboutDetail />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/service" element={<Service />} />
            <Route
              path="/service/ai-video-production"
              element={<AIVideoProduction />}
            />
            <Route
              path="/service/app-introduction"
              element={<AppIntroduction />}
            />

            {/* 환자 */}
            <Route path="/admin/patient" element={<Patient />} />
            <Route
              path="/admin/patient/detail/:id"
              element={<PatientDetail />}
            />
            <Route path="/admin/patient/edit/:id" element={<PatientEdit />} />

            {/* 파트너 */}
            <Route path="/admin/partner" element={<Partner />} />
            <Route path="/admin/partner/add" element={<PartnerAdd />} />
            <Route path="/admin/partner/edit/:id" element={<PartnerEdit />} />

            {/* 부서 */}
            <Route path="/admin/department" element={<Department />} />
            <Route path="/admin/department/add" element={<DepartmentAdd />} />
            <Route
              path="/admin/department/edit/:id"
              element={<DepartmentEdit />}
            />

            {/* 약품 */}
            <Route path="/admin/medication" element={<Medication />} />
            <Route path="/admin/medication/add" element={<MedicationAdd />} />
            <Route
              path="/admin/medication/edit/:id"
              element={<MedicationEdit />}
            />

            {/* 진단 */}
            <Route path="/admin/diagnosis" element={<Diagnosis />} />
            <Route path="/admin/diagnosis/add" element={<DiagnosisAdd />} />
            <Route
              path="/admin/diagnosis/edit/:id"
              element={<DiagnosisEdit />}
            />

            {/* 비디오 */}
            <Route path="/admin/video" element={<Video />} />
            <Route path="/admin/video/add" element={<VideoAdd />} />
            <Route path="/admin/video/edit/:id" element={<VideoEdit />} />
          </Routes>
        </BrowserRouter>
      </MantineProvider>
    </div>
  );
}

export default App;
