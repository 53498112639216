import { DataTable, Layout, TableHeader, TableRow } from '../../components';
import {
  Box,
  Button,
  Center,
  Flex,
  Input,
  LoadingOverlay,
  Modal,
  Pagination,
  Space,
  Stack,
  Title,
  UnstyledButton,
  createStyles,
  rem,
} from '@mantine/core';
import { randomId, useDisclosure } from '@mantine/hooks';
import { IconEdit, IconQrcode } from '@tabler/icons-react';

import { client } from 'src';
import { TableHeaderType } from 'src/types/TableHeaderParams';
import DeleteModal from '../../components/DeleteModal';
import { listDepartmentModels, searchPartnerModels } from 'src/graphql/queries';
import { onDeletePartnerModel } from 'src/graphql/subscriptions';
import {
  PartnerModel,
  SearchablePartnerModelSortableFields,
  SearchableSortDirection,
} from 'src/API';
import { deletePartnerModel } from 'src/graphql/mutations';

import { memo, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Toggle from '../../utils/Toggle';
import { onCheckUser } from 'src/utils';
import { retrievePageNumber, savePageNumber } from 'src/utils/localStoragePage';
import { LIMIT_PAGE } from 'src/constants';
import { QRCodeCanvas } from 'qrcode.react';
import { useReactToPrint } from 'react-to-print';

const headerData: TableHeaderType[] = [
  { key: randomId(), label: '부서' },
  { key: randomId(), label: '이름' },
  { key: randomId(), label: '전화번호' },
  { key: randomId(), label: '포인트' },
  { key: randomId(), label: 'QR' },
  { key: randomId(), label: '수정' },
];

const Partner = () => {
  const qrRef = useRef(null);
  const [qrOpened, qrHandler] = useDisclosure(false);
  const { classes, cx } = useStyles();
  const { toggleAll, toggleRow } = Toggle();

  const navigate = useNavigate();

  const [deleteOpened, deleteHandler] = useDisclosure(false);

  const [departments, setDepartments] = useState<
    { label: string; value: string }[]
  >([]);

  const [pagePartners, setPagePartners] = useState<PartnerModel[]>([]);
  const [selection, setSelection] = useState<string[]>([]);
  const [selectedPartner, setSelectedPartner] = useState<PartnerModel | null>(
    null
  );

  const [search, setSearch] = useState('');
  const [isSearch, setIsSearch] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [activePage, setActivePage] = useState(retrievePageNumber());
  const [totalCount, setTotalCount] = useState(0);

  const onPrint = useReactToPrint({
    content: () => qrRef?.current,
  });

  useEffect(() => {
    const userCheck = onCheckUser();
    if (!userCheck) {
      navigate('/login');
    }

    setActivePage(retrievePageNumber());
    const onFetch = async () => {
      await onGetDepartments();
      await onGetPartners();
    };

    onFetch();

    const deleteSub = client
      .graphql({ query: onDeletePartnerModel })
      .subscribe({
        next: async ({ data }: any) => {
          console.log(data);
        },
        error: (error: any) => console.warn(error),
      });

    return () => {
      deleteSub.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (isSearch) {
      onSearch();
    } else {
      onGetPartners();
    }
  }, [activePage]);

  const onGetPartners = async () => {
    await client
      .graphql({
        query: searchPartnerModels,
        variables: {
          limit: LIMIT_PAGE,
          from: LIMIT_PAGE * (activePage - 1),
          sort: [
            {
              field: SearchablePartnerModelSortableFields.createdAt,
              direction: SearchableSortDirection.desc,
            },
          ],
        },
      })
      .then((result) => {
        setTotalCount((result.data.searchPartnerModels as any).total);

        if (result.errors && result.errors.length > 0) {
          alert('데이터를 불러오는데 실패했습니다. 다시 시도해 주세요.');
          return;
        }

        const { items } = result.data.searchPartnerModels;

        setPagePartners(items);
        setIsLoading(false);
      });
  };

  const setActivePageAndSave = (page: number) => {
    setActivePage(page);
    savePageNumber(page);
  };

  const onSearch = async () => {
    if (search.length === 0) {
      setIsSearch(false);
    } else {
      setIsSearch(true);
    }
    try {
      setIsLoading(true);

      await client
        .graphql({
          query: searchPartnerModels,
          variables: {
            limit: LIMIT_PAGE,
            from: LIMIT_PAGE * (activePage - 1),
            filter: {
              or: [
                { name: { wildcard: `*${search}*` } },
                { username: { wildcard: `*${search}*` } },
              ],
            },
            sort: [
              {
                field: SearchablePartnerModelSortableFields.createdAt,
                direction: SearchableSortDirection.asc,
              },
            ],
          },
        })
        .then((result) => {
          setTotalCount((result.data.searchPartnerModels as any).total);

          if (result.errors && result.errors.length > 0) {
            alert('검색을 실패했습니다. 다시 시도해 주세요.');
            return;
          }

          const { items } = result.data.searchPartnerModels;

          if (items.length === 0) {
            alert('데이터가 존재하지 않습니다.');
            return;
          }

          setPagePartners(items);
          setIsLoading(false);
        });
    } catch (error) {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const onGetDepartments = async () => {
    setIsLoading(true);
    const result = await client.graphql({ query: listDepartmentModels });
    const list = result.data.listDepartmentModels.items;

    const selectList = list.map((data) => {
      const { id, hospital, dept } = data;

      return {
        label: `${hospital} - ${dept}`,
        value: id,
      };
    });

    setDepartments(selectList);
  };

  const partnerHeader = TableHeader({
    headers: headerData,
    data: pagePartners,
    selection,
    setSelection,
    toggleAll,
    dynamicKey: 'id',
    checkbox: true,
  });

  const partnerRow = TableRow({
    toggleRow,
    data: pagePartners,
    selection,
    setSelection,
    dynamicKey: 'id',
    checkbox: true,
    tableRows: (row) => (
      <>
        <td>
          {
            departments.find((item) => item.value === row.departmentmodelID)
              ?.label
          }
        </td>
        <td>{row.name}</td>
        <td>{row.username}</td>
        <td>{row.point}</td>
        <td>
          <UnstyledButton
            onClick={() => {
              setSelectedPartner(row);
              qrHandler.open();
            }}
          >
            <IconQrcode color="gray" />
          </UnstyledButton>
        </td>
        <td>
          <UnstyledButton
            variant="default"
            onClick={() => navigate(`/admin/partner/edit/${row.id}`)}
          >
            <IconEdit color="gray" />
          </UnstyledButton>
        </td>
      </>
    ),
  });

  const onDeletePartner = async () => {
    try {
      if (selection.length === 0) {
        alert('선택된 파트너가 없습니다.');
        return;
      }

      setIsLoading(true);
      await Promise.all(
        selection.map(async (partner) => {
          try {
            const result = await client.graphql({
              query: deletePartnerModel,
              variables: {
                input: {
                  id: String(partner),
                },
              },
            });

            if (result.errors && result.errors?.length > 0) {
              alert('데이터 삭제를 실패했습니다. 다시 시도해 주세요.');
              return;
            }
          } catch (error) {
            console.error(error);
          }
        })
      );

      deleteHandler.close();

      setTimeout(() => {
        setIsLoading(false);
        onGetPartners();
      }, 1800);
    } catch (error: any) {
      console.error(error);
    }
  };

  const MemoizedDataTable = memo(DataTable);
  const MemoizedPagination = memo(Pagination);
  const MemoizedDeleteModal = memo(DeleteModal);

  return (
    <Layout>
      <LoadingOverlay style={{ position: 'fixed' }} visible={isLoading} />
      <Box>
        <Title order={4}>
          <span>파트너 목록</span>
        </Title>
        <Flex align={'center'} justify={'space-between'}>
          <Flex gap={rem(10)}>
            <Input
              value={search}
              onChange={(event) => setSearch(event.target.value)}
              rightSection={
                <UnstyledButton
                  onClick={() => {
                    setSearch('');
                  }}
                >
                  x
                </UnstyledButton>
              }
            />
            <Button
              onClick={() => {
                setActivePage(1);
                onSearch();
              }}
            >
              검색
            </Button>
          </Flex>
          <Flex gap={rem(10)} className={classes.buttonList}>
            <Button
              variant="default"
              onClick={() => navigate('/admin/partner/add')}
            >
              추가
            </Button>
            <Button
              variant="default"
              onClick={() => {
                if (selection.length === 0) {
                  alert('선택된 파트너가 없습니다.');
                  return;
                }
                deleteHandler.open();
              }}
            >
              삭제
            </Button>
          </Flex>
        </Flex>
        <MemoizedDataTable
          tableHeader={partnerHeader}
          tableRow={partnerRow}
          txt="데이터가 존재하지 않습니다."
        />
        <Space w={1} h={1} mb={rem(25)} />
        <Center>
          <MemoizedPagination
            total={Math.ceil(totalCount / LIMIT_PAGE)}
            value={activePage}
            onChange={setActivePageAndSave}
          />
        </Center>
        <Space w={1} h={1} mb={rem(200)} />
      </Box>
      <MemoizedDeleteModal
        opened={deleteOpened}
        onClose={deleteHandler.close}
        onDelete={onDeletePartner}
      />
      <Modal centered opened={qrOpened} onClose={qrHandler.close}>
        <Stack align="center">
          <span style={{ fontSize: rem(20), fontWeight: 600 }}>
            {selectedPartner?.name}님의 QR코드
          </span>
          <Space w={1} h={1} mb={rem(20)} />
          <Flex
            ref={qrRef}
            justify={'center'}
            align={'center'}
            style={{ padding: rem(25) }}
          >
            <QRCodeCanvas value={JSON.stringify({ id: selectedPartner?.id })} />
          </Flex>
          <Space w={1} h={1} mb={rem(20)} />
          <Button onClick={onPrint}>인쇄하기</Button>
        </Stack>
      </Modal>
    </Layout>
  );
};

const useStyles = createStyles(() => ({
  buttonList: {
    paddingBlock: rem(10),
  },
}));

export default Partner;
