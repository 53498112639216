import {
  Box,
  Button,
  Center,
  Flex,
  Input,
  LoadingOverlay,
  Modal,
  Select,
  Space,
  Stack,
  Title,
  UnstyledButton,
  createStyles,
  rem,
} from '@mantine/core';
import { useNavigate, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { IconArrowLeft } from '@tabler/icons-react';
import { Layout } from 'src/components';
import { client } from 'src';
import { getVideoModel, listPartnerModels } from 'src/graphql/queries';
import { updateVideoModel } from 'src/graphql/mutations';
import { extract } from '@extractus/oembed-extractor';
import { useDisclosure } from '@mantine/hooks';

const VideoEdit = () => {
  const { classes } = useStyles();
  const { id } = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [linkSearchOpened, linkSearchHandler] = useDisclosure(false);

  const [partnerId, setPartnerId] = useState<
    { label: string; value: string }[]
  >([]);
  const [video, setVideo] = useState({
    title: '',
    retitle: '',
    author: '',
    url: '',
    tag: '',
    partnermodelID: '',
  });

  useEffect(() => {
    onGetVideo();
    onGetPartner();
  }, []);

  const onGetPartner = async () => {
    const result = await client.graphql({ query: listPartnerModels });
    const list = result.data.listPartnerModels.items;

    const selectList = list.map((data) => {
      const { id, name } = data;
      return {
        label: name,
        value: id,
      };
    });

    setPartnerId(selectList);
  };

  const onGetVideo = async () => {
    try {
      const findItem = await client.graphql({
        query: getVideoModel,
        variables: {
          id: String(id),
        },
      });

      if (findItem.errors && findItem.errors.length > 0) {
        alert('데이터 불러오는데 실패했습니다. 다시 시도해 주세요.');
        return;
      }

      const item = findItem.data.getVideoModel;
      if (item) {
        setVideo({
          ...video,
          title: item.title,
          author: item.author,
          retitle: item.retitle || '',
          url: item.url,
          tag: item.tag || '',
          partnermodelID: item.partnermodelID || '',
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onEditVideo = async () => {
    const { title, retitle, author, url, tag, partnermodelID } = video;

    if (
      title.length == 0 ||
      author.length == 0 ||
      url.length == 0 ||
      partnermodelID.length == 0
    ) {
      alert('필수 항목이 입력되지 않았습니다. 입력해 주세요.');
      return;
    }
    try {
      setIsLoading(true);
      const result = await client.graphql({
        query: updateVideoModel,
        variables: {
          input: {
            id: String(id),
            title,
            retitle,
            author,
            url,
            tag,
          },
        },
      });

      if (result.errors && result.errors.length > 0) {
        alert('데이터 수정을 실패했습니다. 다시 시도해 주세요.');
        return;
      }

      setTimeout(() => {
        setIsLoading(false);
        navigate('/admin/video');
      }, 1800);
    } catch (error) {
      console.error(error);
    }
  };

  const onSearchVideoInfo = async () => {
    try {
      const result = await extract(video.url);

      if (video.url.length == 0) {
        alert('링크를 입력해 주세요.');
        return;
      } else {
        setVideo({
          ...video,
          title: result.title || 'Undefined',
          url: video.url,
        });

        linkSearchHandler.close();
      }
    } catch (error) {
      console.error('잘못된 URL: ', error);
      alert('올바른 URL을 입력해 주세요.');
    }
  };
  return (
    <Layout>
      <LoadingOverlay style={{ position: 'fixed' }} visible={isLoading} />
      <Space w={1} h={1} mb={rem(50)} />
      <Flex direction={'column'} justify={'center'} align={'center'} h={'100%'}>
        <Box className={classes.buttonList}>
          <Flex justify={'space-between'}>
            <UnstyledButton onClick={() => navigate('/admin/video')}>
              <IconArrowLeft />
            </UnstyledButton>
            <Title order={4}>
              <span>비디오 수정</span>
            </Title>
            <Button onClick={onEditVideo}>수정</Button>
          </Flex>
          <Stack>
            <Box>
              <Input.Wrapper label="수정 제목">
                <Input
                  value={video.retitle}
                  onChange={(event) =>
                    setVideo({ ...video, retitle: event.target.value })
                  }
                />
              </Input.Wrapper>
            </Box>
            <Box>
              <Input.Wrapper label="작성자" withAsterisk>
                <Input
                  value={video.author}
                  onChange={(event) =>
                    setVideo({ ...video, author: event.currentTarget?.value })
                  }
                />
              </Input.Wrapper>
            </Box>
            <Box>
              <Flex direction={'column'}>
                <Input.Wrapper label="원제목" withAsterisk>
                  <Input disabled value={video.title} />
                </Input.Wrapper>
                <Flex align={'flex-end'} gap={rem(10)}>
                  <Input.Wrapper style={{ flex: 1 }} label="URL" withAsterisk>
                    <Input disabled value={video.url} />
                  </Input.Wrapper>
                  <Button onClick={linkSearchHandler.open} variant="default">
                    찾기
                  </Button>
                </Flex>
              </Flex>
            </Box>
            <Box>
              <Input.Wrapper label="태그">
                <Input
                  value={video.tag}
                  onChange={(event) =>
                    setVideo({ ...video, tag: event.currentTarget?.value })
                  }
                />
              </Input.Wrapper>
            </Box>
            <Box>
              <Select
                label="파트너"
                withAsterisk
                data={partnerId}
                value={video.partnermodelID}
                onChange={(value) =>
                  setVideo((prev) => ({
                    ...prev,
                    partnermodelID: value || '',
                  }))
                }
              />
            </Box>
          </Stack>
        </Box>
      </Flex>
      <Modal
        title="영상 링크 정보 찾기"
        opened={linkSearchOpened}
        onClose={linkSearchHandler.close}
      >
        <Input.Wrapper label="URL">
          <Input
            onChange={(event) =>
              setVideo((prev) => ({
                ...prev,
                url: event.target.value,
              }))
            }
          />
        </Input.Wrapper>
        <Space w={1} h={1} mb={rem(20)} />
        <Center>
          <Button onClick={onSearchVideoInfo}>확인</Button>
        </Center>
      </Modal>
    </Layout>
  );
};

const useStyles = createStyles(() => ({
  buttonList: {
    width: '80%',
    maxWidth: rem(500),
  },
}));

export default VideoEdit;
