import { Checkbox, rem } from '@mantine/core';
import { Th } from '../components/index';
import { TextAlign, TableHeaderParams } from 'src/types/TableHeaderParams';
import { useMemo } from 'react';

const TableHeader = ({
  headers,
  data,
  selection,
  setSelection,
  dynamicKey,
  checkbox,
  disabled,
  toggleAll,
}: TableHeaderParams) => {
  const isCheckbox = useMemo(() => checkbox, [checkbox]);

  const isChecked = useMemo(
    () =>
      selection
        ? selection.length === (data?.length || 0) && (data?.length || 0) > 0
        : false,
    [selection, data]
  );

  return (
    <tr>
      {isCheckbox && (
        <th
          style={{
            width: rem(5),
          }}
        >
          <Checkbox
            disabled={disabled}
            onChange={() => toggleAll(data, setSelection, dynamicKey)}
            checked={isChecked}
          />
        </th>
      )}
      {headers.map(
        (header: {
          key: string;
          label: string;
          isShow?: boolean;
          align?: TextAlign;
          marginRight?: string;
          thWidth?: string;
        }) =>
          (header.isShow === true || header.isShow === undefined) && (
            <Th
              key={header.key}
              marginRight={header.marginRight}
              align={header.align}
              thWidth={header.thWidth}
            >
              {header.label}
            </Th>
          )
      )}
    </tr>
  );
};

export default TableHeader;
