import {
  BackgroundImage,
  Center,
  Flex,
  Image,
  List,
  SimpleGrid,
  Space,
  Stack,
  UnstyledButton,
  createStyles,
  rem,
} from '@mantine/core';

import MainBanner from '../assets/pricing-main-banner.jpg';
import Logo from '../assets/black-logo.png';

import { ReactComponent as GreenCheck } from '../assets/green-check.svg';
import { ReactComponent as GreenPlus } from '../assets/plus.svg';
import { ReactComponent as HalfDiscountIcon } from '../assets/50-discount.svg';
import { ReactComponent as DiscountIcon } from '../assets/33-discount.svg';
import { ReactComponent as RedCheck } from '../assets/red-check.svg';
import { ReactComponent as RedInfo } from '../assets/red-info.svg';

import { Layout } from 'src/components';
import classnames from 'classnames';
import { BrowserView, MobileView } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

const Pricing = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  return (
    <Layout tabs={false}>
      <BrowserView>
        {/* 메인 배너 */}
        <BackgroundImage
          mah={rem(600)}
          miw={rem(390)}
          mih={rem(430)}
          src={MainBanner}
          style={{
            zIndex: -2,
            position: 'relative',
          }}
        >
          <Flex
            className={classes.mainContents01}
            direction={'column'}
            justify={'center'}
            align={'center'}
            gap={rem(35)}
          >
            <Image
              maw={rem(1020)}
              mah={rem(294)}
              miw={rem(370)}
              mih={rem(164)}
              src={Logo}
            />
            <Flex direction={'column'} align={'center'} gap={rem(20)}>
              <span className={classes.serviceText}>PRICING</span>
              <span className={classes.serviceSubText}>영상 제작비</span>
            </Flex>
          </Flex>
        </BackgroundImage>
        <Flex direction={'column'} className={classes.introduceBox}>
          {/* 영상 제작비 */}
          <Flex direction={'column'} align={'center'}>
            <span className={classes.companyOverviewTitle}>영상 제작비</span>
          </Flex>
        </Flex>
        <Flex
          direction={'column'}
          gap={rem(30)}
          className={classes.introduceBox}
        >
          <SimpleGrid cols={4} spacing={40} className={classes.simpleGrid}>
            <Flex
              direction={'column'}
              justify={'space-between'}
              gap={rem(0)}
              className={classes.priceGrid}
            >
              <Flex direction={'column'}>
                <span className={classes.priceTitle}>Basic</span>
                <Space mb={rem(30)} />
                <Flex w={'100%'} direction={'column'} gap={rem(20)}>
                  <Flex align={'center'} gap={rem(12)}>
                    <GreenCheck className={classes.listIcon} />
                    <span className={classes.priceCondition}>
                      5분 영상 X 2편
                    </span>
                  </Flex>
                  <Flex align={'center'} gap={rem(12)}>
                    <GreenCheck className={classes.listIcon} />
                    <span className={classes.priceCondition}>카메라 1대</span>
                  </Flex>
                  <Flex align={'center'} gap={rem(12)}>
                    <GreenCheck className={classes.listIcon} />
                    <span className={classes.priceCondition}>
                      영상 기획 상담 1회 무료
                    </span>
                  </Flex>
                  <Flex align={'center'} gap={rem(12)}>
                    <GreenCheck className={classes.listIcon} />
                    <span className={classes.priceCondition}>
                      1시간 내 촬영
                    </span>
                  </Flex>
                  <Flex align={'center'} gap={rem(12)}>
                    <GreenCheck className={classes.listIcon} />
                    <span className={classes.priceCondition}>
                      수정 1회 무료
                    </span>
                  </Flex>
                </Flex>
              </Flex>
              <Flex gap={rem(4)} align={'center'} className={classes.priceBox}>
                <HalfDiscountIcon className={classes.discountIcon} />
                <Flex h={'100%'} justify={'space-between'} direction={'column'}>
                  <span
                    className={classnames(
                      classes.priceText,
                      classes.originPrice
                    )}
                  >
                    1,000,000
                  </span>
                  <span
                    className={classnames(
                      classes.priceText,
                      classes.discountPrice
                    )}
                  >
                    500,000원
                  </span>
                </Flex>
              </Flex>
            </Flex>
            <Flex
              direction={'column'}
              justify={'space-between'}
              gap={rem(0)}
              className={classes.priceGrid}
            >
              <Flex direction={'column'}>
                <span className={classes.priceTitle}>Plus</span>
                <Space mb={rem(30)} />
                <Flex w={'100%'} direction={'column'} gap={rem(20)}>
                  <Flex align={'center'} gap={rem(12)}>
                    <GreenCheck className={classes.listIcon} />
                    <span className={classes.priceCondition}>
                      5-10분 영상 X 2편
                    </span>
                  </Flex>
                  <Flex align={'center'} gap={rem(12)}>
                    <GreenCheck className={classes.listIcon} />
                    <span className={classes.priceCondition}>카메라 2대</span>
                  </Flex>
                  <Flex align={'center'} gap={rem(12)}>
                    <GreenCheck className={classes.listIcon} />
                    <span className={classes.priceCondition}>
                      영상 기획 상담 1회 무료
                    </span>
                  </Flex>
                  <Flex align={'center'} gap={rem(12)}>
                    <GreenCheck className={classes.listIcon} />
                    <span className={classes.priceCondition}>
                      1시간 내 촬영
                    </span>
                  </Flex>
                  <Flex align={'center'} gap={rem(12)}>
                    <GreenCheck className={classes.listIcon} />
                    <span className={classes.priceCondition}>
                      수정 2회 무료
                    </span>
                  </Flex>
                  <Flex align={'center'} gap={rem(12)}>
                    <GreenCheck className={classes.listIcon} />
                    <span className={classes.priceCondition}>
                      AI 영상 제작 1편 무료
                    </span>
                  </Flex>
                </Flex>
              </Flex>
              <Flex gap={rem(4)} align={'center'} className={classes.priceBox}>
                <HalfDiscountIcon className={classes.discountIcon} />
                <Flex h={'100%'} justify={'space-between'} direction={'column'}>
                  <span
                    className={classnames(
                      classes.priceText,
                      classes.originPrice
                    )}
                  >
                    2,000,000
                  </span>
                  <span
                    className={classnames(
                      classes.priceText,
                      classes.discountPrice
                    )}
                  >
                    1,000,000원
                  </span>
                </Flex>
              </Flex>
            </Flex>
            <Flex
              direction={'column'}
              justify={'space-between'}
              gap={rem(0)}
              className={classes.priceGrid}
            >
              <Flex direction={'column'}>
                <span className={classes.priceTitle}>Premium</span>
                <Space mb={rem(30)} />
                <Flex w={'100%'} direction={'column'} gap={rem(20)}>
                  <Flex align={'center'} gap={rem(12)}>
                    <GreenCheck className={classes.listIcon} />
                    <span className={classes.priceCondition}>
                      5-10분 영상 X 3편
                    </span>
                  </Flex>
                  <Flex align={'center'} gap={rem(12)}>
                    <GreenCheck className={classes.listIcon} />
                    <span className={classes.priceCondition}>카메라 2대</span>
                  </Flex>
                  <Flex align={'center'} gap={rem(12)}>
                    <GreenCheck className={classes.listIcon} />
                    <span className={classes.priceCondition}>
                      영상 기획 상담 1회 무료
                    </span>
                  </Flex>
                  <Flex align={'center'} gap={rem(12)}>
                    <GreenCheck className={classes.listIcon} />
                    <span className={classes.priceCondition}>
                      1시간 내 촬영
                    </span>
                  </Flex>
                  <Flex align={'center'} gap={rem(12)}>
                    <GreenCheck className={classes.listIcon} />
                    <span className={classes.priceCondition}>
                      수정 3회 무료
                    </span>
                  </Flex>
                  <Flex align={'center'} gap={rem(12)}>
                    <GreenCheck className={classes.listIcon} />
                    <span className={classes.priceCondition}>
                      AI 영상 제작 3편 무료
                    </span>
                  </Flex>
                  <Flex align={'center'} gap={rem(12)}>
                    <GreenCheck className={classes.listIcon} />
                    <span
                      className={classes.priceCondition}
                      style={{ color: '#F75555' }}
                    >
                      맞춤형 Script 제공
                    </span>
                  </Flex>
                </Flex>
              </Flex>
              <Flex gap={rem(4)} align={'center'} className={classes.priceBox}>
                <HalfDiscountIcon className={classes.discountIcon} />
                <Flex h={'100%'} justify={'space-between'} direction={'column'}>
                  <span
                    className={classnames(
                      classes.priceText,
                      classes.originPrice
                    )}
                  >
                    4,000,000
                  </span>
                  <span
                    className={classnames(
                      classes.priceText,
                      classes.discountPrice
                    )}
                  >
                    2,000,000원
                  </span>
                </Flex>
              </Flex>
            </Flex>
            <Flex
              direction={'column'}
              justify={'space-between'}
              gap={rem(0)}
              className={classes.priceGrid}
            >
              <Flex direction={'column'}>
                <span className={classes.priceTitle}>AI Pack</span>
                <Space mb={rem(30)} />
                <Flex w={'100%'} direction={'column'} gap={rem(20)}>
                  <Flex align={'center'} gap={rem(12)}>
                    <GreenCheck className={classes.listIcon} />
                    <span className={classes.priceCondition}>
                      5분 AI 영상 X 1편
                    </span>
                  </Flex>
                  <Flex align={'center'} gap={rem(12)}>
                    <GreenCheck className={classes.listIcon} />
                    <span className={classes.priceCondition}>
                      카메라 1대 (AI 생성용)
                    </span>
                  </Flex>
                  <Flex align={'center'} gap={rem(12)}>
                    <GreenCheck className={classes.listIcon} />
                    <span className={classes.priceCondition}>
                      영상 기획 상담 1회 무료
                    </span>
                  </Flex>
                  <Flex align={'center'} gap={rem(12)}>
                    <GreenCheck className={classes.listIcon} />
                    <span className={classes.priceCondition}>
                      10분 내 AI 생성용 영상 촬영
                    </span>
                  </Flex>
                  <Flex align={'center'} gap={rem(12)}>
                    <GreenCheck className={classes.listIcon} />
                    <span className={classes.priceCondition}>
                      수정 1회 무료
                    </span>
                  </Flex>
                  <Flex align={'center'} gap={rem(12)}>
                    <GreenCheck className={classes.listIcon} />
                    <span className={classes.priceCondition}>
                      PPT 발표 자료 제작
                    </span>
                  </Flex>
                  <Flex align={'center'} gap={rem(12)}>
                    <GreenCheck className={classes.listIcon} />
                    <span
                      className={classes.priceCondition}
                      style={{ color: '#F75555' }}
                    >
                      맞춤형 Script 제공
                    </span>
                  </Flex>
                </Flex>
              </Flex>
              <Flex gap={rem(4)} align={'center'} className={classes.priceBox}>
                <DiscountIcon className={classes.discountIcon} />
                <Flex h={'100%'} justify={'space-between'} direction={'column'}>
                  <span
                    className={classnames(
                      classes.priceText,
                      classes.originPrice
                    )}
                  >
                    1,500,000
                  </span>
                  <span
                    className={classnames(
                      classes.priceText,
                      classes.discountPrice
                    )}
                  >
                    1,000,000원
                  </span>
                </Flex>
              </Flex>
            </Flex>
          </SimpleGrid>
          <Stack className={classes.addPriceBox} spacing={rem(0)}>
            <Flex gap={rem(10)}>
              <RedInfo />
              <span className={classes.priceTitle}>추가비용</span>
            </Flex>
            <Space mb={rem(30)} />
            <Flex gap={rem(45)}>
              <Stack spacing={rem(10)}>
                <Flex align={'center'} gap={rem(10)}>
                  <GreenPlus className={classes.listIcon} />
                  <span className={classes.priceCondition}>
                    추가 편집 수정 1회 (5분 영상 기준) 100,000원
                  </span>
                </Flex>
                <Flex align={'center'} gap={rem(10)}>
                  <GreenPlus className={classes.listIcon} />
                  <span className={classes.priceCondition}>
                    추가 AI 영상 제작 (5분 영상 기준) 200,000원
                  </span>
                </Flex>
                <Flex align={'center'} gap={rem(10)}>
                  <GreenPlus className={classes.listIcon} />
                  <span className={classes.priceCondition}>
                    PPT 발표 자료 제작 (10분 영상 기준) 500,000원
                  </span>
                </Flex>
                <Flex align={'center'} gap={rem(10)} mt={rem(10)}>
                  <RedCheck className={classes.listIcon} />
                  <span className={classes.priceCondition}>
                    맞춤 제작 별도 견적 문의
                  </span>
                </Flex>
              </Stack>
              <Stack spacing={rem(10)}>
                <Flex align={'center'} gap={rem(10)}>
                  <GreenPlus className={classes.listIcon} />
                  <span className={classes.priceCondition}>
                    추가 숏클립 제작 1건 200,000원
                  </span>
                </Flex>
                <Flex align={'center'} gap={rem(10)}>
                  <GreenPlus className={classes.listIcon} />
                  <span className={classes.priceCondition}>
                    영상기획 coordination 1회 (1시간) 300,000원
                  </span>
                </Flex>
                <Flex align={'center'} gap={rem(10)}>
                  <GreenPlus className={classes.listIcon} />
                  <span className={classes.priceCondition}>
                    학회자료, 지침서 등에 기반한 맞춤형 Script 제작 (10분 영상
                    기준) 500,000원
                  </span>
                </Flex>
                <List mt={rem(10)} pl={rem(10)}>
                  <List.Item
                    className={classes.priceCondition}
                    style={{ color: '#F75555' }}
                  >
                    타지역 촬영 출장비 별도
                  </List.Item>
                  <List.Item
                    className={classes.priceCondition}
                    style={{ color: '#F75555' }}
                  >
                    별도 AI 영상 제작은 coordination을 반드시 함께 진행
                  </List.Item>
                </List>
              </Stack>
            </Flex>
          </Stack>
          <Center mt={rem(20)}>
            <UnstyledButton
              onClick={() => navigate('/contact')}
              className={classes.button}
            >
              <span>제작 의뢰</span>
            </UnstyledButton>
          </Center>
        </Flex>
        <Space mb={rem(50)} />
      </BrowserView>
      <MobileView>
        <Space w={4} h={5} mb={rem(42)} />
        {/* 메인 배너 */}
        <BackgroundImage
          miw={rem(390)}
          mih={rem(430)}
          src={MainBanner}
          style={{
            zIndex: -2,
            position: 'relative',
          }}
          className={classes.m_mainBackgroundPosition}
        >
          <Flex
            className={classnames(
              classes.mainContents01,
              classes.m_mainContents1
            )}
            direction={'column'}
            justify={'center'}
            align={'center'}
            gap={rem(50)}
          >
            <Image className={classes.logoImage} src={Logo} />
            <Flex direction={'column'} align={'center'}>
              <span className={classes.serviceText}>PRICING</span>
              <span className={classes.serviceSubText}>영상 제작비</span>
            </Flex>
          </Flex>
        </BackgroundImage>
        {/* 영상 제작비 */}
        <Flex direction={'column'} className={classes.m_introduceBox}>
          <Flex direction={'column'} align={'center'}>
            <span
              className={classnames(
                classes.companyOverviewTitle,
                classes.m_companyOverviewTitle
              )}
            >
              영상 제작비
            </span>
          </Flex>
        </Flex>
        <Flex
          direction={'column'}
          gap={rem(30)}
          className={classes.m_introduceBox}
        >
          <Flex direction={'column'} gap={rem(20)}>
            <Flex
              direction={'column'}
              justify={'space-between'}
              gap={rem(0)}
              className={classnames(classes.priceGrid, classes.m_priceGrid)}
            >
              <Flex direction={'column'}>
                <span
                  className={classnames(
                    classes.priceTitle,
                    classes.m_priceTitle
                  )}
                >
                  Basic
                </span>
                <Space mb={rem(30)} />
                <Flex w={'100%'} direction={'column'} gap={rem(20)}>
                  <Flex align={'center'} gap={rem(12)}>
                    <GreenCheck className={classes.listIcon} />
                    <span
                      className={classnames(
                        classes.priceCondition,
                        classes.m_priceCondition
                      )}
                    >
                      5분 영상 X 2편
                    </span>
                  </Flex>
                  <Flex align={'center'} gap={rem(12)}>
                    <GreenCheck className={classes.listIcon} />
                    <span
                      className={classnames(
                        classes.priceCondition,
                        classes.m_priceCondition
                      )}
                    >
                      카메라 1대
                    </span>
                  </Flex>
                  <Flex align={'center'} gap={rem(12)}>
                    <GreenCheck className={classes.listIcon} />
                    <span
                      className={classnames(
                        classes.priceCondition,
                        classes.m_priceCondition
                      )}
                    >
                      영상 기획 상담 1회 무료
                    </span>
                  </Flex>
                  <Flex align={'center'} gap={rem(12)}>
                    <GreenCheck className={classes.listIcon} />
                    <span
                      className={classnames(
                        classes.priceCondition,
                        classes.m_priceCondition
                      )}
                    >
                      1시간 내 촬영
                    </span>
                  </Flex>
                  <Flex align={'center'} gap={rem(12)}>
                    <GreenCheck className={classes.listIcon} />
                    <span
                      className={classnames(
                        classes.priceCondition,
                        classes.m_priceCondition
                      )}
                    >
                      수정 1회 무료
                    </span>
                  </Flex>
                </Flex>
              </Flex>
              <Flex gap={rem(4)} align={'center'} className={classes.priceBox}>
                <HalfDiscountIcon className={classes.discountIcon} />
                <Flex h={'100%'} justify={'space-between'} direction={'column'}>
                  <span
                    className={classnames(
                      classes.priceText,
                      classes.originPrice,
                      classes.m_originPrice
                    )}
                  >
                    1,000,000
                  </span>
                  <span
                    className={classnames(
                      classes.priceText,
                      classes.discountPrice
                    )}
                  >
                    500,000원
                  </span>
                </Flex>
              </Flex>
            </Flex>
            <Flex
              direction={'column'}
              justify={'space-between'}
              gap={rem(0)}
              className={classnames(classes.priceGrid, classes.m_priceGrid)}
            >
              <Flex direction={'column'}>
                <span
                  className={classnames(
                    classes.priceTitle,
                    classes.m_priceTitle
                  )}
                >
                  Plus
                </span>
                <Space mb={rem(30)} />
                <Flex w={'100%'} direction={'column'} gap={rem(20)}>
                  <Flex align={'center'} gap={rem(12)}>
                    <GreenCheck className={classes.listIcon} />
                    <span
                      className={classnames(
                        classes.priceCondition,
                        classes.m_priceCondition
                      )}
                    >
                      5-10분 영상 X 2편
                    </span>
                  </Flex>
                  <Flex align={'center'} gap={rem(12)}>
                    <GreenCheck className={classes.listIcon} />
                    <span
                      className={classnames(
                        classes.priceCondition,
                        classes.m_priceCondition
                      )}
                    >
                      카메라 2대
                    </span>
                  </Flex>
                  <Flex align={'center'} gap={rem(12)}>
                    <GreenCheck className={classes.listIcon} />
                    <span
                      className={classnames(
                        classes.priceCondition,
                        classes.m_priceCondition
                      )}
                    >
                      영상 기획 상담 1회 무료
                    </span>
                  </Flex>
                  <Flex align={'center'} gap={rem(12)}>
                    <GreenCheck className={classes.listIcon} />
                    <span
                      className={classnames(
                        classes.priceCondition,
                        classes.m_priceCondition
                      )}
                    >
                      1시간 내 촬영
                    </span>
                  </Flex>
                  <Flex align={'center'} gap={rem(12)}>
                    <GreenCheck className={classes.listIcon} />
                    <span
                      className={classnames(
                        classes.priceCondition,
                        classes.m_priceCondition
                      )}
                    >
                      수정 2회 무료
                    </span>
                  </Flex>
                  <Flex align={'center'} gap={rem(12)}>
                    <GreenCheck className={classes.listIcon} />
                    <span
                      className={classnames(
                        classes.priceCondition,
                        classes.m_priceCondition
                      )}
                    >
                      AI 영상 제작 1편 무료
                    </span>
                  </Flex>
                </Flex>
              </Flex>
              <Flex gap={rem(4)} align={'center'} className={classes.priceBox}>
                <HalfDiscountIcon className={classes.discountIcon} />
                <Flex h={'100%'} justify={'space-between'} direction={'column'}>
                  <span
                    className={classnames(
                      classes.priceText,
                      classes.originPrice
                    )}
                  >
                    2,000,000
                  </span>
                  <span
                    className={classnames(
                      classes.priceText,
                      classes.discountPrice
                    )}
                  >
                    1,000,000원
                  </span>
                </Flex>
              </Flex>
            </Flex>
            <Flex
              direction={'column'}
              justify={'space-between'}
              gap={rem(0)}
              className={classnames(classes.priceGrid, classes.m_priceGrid)}
            >
              <Flex direction={'column'}>
                <span
                  className={classnames(
                    classes.priceTitle,
                    classes.m_priceTitle
                  )}
                >
                  Premium
                </span>
                <Space mb={rem(30)} />
                <Flex w={'100%'} direction={'column'} gap={rem(20)}>
                  <Flex align={'center'} gap={rem(12)}>
                    <GreenCheck className={classes.listIcon} />
                    <span
                      className={classnames(
                        classes.priceCondition,
                        classes.m_priceCondition
                      )}
                    >
                      5-10분 영상 X 3편
                    </span>
                  </Flex>
                  <Flex align={'center'} gap={rem(12)}>
                    <GreenCheck className={classes.listIcon} />
                    <span
                      className={classnames(
                        classes.priceCondition,
                        classes.m_priceCondition
                      )}
                    >
                      카메라 2대
                    </span>
                  </Flex>
                  <Flex align={'center'} gap={rem(12)}>
                    <GreenCheck className={classes.listIcon} />
                    <span
                      className={classnames(
                        classes.priceCondition,
                        classes.m_priceCondition
                      )}
                    >
                      영상 기획 상담 1회 무료
                    </span>
                  </Flex>
                  <Flex align={'center'} gap={rem(12)}>
                    <GreenCheck className={classes.listIcon} />
                    <span
                      className={classnames(
                        classes.priceCondition,
                        classes.m_priceCondition
                      )}
                    >
                      1시간 내 촬영
                    </span>
                  </Flex>
                  <Flex align={'center'} gap={rem(12)}>
                    <GreenCheck className={classes.listIcon} />
                    <span
                      className={classnames(
                        classes.priceCondition,
                        classes.m_priceCondition
                      )}
                    >
                      수정 3회 무료
                    </span>
                  </Flex>
                  <Flex align={'center'} gap={rem(12)}>
                    <GreenCheck className={classes.listIcon} />
                    <span
                      className={classnames(
                        classes.priceCondition,
                        classes.m_priceCondition
                      )}
                    >
                      AI 영상 제작 3편 무료
                    </span>
                  </Flex>
                  <Flex align={'center'} gap={rem(12)}>
                    <GreenCheck className={classes.listIcon} />
                    <span
                      className={classnames(
                        classes.priceCondition,
                        classes.m_priceCondition
                      )}
                      style={{ color: '#F75555' }}
                    >
                      맞춤형 Script 제공
                    </span>
                  </Flex>
                </Flex>
              </Flex>
              <Flex gap={rem(4)} align={'center'} className={classes.priceBox}>
                <HalfDiscountIcon className={classes.discountIcon} />
                <Flex h={'100%'} justify={'space-between'} direction={'column'}>
                  <span
                    className={classnames(
                      classes.priceText,
                      classes.originPrice
                    )}
                  >
                    4,000,000
                  </span>
                  <span
                    className={classnames(
                      classes.priceText,
                      classes.discountPrice
                    )}
                  >
                    2,000,000원
                  </span>
                </Flex>
              </Flex>
            </Flex>
            <Flex
              direction={'column'}
              justify={'space-between'}
              gap={rem(0)}
              className={classnames(classes.priceGrid, classes.m_priceGrid)}
            >
              <Flex direction={'column'}>
                <span
                  className={classnames(
                    classes.priceTitle,
                    classes.m_priceTitle
                  )}
                >
                  AI Pack
                </span>
                <Space mb={rem(30)} />
                <Flex w={'100%'} direction={'column'} gap={rem(20)}>
                  <Flex align={'center'} gap={rem(12)}>
                    <GreenCheck className={classes.listIcon} />
                    <span
                      className={classnames(
                        classes.priceCondition,
                        classes.m_priceCondition
                      )}
                    >
                      5분 AI 영상 X 1편
                    </span>
                  </Flex>
                  <Flex align={'center'} gap={rem(12)}>
                    <GreenCheck className={classes.listIcon} />
                    <span
                      className={classnames(
                        classes.priceCondition,
                        classes.m_priceCondition
                      )}
                    >
                      카메라 1대 (AI 생성용)
                    </span>
                  </Flex>
                  <Flex align={'center'} gap={rem(12)}>
                    <GreenCheck className={classes.listIcon} />
                    <span
                      className={classnames(
                        classes.priceCondition,
                        classes.m_priceCondition
                      )}
                    >
                      영상 기획 상담 1회 무료
                    </span>
                  </Flex>
                  <Flex align={'center'} gap={rem(12)}>
                    <GreenCheck className={classes.listIcon} />
                    <span
                      className={classnames(
                        classes.priceCondition,
                        classes.m_priceCondition
                      )}
                    >
                      10분 내 AI 생성용 영상 촬영
                    </span>
                  </Flex>
                  <Flex align={'center'} gap={rem(12)}>
                    <GreenCheck className={classes.listIcon} />
                    <span
                      className={classnames(
                        classes.priceCondition,
                        classes.m_priceCondition
                      )}
                    >
                      수정 1회 무료
                    </span>
                  </Flex>
                  <Flex align={'center'} gap={rem(12)}>
                    <GreenCheck className={classes.listIcon} />
                    <span
                      className={classnames(
                        classes.priceCondition,
                        classes.m_priceCondition
                      )}
                    >
                      PPT 발표 자료 제작
                    </span>
                  </Flex>
                  <Flex align={'center'} gap={rem(12)}>
                    <GreenCheck className={classes.listIcon} />
                    <span
                      className={classnames(
                        classes.priceCondition,
                        classes.m_priceCondition
                      )}
                      style={{ color: '#F75555' }}
                    >
                      맞춤형 Script 제공
                    </span>
                  </Flex>
                </Flex>
              </Flex>
              <Flex gap={rem(4)} align={'center'} className={classes.priceBox}>
                <DiscountIcon />
                <Flex h={'100%'} justify={'space-between'} direction={'column'}>
                  <span
                    className={classnames(
                      classes.priceText,
                      classes.originPrice
                    )}
                  >
                    1,500,000
                  </span>
                  <span
                    className={classnames(
                      classes.priceText,
                      classes.discountPrice
                    )}
                  >
                    1,000,000원
                  </span>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          <Stack className={classes.m_addPriceBox} spacing={rem(0)}>
            <Flex align={'center'} gap={rem(10)}>
              <RedInfo />
              <span className={classes.addPriceInfoTitle}>추가비용</span>
            </Flex>
            <Space mb={rem(30)} />
            <Flex gap={rem(45)}>
              <Stack spacing={rem(10)}>
                <Flex align={'center'} gap={rem(10)}>
                  <GreenPlus width={rem(25)} height={rem(25)} />
                  <span
                    className={classnames(
                      classes.priceCondition,
                      classes.m_priceCondition
                    )}
                  >
                    추가 편집 수정 1회
                    <br /> (5분 영상 기준) 100,000원
                  </span>
                </Flex>
                <Flex align={'center'} gap={rem(10)}>
                  <GreenPlus width={rem(25)} height={rem(25)} />
                  <span
                    className={classnames(
                      classes.priceCondition,
                      classes.m_priceCondition
                    )}
                  >
                    추가 AI 영상 제작
                    <br /> (5분 영상 기준) 200,000원
                  </span>
                </Flex>
                <Flex align={'center'} gap={rem(10)}>
                  <GreenPlus width={rem(25)} height={rem(25)} />
                  <span
                    className={classnames(
                      classes.priceCondition,
                      classes.m_priceCondition
                    )}
                  >
                    PPT 발표 자료 제작
                    <br /> (10분 영상 기준) 500,000원
                  </span>
                </Flex>

                <Flex align={'center'} gap={rem(10)}>
                  <GreenPlus width={rem(25)} height={rem(25)} />
                  <span
                    className={classnames(
                      classes.priceCondition,
                      classes.m_priceCondition
                    )}
                  >
                    추가 숏클립 제작 1건 200,000원
                  </span>
                </Flex>
                <Flex align={'center'} gap={rem(10)}>
                  <GreenPlus width={rem(25)} height={rem(25)} />
                  <span
                    className={classnames(
                      classes.priceCondition,
                      classes.m_priceCondition
                    )}
                  >
                    영상기획 coordination 1회
                    <br /> (1시간) 300,000원
                  </span>
                </Flex>
                <Flex align={'center'} gap={rem(10)}>
                  <GreenPlus width={rem(25)} height={rem(25)} />
                  <span
                    className={classnames(
                      classes.priceCondition,
                      classes.m_priceCondition
                    )}
                  >
                    학회자료, 지침서 등에 기반한 맞춤형
                    <br />
                    Script 제작 (10분 영상 기준) 500,000원
                  </span>
                </Flex>
                <Flex align={'center'} gap={rem(10)} mt={rem(10)}>
                  <RedCheck />
                  <span
                    className={classnames(
                      classes.priceCondition,
                      classes.m_priceCondition
                    )}
                  >
                    맞춤 제작 별도 견적 문의
                  </span>
                </Flex>
                <List mt={rem(10)} pl={rem(10)}>
                  <List.Item
                    className={classnames(
                      classes.priceCondition,
                      classes.m_priceCondition
                    )}
                    style={{ color: '#F75555' }}
                  >
                    타지역 촬영 출장비 별도
                  </List.Item>
                  <List.Item
                    className={classnames(
                      classes.priceCondition,
                      classes.m_priceCondition
                    )}
                    style={{ color: '#F75555' }}
                  >
                    별도 AI 영상 제작은 coordination을
                    <br /> 반드시 함께 진행
                  </List.Item>
                </List>
              </Stack>
            </Flex>
          </Stack>
          <Center mt={rem(20)}>
            <UnstyledButton
              onClick={() => navigate('/contact')}
              className={classes.button}
            >
              <span>제작 의뢰</span>
            </UnstyledButton>
          </Center>
        </Flex>
        <Space mb={rem(50)} />
      </MobileView>
    </Layout>
  );
};

const useStyles = createStyles(() => ({
  mainContents01: {
    paddingInline: rem(50),
    height: rem(600),
    color: '#2C93AD',
    ':after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(234, 244, 247, 0.7)',
      zIndex: -1,
    },
  },
  m_mainContents1: {
    height: rem(430),
    paddingInline: rem(30),
    paddingBlock: rem(30),
  },
  sectionBackground: {
    ':before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
    },
  },
  m_mainBackgroundPosition: {
    backgroundSize: 'cover',
    backgroundPosition: '0 -20%',
  },
  sectionBackgroundPosition: {
    backgroundSize: 'cover',
    backgroundPosition: '0 58%',
  },
  sectionBox: {
    width: rem(1280),
    paddingInline: rem(50),
    zIndex: 1,
  },
  button: {
    maxWidth: rem(400),
    minWidth: rem(330),
    height: rem(52),
    border: `${rem(1)} solid #2A93AD`,
    borderRadius: rem(16),
    backgroundColor: '#2D93AD',
    color: 'white',
    textAlign: 'center',
    paddingBlock: rem(14),
    fontSize: rem(18),
    fontWeight: 500,
    lineHeight: rem(25),
  },
  mainContents2: {
    maxWidth: rem(1280),
    marginInline: 'auto',
    paddingInline: rem(30),
  },
  m_mainContents2: {
    paddingInline: rem(30),
  },
  mainContentsTitle: {
    textAlign: 'center',
    fontWeight: 800,
    fontSize: rem(96),
    color: 'white',
  },
  m_mainContentsTitle: {
    fontSize: rem(36),
    color: 'white',
  },
  mainContentsSub1: {
    color: 'white',
    textAlign: 'center',
    fontSize: rem(36),
  },
  m_mainContentsSub1: {
    fontSize: rem(16),
  },
  mainContentsSub2: {
    color: 'white',
    textAlign: 'center',
    fontSize: rem(18),
    lineHeight: rem(25),
  },
  m_mainContentsSub2: {
    fontSize: rem(16),
  },
  emphasis: {
    fontWeight: 700,
  },
  colorEmphasis: {
    color: '#2C93AD',
  },
  appText: {
    fontSize: rem(18),
    fontWeight: 600,
  },
  m_appText: {
    fontSize: rem(16),
  },
  appTitle: {
    fontSize: rem(36),
    fontWeight: 700,
  },
  m_appTitle: {
    fontSize: rem(32),
    fontWeight: 600,
  },
  appDescription: {
    fontSize: rem(18),
    fontWeight: 500,
  },
  m_appDescription: {
    fontSize: rem(16),
    lineHeight: rem(20),
  },
  introduceBox: {
    maxWidth: rem(1280),
    paddingInline: rem(30),
    marginInline: 'auto',
  },

  producingGrid: {
    border: '1px solid #CCCCCC',
    borderRadius: rem(16),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: rem(320),
    maxHeight: rem(164),
    paddingInline: rem(60),
    paddingBlock: rem(30),
  },
  producingGridText: {
    fontSize: rem(20),
    fontWeight: 600,
  },
  producingArrowGrid: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingInline: rem(55),
    paddingBlock: rem(30),
  },
  middleArrowBox: {
    padding: 0,
  },
  middleArrow: {
    width: rem(300),
    marginBlock: rem(15),
  },
  arrowDown: {
    transform: 'rotate(90deg)',
  },
  arrowLeft: {
    transform: 'rotate(180deg)',
  },
  simpleGrid: {
    [`@media (max-width: ${rem(1024)})`]: {
      gridTemplateColumns: '1fr 1fr',
      gridGap: rem(30),
    },
  },
  priceGrid: {
    border: '1px solid #CCCCCC',
    borderRadius: rem(16),
    paddingInline: rem(20),
    paddingBlock: rem(26),
    [`@media (max-width: ${rem(1024)})`]: {
      paddingInline: rem(30),
    },
  },
  m_priceGrid: {
    paddingInline: rem(40),
  },
  priceTitle: {
    fontWeight: 600,
    fontSize: rem(36),
    textAlign: 'center',
  },
  addPriceInfoTitle: {
    fontSize: rem(24),
    fontWeight: 600,
    lineHeight: rem(25),
  },
  m_priceTitle: {
    textAlign: 'start',
  },
  priceCondition: {
    fontSize: rem(18),
    fontWeight: 600,
    listStyle: 'inside',
    lineHeight: rem(25),
  },
  m_priceCondition: {
    fontSize: rem(16),
  },
  priceBox: {
    minHeight: rem(73),
    marginTop: rem(10),
    [`@media (max-width: ${rem(1024)})`]: {
      gap: rem(10),
    },
  },
  priceText: {
    display: 'block',
    fontWeight: 600,
  },
  originPrice: {
    fontSize: rem(18),
    color: '#CCCCCC',
    textDecoration: 'line-through',
    lineHeight: rem(25),
  },
  m_originPrice: {
    fontSize: rem(16),
  },
  discountPrice: {
    fontSize: rem(30),
    lineHeight: rem(45),
  },
  addPriceBox: {
    border: '1px solid #CCCCCC',
    borderRadius: rem(16),
    marginInline: rem(80),
    paddingBlock: rem(50),
    paddingInline: rem(57),
    [`@media (max-width: ${rem(1024)})`]: {
      marginInline: 0,
      paddingBlock: rem(30),
      paddingInline: rem(30),
    },
  },
  listIcon: {
    minWidth: rem(30),
    minHeight: rem(30),
  },
  discountIcon: {
    minWidth: rem(56),
    minHeight: rem(56),
  },
  m_addPriceBox: {
    border: '1px solid #CCCCCC',
    borderRadius: rem(16),
    paddingInline: rem(15),
    paddingBlock: rem(25),
  },
  m_sectionBox: {
    paddingInline: rem(30),
    zIndex: 1,
  },
  aiTitle: {
    fontSize: rem(32),
    fontWeight: 600,
    color: 'white',
  },
  m_aiTitle: {
    fontSize: rem(24),
    lineHeight: rem(28),
  },
  aiText: {
    color: 'white',
    fontSize: rem(18),
    fontWeight: 500,
    lineHeight: rem(25),
  },
  m_aiText: {
    fontSize: rem(16),
  },
  serviceText: {
    fontSize: rem(36),
    fontWeight: 700,
    marginTop: rem(35),
  },
  serviceSubText: {
    marginTop: rem(20),
    fontSize: rem(18),
    fontWeight: 500,
  },
  m_introduceBox: {
    paddingInline: rem(30),
    marginInline: 'auto',
  },
  companyOverviewTitle: {
    display: 'block',
    paddingBlock: rem(50),
    fontSize: rem(48),
    fontWeight: 800,
  },
  m_companyOverviewTitle: {
    paddingBottom: rem(20),
  },
  logoImage: {
    minWidth: rem(370),
    minHeight: rem(164),
  },
}));

export default Pricing;
