import {
  Box,
  Button,
  Flex,
  Input,
  LoadingOverlay,
  NumberInput,
  Space,
  Stack,
  Title,
  UnstyledButton,
  createStyles,
  rem,
} from '@mantine/core';
import { useNavigate, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { IconArrowLeft } from '@tabler/icons-react';
import { Layout } from 'src/components';
import { client } from 'src';
import { getDiagnosisModel, searchDiagnosisModels } from 'src/graphql/queries';
import { updateDiagnosisModel } from 'src/graphql/mutations';
import {
  SearchableDiagnosisModelSortableFields,
  SearchableSortDirection,
} from 'src/API';

const DiagnosisEdit = () => {
  const { classes } = useStyles();

  const { id } = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [diag, setDiag] = useState({
    rank: 0,
    code: '',
    name_kor: '',
    name_eng: '',
  });

  const [lastNumber, setLastNumber] = useState(0);

  useEffect(() => {
    onGetLastNumber();
    onGetDiag();
  }, []);

  const onGetLastNumber = async () => {
    const result = await client.graphql({
      query: searchDiagnosisModels,
      variables: {
        sort: [
          {
            field: SearchableDiagnosisModelSortableFields.rank,
            direction: SearchableSortDirection.asc,
          },
        ],
      },
    });
    setLastNumber((result.data.searchDiagnosisModels as any).total);
  };

  const onGetDiag = async () => {
    try {
      const findItem = await client.graphql({
        query: getDiagnosisModel,
        variables: {
          id: String(id),
        },
      });
      const item = findItem.data.getDiagnosisModel;

      if (findItem.errors && findItem.errors.length > 0) {
        alert('데이터 불러오는데 실패했습니다. 다시 시도해 주세요.');
        return;
      }

      if (item) {
        setDiag({
          rank: item.rank === 0 ? lastNumber + 1 : item.rank,
          code: item.code,
          name_kor: item.name_kor,
          name_eng: item.name_eng,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onEditDiag = async () => {
    const { rank, code, name_kor, name_eng } = diag;

    if (
      rank == 0 ||
      code.length == 0 ||
      name_kor.length == 0 ||
      name_eng.length == 0
    ) {
      alert('필수 항목이 입력되지 않았습니다. 입력해 주세요.');
      return;
    }
    try {
      setIsLoading(true);
      const result = await client.graphql({
        query: updateDiagnosisModel,
        variables: {
          input: {
            id: String(id),
            rank: rank,
            code: code,
            name_kor: name_kor,
            name_eng: name_eng,
          },
        },
      });

      if (result.errors && result.errors.length > 0) {
        alert('데이터 수정을 실패했습니다. 다시 시도해 주세요.');
        return;
      }

      setTimeout(() => {
        setIsLoading(false);
        navigate('/admin/diagnosis');
      }, 1800);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Layout>
      <LoadingOverlay style={{ position: 'fixed' }} visible={isLoading} />
      <Space w={1} h={1} mb={rem(50)} />
      <Flex direction={'column'} justify={'center'} align={'center'} h={'100%'}>
        <Box className={classes.buttonList}>
          <Flex justify={'space-between'}>
            <UnstyledButton onClick={() => navigate('/admin/diagnosis')}>
              <IconArrowLeft />
            </UnstyledButton>
            <Title order={4}>
              <span>진단서 수정</span>
            </Title>
            <Button onClick={onEditDiag}>수정</Button>
          </Flex>
          <Stack>
            <Box>
              <NumberInput
                label="순위"
                withAsterisk
                value={diag.rank}
                onChange={(event) => setDiag({ ...diag, rank: event || 0 })}
              />
            </Box>
            <Box>
              <Input.Wrapper label="코드" withAsterisk>
                <Input
                  value={diag.code}
                  onChange={(event) =>
                    setDiag({ ...diag, code: event.currentTarget?.value })
                  }
                />
              </Input.Wrapper>
            </Box>
            <Box>
              <Input.Wrapper label="이름" withAsterisk>
                <Input
                  value={diag.name_kor}
                  onChange={(event) =>
                    setDiag({ ...diag, name_kor: event.currentTarget?.value })
                  }
                />
              </Input.Wrapper>
            </Box>
            <Box>
              <Input.Wrapper label="영문 이름" withAsterisk>
                <Input
                  value={diag.name_eng}
                  onChange={(event) =>
                    setDiag({ ...diag, name_eng: event.currentTarget?.value })
                  }
                />
              </Input.Wrapper>
            </Box>
          </Stack>
        </Box>
      </Flex>
    </Layout>
  );
};

const useStyles = createStyles(() => ({
  buttonList: {
    width: '80%',
    maxWidth: rem(500),
  },
}));

export default DiagnosisEdit;
