import {
  DataTable,
  DeleteModal,
  Layout,
  TableHeader,
  TableRow,
} from '../../components';
import {
  Box,
  Button,
  Center,
  Flex,
  Input,
  LoadingOverlay,
  Pagination,
  Space,
  Title,
  UnstyledButton,
  createStyles,
  rem,
} from '@mantine/core';
import { memo, useEffect, useState } from 'react';
import Toggle from '../../utils/Toggle';
import { TableHeaderType } from 'src/types/TableHeaderParams';
import { randomId, useDisclosure } from '@mantine/hooks';
import { onCheckUser } from 'src/utils';
import { IconEdit } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { client } from 'src';
import { searchDepartmentModels } from 'src/graphql/queries';
import {
  DepartmentModel,
  SearchableDepartmentModelSortableFields,
  SearchableSortDirection,
} from 'src/API';
import { deleteDepartmentModel } from 'src/graphql/mutations';
import { onDeleteDepartmentModel } from 'src/graphql/subscriptions';
import { retrievePageNumber, savePageNumber } from 'src/utils/localStoragePage';
import { LIMIT_PAGE } from 'src/constants';

const headerData: TableHeaderType[] = [
  { key: randomId(), label: '병원' },
  { key: randomId(), label: '소속' },
  { key: randomId(), label: '수정' },
];

const Department = () => {
  const { classes } = useStyles();
  const { toggleAll, toggleRow } = Toggle();

  const navigate = useNavigate();

  const [deleteOpened, deleteHandler] = useDisclosure(false);

  const [pageDept, setPageDept] = useState<DepartmentModel[]>([]);
  const [selection, setSelection] = useState<string[]>([]);

  const [search, setSearch] = useState('');
  const [isSearch, setIsSearch] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [activePage, setActivePage] = useState(retrievePageNumber());
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    const userCheck = onCheckUser();
    if (!userCheck) {
      navigate('/login');
    }

    setActivePage(retrievePageNumber());

    const onFetch = async () => {
      await onGetDepartments();
    };

    onFetch();

    const deleteSub = client
      .graphql({ query: onDeleteDepartmentModel })
      .subscribe({
        next: ({ data }: any) => {
          console.log(data);
        },
        error: (error: any) => console.warn(error),
      });

    return () => {
      deleteSub.unsubscribe();
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (isSearch) {
        await onSearch();
      } else {
        await onGetDepartments();
      }
    };

    fetchData();
  }, [activePage]);

  const setActivePageAndSave = (page: number) => {
    setActivePage(page);
    savePageNumber(page);
  };

  const onGetDepartments = async () => {
    setIsLoading(true);
    await client
      .graphql({
        query: searchDepartmentModels,
        variables: {
          limit: LIMIT_PAGE,
          from: LIMIT_PAGE * (activePage - 1),
          sort: [
            {
              field: SearchableDepartmentModelSortableFields.createdAt,
              direction: SearchableSortDirection.desc,
            },
          ],
        },
      })
      .then((result) => {
        setTotalCount((result.data.searchDepartmentModels as any).total);

        if (result.errors && result.errors.length > 0) {
          alert('데이터를 불러오는데 실패했습니다. 다시 시도해 주세요.');
          return;
        }

        const { items } = result.data.searchDepartmentModels;

        setPageDept(items);
        setIsLoading(false);
      });
  };

  const onSearch = async () => {
    if (search.length === 0) {
      setIsSearch(false);
    } else {
      setIsSearch(true);
    }
    try {
      setIsLoading(true);

      await client
        .graphql({
          query: searchDepartmentModels,
          variables: {
            limit: LIMIT_PAGE,
            from: LIMIT_PAGE * (activePage - 1),
            filter: {
              or: [
                { hospital: { wildcard: `*${search}*` } },
                { dept: { wildcard: `*${search}*` } },
              ],
            },
            sort: [
              {
                field: SearchableDepartmentModelSortableFields.createdAt,
                direction: SearchableSortDirection.desc,
              },
            ],
          },
        })
        .then((result) => {
          setTotalCount((result.data.searchDepartmentModels as any).total);

          if (result.errors && result.errors.length > 0) {
            alert('검색을 실패했습니다. 다시 시도해 주세요.');
            return;
          }

          const { items } = result.data.searchDepartmentModels;

          setPageDept(items);
          setIsLoading(false);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const deptHeader = TableHeader({
    headers: headerData,
    data: pageDept,
    selection,
    setSelection,
    toggleAll,
    dynamicKey: 'id',
    checkbox: true,
  });

  const deptRow = TableRow({
    toggleRow,
    data: pageDept,
    selection,
    setSelection,
    dynamicKey: 'id',
    checkbox: true,
    tableRows: (row) => (
      <>
        <td>{row.hospital}</td>
        <td>{row.dept}</td>
        <td>
          <UnstyledButton
            onClick={() => navigate(`/admin/department/edit/${row.id}`)}
            variant="default"
          >
            <IconEdit color="gray" />
          </UnstyledButton>
        </td>
      </>
    ),
  });

  const onDeleteDept = async () => {
    try {
      if (selection.length === 0) {
        alert('선택된 부서가 없습니다.');
        return;
      }

      setIsLoading(true);
      await Promise.all(
        selection.map(async (dept) => {
          try {
            await client
              .graphql({
                query: deleteDepartmentModel,
                variables: {
                  input: {
                    id: dept,
                  },
                },
              })
              .then(async (result) => {
                if (result.errors && result.errors?.length > 0) {
                  alert('데이터 삭제를 실패했습니다. 다시 시도해 주세요.');
                  return;
                }
              });
          } catch (error) {
            console.error(error);
          }
        })
      ).then(async () => {
        deleteHandler.close();
        setTimeout(() => {
          setIsLoading(false);
          onGetDepartments();
        }, 1800);
      });
    } catch (error) {
      console.error(error);
    }
  };

  const MemoizedDataTable = memo(DataTable);
  const MemoizedDeleteModal = memo(DeleteModal);
  const MemoizedPagination = memo(Pagination);

  return (
    <Layout>
      <LoadingOverlay style={{ position: 'fixed' }} visible={isLoading} />
      <Box>
        <Title order={4}>
          <span>부서 목록</span>
        </Title>
        <Flex align={'center'} justify={'space-between'}>
          <Flex gap={rem(10)}>
            <Input
              value={search}
              onChange={(event) => setSearch(event.target.value)}
              rightSection={
                <UnstyledButton
                  onClick={() => {
                    setSearch('');
                  }}
                >
                  x
                </UnstyledButton>
              }
            />
            <Button
              onClick={() => {
                setActivePage(1);
                onSearch();
              }}
            >
              검색
            </Button>
          </Flex>
          <Flex gap={rem(10)} className={classes.buttonList}>
            <Button
              variant="default"
              onClick={() => navigate('/admin/department/add')}
            >
              추가
            </Button>
            <Button
              variant="default"
              onClick={() => {
                if (selection.length === 0) {
                  alert('선택된 부서가 없습니다.');
                  return;
                }
                deleteHandler.open();
              }}
            >
              삭제
            </Button>
          </Flex>
        </Flex>
        <MemoizedDataTable
          tableHeader={deptHeader}
          tableRow={deptRow}
          txt="데이터가 존재하지 않습니다."
        />
        <Space w={1} h={1} mb={rem(25)} />
        <Center>
          <MemoizedPagination
            total={Math.ceil(totalCount / LIMIT_PAGE)}
            value={activePage}
            onChange={setActivePageAndSave}
          />
        </Center>
        <Space w={1} h={1} mb={rem(200)} />
      </Box>
      <MemoizedDeleteModal
        opened={deleteOpened}
        onClose={deleteHandler.close}
        onDelete={onDeleteDept}
      />
    </Layout>
  );
};

const useStyles = createStyles(() => ({
  buttonList: {
    paddingBlock: rem(10),
  },
}));

export default Department;
