import { Table, rem } from '@mantine/core';
import React, { memo, useMemo } from 'react';

interface PlayerTableProps {
  tableRow?: any;
  tableHeader?: any;
  txt?: string;
  children?: React.ReactNode;
}
const DataTable = ({
  tableRow,
  tableHeader,
  txt,
  children,
}: PlayerTableProps) => {
  const isCheckbox = useMemo(
    () => tableHeader?.props.children[0].type === 'th',
    []
  );

  const headerArr = useMemo(
    () => tableHeader?.props.children[1].length,
    [tableHeader]
  );

  const rowLength = useMemo(
    () => (isCheckbox ? headerArr + 1 : headerArr),
    [isCheckbox, headerArr]
  );

  const MemorizedTable = memo(Table);

  return (
    <>
      <MemorizedTable>
        <thead>{tableHeader}</thead>
        <tbody>
          {children ? (
            children
          ) : (tableRow && tableRow === null) ||
            tableRow === undefined ||
            tableRow.length === 0 ? (
            <tr>
              <td
                style={{
                  height: rem(200),
                  textAlign: 'center',
                  padding: `${rem(50)} 0`,
                }}
                colSpan={rowLength}
              >
                <span>{txt}</span>
              </td>
            </tr>
          ) : (
            tableRow
          )}
        </tbody>
      </MemorizedTable>
    </>
  );
};

export default DataTable;
