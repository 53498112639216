/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createTestModel = /* GraphQL */ `mutation CreateTestModel(
  $input: CreateTestModelInput!
  $condition: ModelTestModelConditionInput
) {
  createTestModel(input: $input, condition: $condition) {
    id
    rank
    name_kor
    name_eng
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTestModelMutationVariables,
  APITypes.CreateTestModelMutation
>;
export const updateTestModel = /* GraphQL */ `mutation UpdateTestModel(
  $input: UpdateTestModelInput!
  $condition: ModelTestModelConditionInput
) {
  updateTestModel(input: $input, condition: $condition) {
    id
    rank
    name_kor
    name_eng
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTestModelMutationVariables,
  APITypes.UpdateTestModelMutation
>;
export const deleteTestModel = /* GraphQL */ `mutation DeleteTestModel(
  $input: DeleteTestModelInput!
  $condition: ModelTestModelConditionInput
) {
  deleteTestModel(input: $input, condition: $condition) {
    id
    rank
    name_kor
    name_eng
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTestModelMutationVariables,
  APITypes.DeleteTestModelMutation
>;
export const createNoticeModel = /* GraphQL */ `mutation CreateNoticeModel(
  $input: CreateNoticeModelInput!
  $condition: ModelNoticeModelConditionInput
) {
  createNoticeModel(input: $input, condition: $condition) {
    id
    title
    content
    isDisplayed
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateNoticeModelMutationVariables,
  APITypes.CreateNoticeModelMutation
>;
export const updateNoticeModel = /* GraphQL */ `mutation UpdateNoticeModel(
  $input: UpdateNoticeModelInput!
  $condition: ModelNoticeModelConditionInput
) {
  updateNoticeModel(input: $input, condition: $condition) {
    id
    title
    content
    isDisplayed
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateNoticeModelMutationVariables,
  APITypes.UpdateNoticeModelMutation
>;
export const deleteNoticeModel = /* GraphQL */ `mutation DeleteNoticeModel(
  $input: DeleteNoticeModelInput!
  $condition: ModelNoticeModelConditionInput
) {
  deleteNoticeModel(input: $input, condition: $condition) {
    id
    title
    content
    isDisplayed
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteNoticeModelMutationVariables,
  APITypes.DeleteNoticeModelMutation
>;
export const createDepartmentModel = /* GraphQL */ `mutation CreateDepartmentModel(
  $input: CreateDepartmentModelInput!
  $condition: ModelDepartmentModelConditionInput
) {
  createDepartmentModel(input: $input, condition: $condition) {
    id
    hospital
    dept
    PartnerModels {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDepartmentModelMutationVariables,
  APITypes.CreateDepartmentModelMutation
>;
export const updateDepartmentModel = /* GraphQL */ `mutation UpdateDepartmentModel(
  $input: UpdateDepartmentModelInput!
  $condition: ModelDepartmentModelConditionInput
) {
  updateDepartmentModel(input: $input, condition: $condition) {
    id
    hospital
    dept
    PartnerModels {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDepartmentModelMutationVariables,
  APITypes.UpdateDepartmentModelMutation
>;
export const deleteDepartmentModel = /* GraphQL */ `mutation DeleteDepartmentModel(
  $input: DeleteDepartmentModelInput!
  $condition: ModelDepartmentModelConditionInput
) {
  deleteDepartmentModel(input: $input, condition: $condition) {
    id
    hospital
    dept
    PartnerModels {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDepartmentModelMutationVariables,
  APITypes.DeleteDepartmentModelMutation
>;
export const createPartnerModel = /* GraphQL */ `mutation CreatePartnerModel(
  $input: CreatePartnerModelInput!
  $condition: ModelPartnerModelConditionInput
) {
  createPartnerModel(input: $input, condition: $condition) {
    id
    username
    name
    point
    departmentmodelID
    VideoModels {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePartnerModelMutationVariables,
  APITypes.CreatePartnerModelMutation
>;
export const updatePartnerModel = /* GraphQL */ `mutation UpdatePartnerModel(
  $input: UpdatePartnerModelInput!
  $condition: ModelPartnerModelConditionInput
) {
  updatePartnerModel(input: $input, condition: $condition) {
    id
    username
    name
    point
    departmentmodelID
    VideoModels {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePartnerModelMutationVariables,
  APITypes.UpdatePartnerModelMutation
>;
export const deletePartnerModel = /* GraphQL */ `mutation DeletePartnerModel(
  $input: DeletePartnerModelInput!
  $condition: ModelPartnerModelConditionInput
) {
  deletePartnerModel(input: $input, condition: $condition) {
    id
    username
    name
    point
    departmentmodelID
    VideoModels {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePartnerModelMutationVariables,
  APITypes.DeletePartnerModelMutation
>;
export const createQuizModel = /* GraphQL */ `mutation CreateQuizModel(
  $input: CreateQuizModelInput!
  $condition: ModelQuizModelConditionInput
) {
  createQuizModel(input: $input, condition: $condition) {
    id
    question
    answer
    seq
    videomodelID
    source_name
    source_url
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateQuizModelMutationVariables,
  APITypes.CreateQuizModelMutation
>;
export const updateQuizModel = /* GraphQL */ `mutation UpdateQuizModel(
  $input: UpdateQuizModelInput!
  $condition: ModelQuizModelConditionInput
) {
  updateQuizModel(input: $input, condition: $condition) {
    id
    question
    answer
    seq
    videomodelID
    source_name
    source_url
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateQuizModelMutationVariables,
  APITypes.UpdateQuizModelMutation
>;
export const deleteQuizModel = /* GraphQL */ `mutation DeleteQuizModel(
  $input: DeleteQuizModelInput!
  $condition: ModelQuizModelConditionInput
) {
  deleteQuizModel(input: $input, condition: $condition) {
    id
    question
    answer
    seq
    videomodelID
    source_name
    source_url
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteQuizModelMutationVariables,
  APITypes.DeleteQuizModelMutation
>;
export const createVideoModel = /* GraphQL */ `mutation CreateVideoModel(
  $input: CreateVideoModelInput!
  $condition: ModelVideoModelConditionInput
) {
  createVideoModel(input: $input, condition: $condition) {
    id
    title
    author
    url
    QuizModels {
      nextToken
      __typename
    }
    tag
    partnermodelID
    retitle
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateVideoModelMutationVariables,
  APITypes.CreateVideoModelMutation
>;
export const updateVideoModel = /* GraphQL */ `mutation UpdateVideoModel(
  $input: UpdateVideoModelInput!
  $condition: ModelVideoModelConditionInput
) {
  updateVideoModel(input: $input, condition: $condition) {
    id
    title
    author
    url
    QuizModels {
      nextToken
      __typename
    }
    tag
    partnermodelID
    retitle
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateVideoModelMutationVariables,
  APITypes.UpdateVideoModelMutation
>;
export const deleteVideoModel = /* GraphQL */ `mutation DeleteVideoModel(
  $input: DeleteVideoModelInput!
  $condition: ModelVideoModelConditionInput
) {
  deleteVideoModel(input: $input, condition: $condition) {
    id
    title
    author
    url
    QuizModels {
      nextToken
      __typename
    }
    tag
    partnermodelID
    retitle
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteVideoModelMutationVariables,
  APITypes.DeleteVideoModelMutation
>;
export const createPatientModel = /* GraphQL */ `mutation CreatePatientModel(
  $input: CreatePatientModelInput!
  $condition: ModelPatientModelConditionInput
) {
  createPatientModel(input: $input, condition: $condition) {
    id
    username
    name
    birthdate
    gender
    partner_ids
    medication_ids
    pointed_video_ids
    watched_video_ids
    diagnosis_ids
    visited_history
    picture
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePatientModelMutationVariables,
  APITypes.CreatePatientModelMutation
>;
export const updatePatientModel = /* GraphQL */ `mutation UpdatePatientModel(
  $input: UpdatePatientModelInput!
  $condition: ModelPatientModelConditionInput
) {
  updatePatientModel(input: $input, condition: $condition) {
    id
    username
    name
    birthdate
    gender
    partner_ids
    medication_ids
    pointed_video_ids
    watched_video_ids
    diagnosis_ids
    visited_history
    picture
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePatientModelMutationVariables,
  APITypes.UpdatePatientModelMutation
>;
export const deletePatientModel = /* GraphQL */ `mutation DeletePatientModel(
  $input: DeletePatientModelInput!
  $condition: ModelPatientModelConditionInput
) {
  deletePatientModel(input: $input, condition: $condition) {
    id
    username
    name
    birthdate
    gender
    partner_ids
    medication_ids
    pointed_video_ids
    watched_video_ids
    diagnosis_ids
    visited_history
    picture
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePatientModelMutationVariables,
  APITypes.DeletePatientModelMutation
>;
export const createMedicationModel = /* GraphQL */ `mutation CreateMedicationModel(
  $input: CreateMedicationModelInput!
  $condition: ModelMedicationModelConditionInput
) {
  createMedicationModel(input: $input, condition: $condition) {
    id
    rank
    name_eng
    name_kor
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMedicationModelMutationVariables,
  APITypes.CreateMedicationModelMutation
>;
export const updateMedicationModel = /* GraphQL */ `mutation UpdateMedicationModel(
  $input: UpdateMedicationModelInput!
  $condition: ModelMedicationModelConditionInput
) {
  updateMedicationModel(input: $input, condition: $condition) {
    id
    rank
    name_eng
    name_kor
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMedicationModelMutationVariables,
  APITypes.UpdateMedicationModelMutation
>;
export const deleteMedicationModel = /* GraphQL */ `mutation DeleteMedicationModel(
  $input: DeleteMedicationModelInput!
  $condition: ModelMedicationModelConditionInput
) {
  deleteMedicationModel(input: $input, condition: $condition) {
    id
    rank
    name_eng
    name_kor
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMedicationModelMutationVariables,
  APITypes.DeleteMedicationModelMutation
>;
export const createDiagnosisModel = /* GraphQL */ `mutation CreateDiagnosisModel(
  $input: CreateDiagnosisModelInput!
  $condition: ModelDiagnosisModelConditionInput
) {
  createDiagnosisModel(input: $input, condition: $condition) {
    id
    rank
    code
    name_eng
    name_kor
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDiagnosisModelMutationVariables,
  APITypes.CreateDiagnosisModelMutation
>;
export const updateDiagnosisModel = /* GraphQL */ `mutation UpdateDiagnosisModel(
  $input: UpdateDiagnosisModelInput!
  $condition: ModelDiagnosisModelConditionInput
) {
  updateDiagnosisModel(input: $input, condition: $condition) {
    id
    rank
    code
    name_eng
    name_kor
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDiagnosisModelMutationVariables,
  APITypes.UpdateDiagnosisModelMutation
>;
export const deleteDiagnosisModel = /* GraphQL */ `mutation DeleteDiagnosisModel(
  $input: DeleteDiagnosisModelInput!
  $condition: ModelDiagnosisModelConditionInput
) {
  deleteDiagnosisModel(input: $input, condition: $condition) {
    id
    rank
    code
    name_eng
    name_kor
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDiagnosisModelMutationVariables,
  APITypes.DeleteDiagnosisModelMutation
>;
