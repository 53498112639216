/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from '../API';
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getTestModel = /* GraphQL */ `query GetTestModel($id: ID!) {
  getTestModel(id: $id) {
    id
    rank
    name_kor
    name_eng
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTestModelQueryVariables,
  APITypes.GetTestModelQuery
>;
export const listTestModels = /* GraphQL */ `query ListTestModels(
  $filter: ModelTestModelFilterInput
  $limit: Int
  $nextToken: String
) {
  listTestModels(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      rank
      name_kor
      name_eng
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTestModelsQueryVariables,
  APITypes.ListTestModelsQuery
>;
export const searchTestModels = /* GraphQL */ `query SearchTestModels(
  $filter: SearchableTestModelFilterInput
  $sort: [SearchableTestModelSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableTestModelAggregationInput]
) {
  searchTestModels(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      rank
      name_kor
      name_eng
      createdAt
      updatedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchTestModelsQueryVariables,
  APITypes.SearchTestModelsQuery
>;
export const getNoticeModel = /* GraphQL */ `query GetNoticeModel($id: ID!) {
  getNoticeModel(id: $id) {
    id
    title
    content
    isDisplayed
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetNoticeModelQueryVariables,
  APITypes.GetNoticeModelQuery
>;
export const listNoticeModels = /* GraphQL */ `query ListNoticeModels(
  $filter: ModelNoticeModelFilterInput
  $limit: Int
  $nextToken: String
) {
  listNoticeModels(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      title
      content
      isDisplayed
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListNoticeModelsQueryVariables,
  APITypes.ListNoticeModelsQuery
>;
export const searchNoticeModels = /* GraphQL */ `query SearchNoticeModels(
  $filter: SearchableNoticeModelFilterInput
  $sort: [SearchableNoticeModelSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableNoticeModelAggregationInput]
) {
  searchNoticeModels(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      title
      content
      isDisplayed
      createdAt
      updatedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchNoticeModelsQueryVariables,
  APITypes.SearchNoticeModelsQuery
>;
export const getDepartmentModel =
  /* GraphQL */ `query GetDepartmentModel($id: ID!) {
  getDepartmentModel(id: $id) {
    id
    hospital
    dept
    PartnerModels {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
    APITypes.GetDepartmentModelQueryVariables,
    APITypes.GetDepartmentModelQuery
  >;
export const listDepartmentModels = /* GraphQL */ `query ListDepartmentModels(
  $filter: ModelDepartmentModelFilterInput
  $limit: Int
  $nextToken: String
) {
  listDepartmentModels(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      hospital
      dept
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDepartmentModelsQueryVariables,
  APITypes.ListDepartmentModelsQuery
>;
export const searchDepartmentModels =
  /* GraphQL */ `query SearchDepartmentModels(
  $filter: SearchableDepartmentModelFilterInput
  $sort: [SearchableDepartmentModelSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableDepartmentModelAggregationInput]
) {
  searchDepartmentModels(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      hospital
      dept
      createdAt
      updatedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
    APITypes.SearchDepartmentModelsQueryVariables,
    APITypes.SearchDepartmentModelsQuery
  >;
export const getPartnerModel = /* GraphQL */ `query GetPartnerModel($id: ID!) {
  getPartnerModel(id: $id) {
    id
    username
    name
    point
    departmentmodelID
    VideoModels {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPartnerModelQueryVariables,
  APITypes.GetPartnerModelQuery
>;
export const listPartnerModels = /* GraphQL */ `query ListPartnerModels(
  $filter: ModelPartnerModelFilterInput
  $limit: Int
  $nextToken: String
) {
  listPartnerModels(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      username
      name
      point
      departmentmodelID
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPartnerModelsQueryVariables,
  APITypes.ListPartnerModelsQuery
>;
export const partnerModelsByDepartmentmodelID =
  /* GraphQL */ `query PartnerModelsByDepartmentmodelID(
  $departmentmodelID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelPartnerModelFilterInput
  $limit: Int
  $nextToken: String
) {
  partnerModelsByDepartmentmodelID(
    departmentmodelID: $departmentmodelID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      username
      name
      point
      departmentmodelID
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.PartnerModelsByDepartmentmodelIDQueryVariables,
    APITypes.PartnerModelsByDepartmentmodelIDQuery
  >;
export const searchPartnerModels = /* GraphQL */ `query SearchPartnerModels(
  $filter: SearchablePartnerModelFilterInput
  $sort: [SearchablePartnerModelSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchablePartnerModelAggregationInput]
) {
  searchPartnerModels(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      username
      name
      point
      departmentmodelID
      createdAt
      updatedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchPartnerModelsQueryVariables,
  APITypes.SearchPartnerModelsQuery
>;
export const getQuizModel = /* GraphQL */ `query GetQuizModel($id: ID!) {
  getQuizModel(id: $id) {
    id
    question
    answer
    seq
    videomodelID
    source_name
    source_url
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetQuizModelQueryVariables,
  APITypes.GetQuizModelQuery
>;
export const listQuizModels = /* GraphQL */ `query ListQuizModels(
  $filter: ModelQuizModelFilterInput
  $limit: Int
  $nextToken: String
) {
  listQuizModels(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      question
      answer
      seq
      videomodelID
      source_name
      source_url
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListQuizModelsQueryVariables,
  APITypes.ListQuizModelsQuery
>;
export const quizModelsByVideomodelID =
  /* GraphQL */ `query QuizModelsByVideomodelID(
  $videomodelID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelQuizModelFilterInput
  $limit: Int
  $nextToken: String
) {
  quizModelsByVideomodelID(
    videomodelID: $videomodelID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      question
      answer
      seq
      videomodelID
      source_name
      source_url
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.QuizModelsByVideomodelIDQueryVariables,
    APITypes.QuizModelsByVideomodelIDQuery
  >;
export const searchQuizModels = /* GraphQL */ `query SearchQuizModels(
  $filter: SearchableQuizModelFilterInput
  $sort: [SearchableQuizModelSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableQuizModelAggregationInput]
) {
  searchQuizModels(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      question
      answer
      seq
      videomodelID
      source_name
      source_url
      createdAt
      updatedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchQuizModelsQueryVariables,
  APITypes.SearchQuizModelsQuery
>;
export const getVideoModel = /* GraphQL */ `query GetVideoModel($id: ID!) {
  getVideoModel(id: $id) {
    id
    title
    author
    url
    QuizModels {
      nextToken
      __typename
    }
    tag
    partnermodelID
    retitle
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetVideoModelQueryVariables,
  APITypes.GetVideoModelQuery
>;
export const listVideoModels = /* GraphQL */ `query ListVideoModels(
  $filter: ModelVideoModelFilterInput
  $limit: Int
  $nextToken: String
) {
  listVideoModels(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      title
      author
      url
      tag
      partnermodelID
      retitle
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListVideoModelsQueryVariables,
  APITypes.ListVideoModelsQuery
>;
export const videoModelsByPartnermodelID =
  /* GraphQL */ `query VideoModelsByPartnermodelID(
  $partnermodelID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelVideoModelFilterInput
  $limit: Int
  $nextToken: String
) {
  videoModelsByPartnermodelID(
    partnermodelID: $partnermodelID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      author
      url
      tag
      partnermodelID
      retitle
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.VideoModelsByPartnermodelIDQueryVariables,
    APITypes.VideoModelsByPartnermodelIDQuery
  >;
export const searchVideoModels = /* GraphQL */ `query SearchVideoModels(
  $filter: SearchableVideoModelFilterInput
  $sort: [SearchableVideoModelSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableVideoModelAggregationInput]
) {
  searchVideoModels(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      QuizModels {
        items {
          answer
          id
          question
          seq
          source_name
          source_url
        }
      }
      id
      title
      author
      url
      tag
      partnermodelID
      retitle
      createdAt
      updatedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchVideoModelsQueryVariables,
  APITypes.SearchVideoModelsQuery
>;
export const getPatientModel = /* GraphQL */ `query GetPatientModel($id: ID!) {
  getPatientModel(id: $id) {
    id
    username
    name
    birthdate
    gender
    partner_ids
    medication_ids
    pointed_video_ids
    watched_video_ids
    diagnosis_ids
    visited_history
    picture
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPatientModelQueryVariables,
  APITypes.GetPatientModelQuery
>;
export const listPatientModels = /* GraphQL */ `query ListPatientModels(
  $filter: ModelPatientModelFilterInput
  $limit: Int
  $nextToken: String
) {
  listPatientModels(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      username
      name
      birthdate
      gender
      partner_ids
      medication_ids
      pointed_video_ids
      watched_video_ids
      diagnosis_ids
      visited_history
      picture
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPatientModelsQueryVariables,
  APITypes.ListPatientModelsQuery
>;
export const searchPatientModels = /* GraphQL */ `query SearchPatientModels(
  $filter: SearchablePatientModelFilterInput
  $sort: [SearchablePatientModelSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchablePatientModelAggregationInput]
) {
  searchPatientModels(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      username
      name
      birthdate
      gender
      partner_ids
      medication_ids
      pointed_video_ids
      watched_video_ids
      diagnosis_ids
      visited_history
      picture
      createdAt
      updatedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchPatientModelsQueryVariables,
  APITypes.SearchPatientModelsQuery
>;
export const getMedicationModel =
  /* GraphQL */ `query GetMedicationModel($id: ID!) {
  getMedicationModel(id: $id) {
    id
    rank
    name_eng
    name_kor
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
    APITypes.GetMedicationModelQueryVariables,
    APITypes.GetMedicationModelQuery
  >;
export const listMedicationModels = /* GraphQL */ `query ListMedicationModels(
  $filter: ModelMedicationModelFilterInput
  $limit: Int
  $nextToken: String
) {
  listMedicationModels(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      rank
      name_eng
      name_kor
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMedicationModelsQueryVariables,
  APITypes.ListMedicationModelsQuery
>;
export const searchMedicationModels =
  /* GraphQL */ `query SearchMedicationModels(
  $filter: SearchableMedicationModelFilterInput
  $sort: [SearchableMedicationModelSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableMedicationModelAggregationInput]
) {
  searchMedicationModels(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      rank
      name_eng
      name_kor
      createdAt
      updatedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
    APITypes.SearchMedicationModelsQueryVariables,
    APITypes.SearchMedicationModelsQuery
  >;
export const getDiagnosisModel =
  /* GraphQL */ `query GetDiagnosisModel($id: ID!) {
  getDiagnosisModel(id: $id) {
    id
    rank
    code
    name_eng
    name_kor
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
    APITypes.GetDiagnosisModelQueryVariables,
    APITypes.GetDiagnosisModelQuery
  >;
export const listDiagnosisModels = /* GraphQL */ `query ListDiagnosisModels(
  $filter: ModelDiagnosisModelFilterInput
  $limit: Int
  $nextToken: String
) {
  listDiagnosisModels(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      rank
      code
      name_eng
      name_kor
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDiagnosisModelsQueryVariables,
  APITypes.ListDiagnosisModelsQuery
>;
export const searchDiagnosisModels = /* GraphQL */ `query SearchDiagnosisModels(
  $filter: SearchableDiagnosisModelFilterInput
  $sort: [SearchableDiagnosisModelSortInput]
  $limit: Int
  $nextToken: String
  $from: Int
  $aggregates: [SearchableDiagnosisModelAggregationInput]
) {
  searchDiagnosisModels(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
    aggregates: $aggregates
  ) {
    items {
      id
      rank
      code
      name_eng
      name_kor
      createdAt
      updatedAt
      __typename
    }
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          value
        }
        ... on SearchableAggregateBucketResult {
          buckets {
            key
            doc_count
            __typename
          }
        }
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchDiagnosisModelsQueryVariables,
  APITypes.SearchDiagnosisModelsQuery
>;
