const PAGE_KEY = 'currentPage';

export const savePageNumber = (page: number) => {
  localStorage.setItem(PAGE_KEY, page.toString());
};

export const retrievePageNumber = () => {
  const storedPage = localStorage.getItem(PAGE_KEY);
  return storedPage ? parseInt(storedPage, 10) : 1;
};

export const getVideoId = () => {
  return localStorage.getItem('currVideoId');
};
