import { Box, Button, Center, Flex, Modal, Space, rem } from '@mantine/core';

interface DeleteModalType {
  opened: any;
  onClose: any;
  onDelete: any;
}

const DeleteModal = ({ opened, onClose, onDelete }: DeleteModalType) => {
  const handleDelete = async () => {
    await onDelete();
  };
  return (
    <Modal centered opened={opened} onClose={onClose} title="삭제하기">
      <Box>정말 삭제하시겠습니까?</Box>
      <Box>삭제된 데이터를 복구할 수 없습니다.</Box>
      <Space w={1} h={1} mb={rem(20)} />
      <Center>
        <Flex gap={rem(10)}>
          <Button onClick={handleDelete}>삭제</Button>
          <Button variant="default" onClick={onClose}>
            취소
          </Button>
        </Flex>
      </Center>
    </Modal>
  );
};

export default DeleteModal;
