import {
  Box,
  Flex,
  Image,
  Space,
  Stack,
  createStyles,
  rem,
} from '@mantine/core';

import Phone1 from '../assets/phone1.jpg';
import Phone2 from '../assets/phone2.jpg';
import Phone3 from '../assets/phone3.jpg';
import Phone4 from '../assets/phone4.jpg';
import Phone5 from '../assets/phone5.jpg';

import { Layout } from 'src/components';
import classnames from 'classnames';
import { BrowserView, MobileView } from 'react-device-detect';

const AboutDetail = () => {
  const { classes } = useStyles();

  return (
    <Layout tabs={false}>
      <BrowserView>
        <Space w={2} h={3} mb={rem(100)} />
        <Box className={classes.mainContents}>
          <Flex direction={'column'} gap={rem(30)}>
            <Flex justify={'center'} direction={'column'}>
              <span className={classes.mainContentsTitle}>Dr.Ben과</span>
              <span className={classes.mainContentsTitle}>
                함께하는 진료여정
              </span>
            </Flex>
            <Flex
              justify={'center'}
              direction={'column'}
              className={classes.mainContentsSub2}
            >
              <span>AI 알고리즘을 통해 각 환자에게</span>
              <span>꼭 맞는 맞춤형 교육을 제공하는 영상 서비스 플랫폼</span>
            </Flex>
          </Flex>
        </Box>
        <Space w={4} h={5} mb={rem(100)} />
      </BrowserView>
      <MobileView>
        <Space w={1} h={1} mb={rem(50)} />
        <Box className={classes.mainContents}>
          <Flex direction={'column'} gap={rem(30)}>
            <Flex justify={'center'} direction={'column'}>
              <span className={classes.mobileMainContentsTitle}>Dr.Ben과</span>
              <span className={classes.mobileMainContentsTitle}>
                함께하는 진료여정
              </span>
            </Flex>
            <Flex
              justify={'center'}
              direction={'column'}
              className={classes.mobileMainContentsSub2}
            >
              <span>AI 알고리즘을 통해 각 환자에게</span>
              <span>꼭 맞는 맞춤형 교육을 제공하는</span>
              <span>영상 서비스 플랫폼</span>
            </Flex>
          </Flex>
        </Box>
        <Space w={1} h={1} mb={rem(50)} />
      </MobileView>
      <Stack className={classes.stackBoxList}>
        <Box>
          <BrowserView>
            <Flex
              justify={'center'}
              align={'flex-end'}
              className={classnames(classes.stack, classes.stack1)}
            >
              <Flex>
                <Flex
                  w={rem(1170)}
                  h={'inherit'}
                  justify={'space-between'}
                  align={'flex-start'}
                >
                  <Flex h={'inherit'} direction={'column'} gap={rem(40)}>
                    <Box
                      className={classnames(
                        classes.stackTextTitle,
                        classes.pcStackTextTitle
                      )}
                    >
                      <span className={classes.stackText}>
                        주치의에게 직접 듣는
                      </span>
                      <span className={classes.stackText}>자세한 질환교육</span>
                    </Box>
                    <Box className={classes.stackTextDescript}>
                      <span className={classes.stackText}>
                        외래 진료실에서 차마 시간에 쫓겨 묻지 못했던 많은
                        궁금증들.
                      </span>
                      <span className={classes.stackText}>
                        주치의가 직접 출연하여 설명하는 세분화된 질환 교육
                        영상을 통해
                      </span>
                      <span className={classes.stackText}>
                        모두 해결해 드릴게요.
                      </span>
                    </Box>
                  </Flex>
                  <Flex align={'flex-end'} className={classes.stackImage}>
                    <Image width={rem(300)} src={Phone1} />
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </BrowserView>
          <MobileView>
            <Flex
              justify={'center'}
              className={classnames(classes.mobileStack, classes.stack1)}
            >
              <Flex direction={'column'} justify={'center'}>
                <Flex direction={'column'} align={'center'} gap={rem(20)}>
                  <Box
                    className={classnames(
                      classes.stackTextTitle,
                      classes.mobileStackTextTitle
                    )}
                  >
                    <span className={classes.stackText}>
                      주치의에게 직접 듣는
                    </span>
                    <span className={classes.stackText}>자세한 질환교육</span>
                  </Box>
                  <Box className={classes.mobileStackTextDescript}>
                    <span className={classes.stackText}>
                      외래 진료실에서 차마 시간에 쫓겨
                    </span>
                    <span className={classes.stackText}>
                      묻지 못했던 많은 궁금증들.
                    </span>
                    <span className={classes.stackText}>
                      주치의가 직접 출연하여 설명하는
                    </span>
                    <span className={classes.stackText}>
                      세분화된 질환 교육 영상을 통해
                    </span>
                    <span className={classes.stackText}>
                      모두 해결해 드릴게요.
                    </span>
                  </Box>
                </Flex>
                <Flex align={'flex-end'} className={classes.stackImage}>
                  <Image src={Phone1} />
                </Flex>
              </Flex>
            </Flex>
          </MobileView>
        </Box>
        <Box>
          <BrowserView>
            <Flex
              justify={'center'}
              align={'flex-end'}
              className={classnames(classes.stack, classes.stack2)}
            >
              <Flex>
                <Flex
                  w={rem(1170)}
                  h={'100%'}
                  align={'flex-start'}
                  justify={'space-between'}
                >
                  <Flex align={'flex-end'} className={classes.stackImage}>
                    <Image width={rem(300)} src={Phone2} />
                  </Flex>
                  <Flex
                    h={'100%'}
                    direction={'column'}
                    gap={rem(40)}
                    className={classes.stackWhiteText}
                  >
                    <Box
                      className={classnames(
                        classes.stackTextTitle,
                        classes.pcStackTextTitle
                      )}
                    >
                      <span className={classes.stackText}>간단한 퀴즈로</span>
                      <span className={classes.stackText}>
                        알아보는 나의 질환 지식
                      </span>
                    </Box>
                    <Box className={classes.stackTextDescript}>
                      <span className={classes.stackText}>
                        영상 시청 후 간단한 퀴즈를 통해 영상으로 배운 지식을
                      </span>
                      <span className={classes.stackText}>
                        내가 완전히 이해했는지 확인할 수 있어요.
                      </span>
                      <span className={classes.stackText}>
                        쉽고 재밌는 퀴즈로 내가 가진 질환에 대해 꼼꼼히
                        짚어보세요.
                      </span>
                    </Box>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </BrowserView>
          <MobileView>
            <Flex
              justify={'center'}
              className={classnames(classes.mobileStack, classes.stack2)}
            >
              <Flex direction={'column'} justify={'center'} align={'center'}>
                <Flex
                  direction={'column'}
                  align={'center'}
                  gap={rem(20)}
                  className={classes.stackWhiteText}
                >
                  <Box
                    className={classnames(
                      classes.stackTextTitle,
                      classes.mobileStackTextTitle
                    )}
                  >
                    <span className={classes.stackText}>간단한 퀴즈로</span>
                    <span className={classes.stackText}>알아보는</span>
                    <span className={classes.stackText}>나의 질환 지식</span>
                  </Box>
                  <Box className={classes.mobileStackTextDescript}>
                    <span className={classes.stackText}>
                      영상 시청 후 간단한 퀴즈를 통해 배운 지식을
                    </span>
                    <span className={classes.stackText}>
                      내가 완전히 이해 했는지 확인할 수 있어요.
                    </span>
                    <span className={classes.stackText}>
                      쉽고 재밌는 퀴즈로 내가 가진 질환에 대해
                    </span>
                    <span className={classes.stackText}>
                      꼼꼼히 짚어보세요.
                    </span>
                  </Box>
                </Flex>
                <Flex
                  align={'flex-end'}
                  justify={'center'}
                  className={classes.stackImage}
                >
                  <Image src={Phone2} />
                </Flex>
              </Flex>
            </Flex>
          </MobileView>
        </Box>
        <Box>
          <BrowserView>
            <Flex
              justify={'center'}
              align={'flex-end'}
              className={classnames(classes.stack, classes.stack3)}
            >
              <Flex>
                <Flex
                  w={rem(1170)}
                  h={'inherit'}
                  justify={'space-between'}
                  align={'flex-start'}
                >
                  <Flex
                    className={classes.stackWhiteText}
                    h={'inherit'}
                    direction={'column'}
                    gap={rem(40)}
                  >
                    <Box
                      className={classnames(
                        classes.stackTextTitle,
                        classes.pcStackTextTitle
                      )}
                    >
                      <span className={classes.stackText}>
                        주치의와 감사표시로
                      </span>
                      <span className={classes.stackText}>주고받는 피드백</span>
                    </Box>
                    <Box className={classes.stackTextDescript}>
                      <span className={classes.stackText}>
                        영상 시청 후 감사표시와 응원하기 버튼을 통해
                      </span>
                      <span className={classes.stackText}>
                        진료실 밖에서도 나의 주치의와 서로 고마운 마음을
                      </span>
                      <span className={classes.stackText}>
                        주고받으며 소통할 수 있어요.
                      </span>
                    </Box>
                  </Flex>
                  <Flex align={'flex-end'}>
                    <Image width={rem(300)} src={Phone3} />
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </BrowserView>
          <MobileView>
            <Flex
              justify={'center'}
              className={classnames(classes.mobileStack, classes.stack3)}
            >
              <Flex
                direction={'column'}
                justify={'center'}
                align={'center'}
                gap={rem(10)}
              >
                <Flex
                  className={classes.stackWhiteText}
                  direction={'column'}
                  align={'center'}
                  gap={rem(20)}
                >
                  <Box
                    className={classnames(
                      classes.stackTextTitle,
                      classes.mobileStackTextTitle
                    )}
                  >
                    <span className={classes.stackText}>주치의와</span>
                    <span className={classes.stackText}>감사표시로</span>
                    <span className={classes.stackText}>주고받는 피드백</span>
                  </Box>
                  <Box className={classes.mobileStackTextDescript}>
                    <span className={classes.stackText}>
                      영상 시청 후 감사표시와 응원하기 버튼을 통해
                    </span>
                    <span className={classes.stackText}>
                      진료실 밖에서도 나의 주치의와 서로 고마운 마음을
                    </span>
                    <span className={classes.stackText}>
                      주고받으며 소통할 수 있어요.
                    </span>
                  </Box>
                </Flex>
                <Flex align={'flex-end'} className={classes.stackImage}>
                  <Image src={Phone3} />
                </Flex>
              </Flex>
            </Flex>
          </MobileView>
        </Box>
        <Box>
          <BrowserView>
            <Flex
              justify={'center'}
              align={'flex-end'}
              className={classnames(classes.stack, classes.stack4)}
            >
              <Flex>
                <Flex
                  w={rem(1170)}
                  h={'100%'}
                  align={'flex-start'}
                  justify={'space-between'}
                >
                  <Flex align={'flex-end'} className={classes.stackImage}>
                    <Image width={rem(300)} src={Phone4} />
                  </Flex>
                  <Flex h={'100%'} direction={'column'} gap={rem(40)}>
                    <Box
                      className={classnames(
                        classes.stackTextTitle,
                        classes.pcStackTextTitle
                      )}
                    >
                      <span className={classes.stackText}>
                        AI 알고리즘을 통해
                      </span>
                      <span className={classes.stackText}>다양한 맞춤형</span>
                      <span className={classes.stackText}>
                        영상 콘텐츠 제공
                      </span>
                    </Box>
                    <Box className={classes.stackTextDescript}>
                      <span className={classes.stackText}>
                        나의 치료 과정에 최적화된 영상을 추가로 시청할 수
                        있어요.
                      </span>
                      <span className={classes.stackText}>
                        AI 알고리즘을 통해 나에게 최적화된 영상을 시청해 보세요.
                      </span>
                    </Box>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </BrowserView>
          <MobileView>
            <Flex
              justify={'center'}
              className={classnames(classes.mobileStack, classes.stack4)}
            >
              <Flex direction={'column'} justify={'center'} align={'center'}>
                <Flex direction={'column'} align={'center'} gap={rem(20)}>
                  <Box
                    className={classnames(
                      classes.stackTextTitle,
                      classes.mobileStackTextTitle
                    )}
                  >
                    <span className={classes.stackText}>
                      AI 알고리즘을 통해
                    </span>
                    <span className={classes.stackText}>다양한 맞춤형</span>
                    <span className={classes.stackText}>영상 콘텐츠 제공</span>
                  </Box>
                  <Box className={classes.mobileStackTextDescript}>
                    <span className={classes.stackText}>
                      나의 치료 과정에 최적화된
                    </span>
                    <span className={classes.stackText}>
                      영상을 추가로 시청할 수 있어요.
                    </span>
                    <span className={classes.stackText}>
                      AI 알고리즘을 통해 나에게
                    </span>
                    <span className={classes.stackText}>
                      최적화된 영상을 시청해 보세요.
                    </span>
                  </Box>
                </Flex>
                <Flex
                  align={'flex-end'}
                  justify={'center'}
                  className={classes.stackImage}
                >
                  <Image src={Phone4} />
                </Flex>
              </Flex>
            </Flex>
          </MobileView>
        </Box>
        <Box>
          <BrowserView>
            <Flex
              justify={'center'}
              align={'flex-end'}
              className={classnames(classes.stack, classes.stack5)}
            >
              <Flex>
                <Flex
                  w={rem(1170)}
                  h={'inherit'}
                  justify={'space-between'}
                  align={'flex-start'}
                >
                  <Flex
                    className={classes.stackWhiteText}
                    h={'inherit'}
                    direction={'column'}
                    gap={rem(40)}
                  >
                    <Box
                      className={classnames(
                        classes.stackTextTitle,
                        classes.pcStackTextTitle
                      )}
                    >
                      <span className={classes.stackText}>
                        토탈 건강 관리 앱
                      </span>
                    </Box>
                    <Box className={classes.stackTextDescript}>
                      <span className={classes.stackText}>
                        다양하고 어려운 진단명과 처방약.
                      </span>
                      <span className={classes.stackText}>
                        이제는 닥터벤 앱에 기록해두고 손쉽게 확인해 보세요.
                      </span>
                    </Box>
                  </Flex>
                  <Flex align={'flex-end'} className={classes.stackImage}>
                    <Image width={rem(300)} src={Phone5} />
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </BrowserView>
          <MobileView>
            <Flex
              justify={'center'}
              className={classnames(classes.lastStack, classes.stack5)}
            >
              <Flex direction={'column'} justify={'center'} align={'center'}>
                <Flex
                  className={classes.stackWhiteText}
                  direction={'column'}
                  align={'center'}
                  gap={rem(20)}
                >
                  <Box
                    className={classnames(
                      classes.stackTextTitle,
                      classes.mobileStackTextTitle
                    )}
                  >
                    <span className={classes.stackText}>토탈 건강 관리 앱</span>
                  </Box>
                  <Box className={classes.mobileStackTextDescript}>
                    <span className={classes.stackText}>
                      다양하고 어려운 진단명과 처방약.
                    </span>
                    <span className={classes.stackText}>
                      이제는 닥터벤 앱에 기록해두고
                    </span>
                    <span className={classes.stackText}>
                      손쉽게 확인해 보세요.
                    </span>
                  </Box>
                </Flex>
                <Flex align={'flex-end'} className={classes.stackImage}>
                  <Image src={Phone5} />
                </Flex>
              </Flex>
            </Flex>
          </MobileView>
        </Box>
      </Stack>
    </Layout>
  );
};

const useStyles = createStyles(() => ({
  mainContents: {
    paddingInline: rem(50),
  },
  mainContentsSub1: {
    color: '#2C93AD',
    textAlign: 'center',
    fontSize: rem(18),
  },
  mainContentsTitle: {
    textAlign: 'center',
    fontWeight: 800,
    fontSize: rem(48),
    lineHeight: rem(52),
  },
  mobileMainContentsTitle: {
    textAlign: 'center',
    fontWeight: 800,
    fontSize: rem(40),
    lineHeight: rem(52),
  },
  mainContentsSub2: {
    color: '#2C93AD',
    textAlign: 'center',
    fontSize: rem(24),
  },
  mobileMainContentsSub2: {
    color: '#2C93AD',
    textAlign: 'center',
    fontSize: rem(20),
  },
  stackBoxList: {
    gap: rem(0),
  },
  stack: {
    paddingInline: rem(100),
    color: '#33333',
    height: rem(479),
  },
  mobileStack: {
    height: rem(601),
  },
  lastStack: {
    height: rem(520),
  },
  stackTextTitle: {
    fontWeight: 700,
  },
  pcStackTextTitle: {
    fontSize: rem(36),
  },
  mobileStackTextTitle: {
    fontSize: rem(30),
    textAlign: 'center',
    paddingTop: rem(58),
  },
  stackTextDescript: {
    fontSize: rem(18),
    fontWeight: 400,
  },
  mobileStackTextDescript: {
    fontSize: rem(16),
    fontWeight: 400,
    textAlign: 'center',
    lineHeight: rem(20),
  },
  stackText: {
    display: 'block',
  },
  stackWhiteText: {
    color: '#ffffff',
  },
  stackImage: {
    width: rem(240),
    height: rem(370),
  },
  stack1: {
    backgroundColor: '#EAF4F7',
  },
  stack2: {
    backgroundColor: '#2C93AD',
  },
  stack3: {
    backgroundColor: '#C0DFE6',
  },
  stack4: {
    backgroundColor: '#EAF4F7',
  },
  stack5: {
    backgroundColor: '#C0DFE6',
  },
}));

export default AboutDetail;
